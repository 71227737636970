import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import { tokenData } from '../const';

const MemberContext = createContext()

export default function MemberProvider({ children }) {

    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!tokenData?.token) return
        fetchMember()
    }, []);

    const fetchMember = () => {
        try {
            fetch(`${SERVER_URL + "project-member/get-all-employees"}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setMembers(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                    setLoading(false);
                });
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    return (
        <MemberContext.Provider value={{ members, setMembers, fetchMember }}>
            {children}
        </MemberContext.Provider>
    )
}

export function useMember() {
    return useContext(MemberContext)
}
