import React, { useState, useEffect, useCallback } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, MailOutlined, PlusOutlined, UserOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Divider, Avatar, Tooltip, Collapse, Image, notification, Select, DatePicker } from 'antd';
import { Spinner, Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'
import { useStatusCode } from '../../contexts/StatusContext';
import Email from './Email';
import dayjs from 'dayjs';
import Addmember from './Addmember';
import { SERVER_URL } from '../../api';
import ViewMember from './ViewMember';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import he from 'he';
import { tokenData } from '../../const';
import utc from 'dayjs/plugin/utc';
import { errorSwal } from '../../helper/sweetalert';
dayjs.extend(utc);

export default function ModalAddManagement({
    show,
    handleclose,
    isLoading,
    title,
    setTitle,
    detail,
    setDetail,
    statusId,
    setStatusId,
    popupState,
    addAssignment,
    addComment,
    setFile,
    loadingCreate,
    selectEditData,
    setFilePreview,
    uploadedFiles,
    setUploadedFiles,
    setSelectEditData,
    UpdateProcessing,
    UpdateAssignment,
    members,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    currentStartTime,
    setCurrentStartTime,
    currentEndTime,
    setCurrentEndTime,
    memberAssignHistory,
    selected_employee,
    setSelected_Employee,
    project,
    setEmployeesMember,
    employees_member,
    addProcessing }) {

    // --------------------- useState ---------------------

    const { t } = useTranslation();
    const { status } = useStatusCode()
    // const { employees_member, setEmployeesMember } = useEmployeesMember()
    const [errorTitle, setErrorTitle] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [editMode, setEditMode] = useState(false)
    const [showAddMember, setShowAddmember] = useState(false)
    const [showViewMember, setShowViewMember] = useState(false)
    const [showEmail, setShowEmail] = useState(false)
    const [assignmentData, setAssignMentData] = useState()
    const [meetingData, setMeetingData] = useState()
    // const decodedHtml = he.decode(DetailData?.descriptions ?? '');

    const [errorStartStatus, setErrorStartStatus] = useState()
    const [errorEndStatus, setErrorEndStatus] = useState()

    // --------------------- useEffect ---------------------

    useEffect(() => {
        if (popupState === "processing") {
            fetchDataMeeting()
        } else if (popupState === "assignment") {
            fetchDataAssignment()
        }
    }, [popupState])

    useEffect(() => {
        if (selectEditData) {
            setEditMode(selectEditData?.editMode)
            fetchDataAssignment()
            fetchDataMeeting()
        } else {
            setEditMode(false)
            setSelectEditData('')
        }
    }, [selectEditData])

    useEffect(() => {
        if (editMode === true) {
            const decodedHtml = he.decode(selectEditData?.descriptions ?? '');
            setTitle(selectEditData?.comments || selectEditData?.title)
            setStatusId(selectEditData?.status_id)
            setDetail(decodedHtml)
            // setFile(selectEditData?.files?.[0])
            // setUploadedFiles(selectEditData?.files || [])

            if (selectEditData?.from_date === "" || selectEditData?.to_date === "") {
                setCurrentStartDate()
                setCurrentEndDate()

            } else if (selectEditData?.from_date === "") {
                setCurrentStartDate()
                const formattedEndDate = dayjs(selectEditData?.to_date);
                setCurrentEndDate(formattedEndDate)
            }
            else if (selectEditData?.to_date === "") {
                setCurrentEndDate()
                const formattedDate = dayjs(selectEditData?.from_date);
                setCurrentStartDate(formattedDate)
            }
            else {
                const formattedDate = dayjs(selectEditData?.from_date);
                const formattedEndDate = dayjs(selectEditData?.to_date);
                setCurrentStartDate(formattedDate)
                setCurrentEndDate(formattedEndDate)
            }
            // setCurrentStartDate(formattedDate)
            // setCurrentEndDate(formattedEndDate)
            setCurrentStartTime(selectEditData?.start_time)
            setCurrentEndTime(selectEditData?.end_time)

        } else {
            setTitle('')
            setCurrentStartDate('')
            setCurrentEndDate('')
            setCurrentStartTime('')
            setCurrentEndTime('')
            setSelectEditData('')
            // setUploadedFiles([])
        }
    }, [editMode])

    function onChangeDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);

        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD HH:mm:ss")
        // Set the selected date in the new time zone
        setCurrentStartDate(bangkokTime);
        // console.log("=========>parsedDate", bangkokTime.format("YYYY-MM-DD HH:mm:ss"));
    }

    function onChangeEndDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);

        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD HH:mm:ss")
        // Set the selected date in the new time zone
        setCurrentEndDate(bangkokTime);
        // console.log("=========>parsedDate", bangkokTime.format("YYYY-MM-DD HH:mm:ss"));
    }

    const fetchDataAssignment = () => {
        fetch(`${SERVER_URL + "ass/get-assignment/" + selectEditData?.as_id}`, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAssignMentData(data);
                // setLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching data:', error);
                // setLoading(false);
            });
        // setLoading(false);
    };

    const fetchDataMeeting = () => {
        fetch(`${SERVER_URL + "meeting/get-meeting/" + selectEditData?.meeting_uid}`, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setMeetingData(data);
                // setLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching data:', error);
                // setLoading(false);
            });
        // setLoading(false);
    };

    // --------------------- fuctions ---------------------

    const handleEditFileAssignment = (file_id) => {

        const formData = new FormData();

        uploadedFiles.map((blob, index) => {
            formData.append('file_name', blob);
            // }
        });

        fetch(`${SERVER_URL + `ass/add-files/${file_id}`}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                // 'Content-Type': 'application/json',
            },
            body: formData
        })
            .then((response) => {
                if (response.ok) {
                    fetchDataAssignment()
                } else {
                    console.log('Failed to delete the member.');
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };

    const handleEditFileMeetings = (file_id) => {

        const formData = new FormData();

        uploadedFiles.map((blob, index) => {
            formData.append('file_name', blob);
            // }
        });

        fetch(`${SERVER_URL + `meeting/insert-new-file/${file_id}`}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                // 'Content-Type': 'application/json',
            },
            body: formData
        })
            .then((response) => {
                if (response.ok) {
                    fetchDataMeeting()
                } else {
                    console.log('Failed to delete the member.');
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };

    const handleDeleteFileAssignment = (file_id) => {
        // Make a DELETE request to delete the member
        fetch(`${SERVER_URL + `ass/delete-file/${file_id}`}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    notification.success({
                        message: t('Success'),
                        description: t("delete success"),
                        duration: 2,
                    });
                    fetchDataAssignment()
                } else {
                    console.log('Failed to delete the member.');
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };

    const handleDeleteFileMeeting = (file_id) => {
        // Make a DELETE request to delete the member
        fetch(`${SERVER_URL + `meeting/delete-file/${file_id}`}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    notification.success({
                        message: t('Success'),
                        description: t("delete success"),
                        duration: 2,
                    });
                    fetchDataMeeting()
                } else {
                    console.log('Failed to delete the member.');
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };

    const onSave = () => {
        if (popupState === "processing") {

            if (!title || title?.trim() === '') {
                setErrorTitle('Title  are required.'); // Set the error message
                return;
            }
            if (!statusId) {
                setErrorStatus('Status are required.'); // Set the error message
                return;
            } else {
                setErrorStatus('')
            }

            if (!currentStartDate || !currentEndDate) {
                setErrorEndStatus('Error 404')
                return
            } else {
                setErrorEndStatus('')
            }

            if (new Date(currentEndDate) <= new Date(currentStartDate)) {
                // errors.currentEndDate = 'ວັນທີ່ສິ້ນສຸດຕໍ່ວັນທີ່ໝົດກໍ່ຖືກຕ້ອງ';
                errorSwal(t('End date must be more than Start Date'))
                return; // Return if validation fails
            } else {
                setErrorEndStatus('')
            }

            setFile('')
            addProcessing({ title });

            // setUploadedFiles([])
        } else if (popupState === "assignment") {
            if (!title || title?.trim() === '') {
                setErrorTitle('Title  are required.'); // Set the error message
                return;
            }
            if (!statusId) {
                setErrorStatus('Status are required.'); // Set the error message
                return;
            } else {
                setErrorStatus('')
            }

            if (!title) {
                setErrorTitle('Title  are required.'); // Set the error message
                return;
            }

            addAssignment({ title });
            // setUploadedFiles([])
            setFile('')
        } else {
            if (!title) {
                setErrorTitle('Title  are required.'); // Set the error message
                return;
            }
            addComment({ title });
            setErrorStatus()
        }
    }

    const onClose = () => {
        setTitle('')
        setFile('')
        setDetail('')
        setCurrentStartDate()
        setCurrentEndDate()
        setCurrentStartTime()
        setCurrentEndTime()
        setStatusId('')
        handleclose()
        setSelectEditData('')
        setEmployeesMember([])
        setUploadedFiles([])
        setMeetingData()
        setAssignMentData()
        setSelected_Employee([])
    }

    const onEditSave = () => {
        if (popupState === "processing") {
            if (!title || title?.trim() === '') {
                setErrorTitle('Title  are required.'); // Set the error message
                return;
            }
            if (!statusId) {
                setErrorStatus('Status are required.'); // Set the error message
                return;
            } else {
                setErrorStatus('')
            }

            if (new Date(currentEndDate) <= new Date(currentStartDate)) {
                // errors.currentEndDate = 'ວັນທີ່ສິ້ນສຸດຕໍ່ວັນທີ່ໝົດກໍ່ຖືກຕ້ອງ';
                errorSwal(t('End date must be more than Start Date'))
                return; // Return if validation fails
            }

            handleEditFileMeetings(selectEditData?.meeting_uid)
            setFile('')
            // setUploadedFiles([])
            setSelectEditData('')
            setFilePreview('')
            UpdateProcessing({ title });
        }
        else if (popupState === "assignment") {
            if (!title || title?.trim() === '') {
                setErrorTitle('Title  are required.'); // Set the error message
                return;
            } else {
                setErrorTitle('')
            }

            if (!statusId) {
                setErrorStatus('Status are required.'); // Set the error message
                return;
            } else {
                setErrorStatus('')
            }


            handleEditFileAssignment(selectEditData?.as_id)
            setFile('')
            setSelectEditData('')
            // setUploadedFiles([])
            setFilePreview('')
            UpdateAssignment()
        }
    }

    const onEditClose = () => {
        handleclose()
        setSelectEditData('')
        setUploadedFiles([])
        setStatusId('')
        setDetail('')
        setCurrentStartDate()
        setCurrentEndDate()
        setCurrentStartTime()
        setCurrentEndTime()
        setFile(null);
        setMeetingData()
        setAssignMentData()
    }

    const onDrop = useCallback((acceptedFiles) => {
        setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        // accept: ['image/*', '.pdf'],
    });

    const deleteFile = (index) => {
        const updatedFiles = [...uploadedFiles];
        updatedFiles.splice(index, 1);
        setUploadedFiles(updatedFiles);
    };

    const items = [
        {
            key: '1',
            label: t('Meeting Or Schedule'),
            children:
                <div className='row' style={{ display: popupState === "assignment" ? "none" : "" }}>

                    <div className='col-6'>
                        <Form.Label style={{ fontSize: 14 }}>{t('startDate')}</Form.Label>
                        {/* <Form.Control
                            type="datetime-local"
                            value={currentStartDate}
                            onChange={(e) => setCurrentStartDate(e.target.value)}
                            disabled // Add the disabled attribute here
                            style={{ fontSize: 14, borderRadius: 8 }}
                        /> */}
                        <DatePicker
                            value={currentStartDate}
                            onChange={onChangeDate}
                            placeholder={t("Select Date")}
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            valueFormat="YYYY-MM-DD HH:mm:ss"
                            allowClear={false} // Disable the clear icon
                            style={{
                                fontSize: 14, borderRadius: 8, width: "100%", height: 40,
                                border: errorEndStatus ? "1px solid red" : "",
                            }}
                            needConfirm={false} />
                    </div>

                    <div className='col-6'>

                        <Form.Label style={{ fontSize: 14 }}>{t('endDate')}</Form.Label>
                        {/* <Form.Control
                            type="datetime-local"
                            value={currentEndDate}
                            onChange={(e) => setCurrentEndDate(e.target.value)}
                            style={{ fontSize: 14, borderRadius: 8 }}
                        /> */}
                        <DatePicker
                            value={currentEndDate}
                            onChange={onChangeEndDate}
                            placeholder={t("Select Date")}
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            valueFormat="YYYY-MM-DD HH:mm:ss"
                            allowClear={false} // Disable the clear icon
                            style={{
                                fontSize: 14, borderRadius: 8, width: "100%", height: 40,
                                border: errorEndStatus ? "1px solid red" : "",
                            }}
                            needConfirm={false} />
                    </div>

                    {/* <div className='col-3'>
                        <Form.Label style={{ fontSize: 14 }}>{t('startTime')}</Form.Label>
                        <Form.Control
                            type="time"
                            value={currentStartTime}
                            onChange={(e) => setCurrentStartTime(e.target.value)}
                            style={{ fontSize: 14, borderRadius: 8 }}
                        />
                    </div>
                    <div className='col-3'>
                        <Form.Label style={{ fontSize: 14 }}>{t('endTime')}</Form.Label>
                        <Form.Control
                            type="time"
                            value={currentEndTime}
                            onChange={(e) => setCurrentEndTime(e.target.value)}
                            style={{ fontSize: 14, borderRadius: 8 }}
                        />
                    </div> */}

                </div>
        },
    ];

    const editorConfig = {
        // toolbar: true,
        fontSize: {
            options: [9, 11, 13, "default", 17, 19, 21],
        },
        ckfinder: {
            uploadUrl: "https://example.com/upload",
        },
        height: 400,
        // placeholder: 'ປ້ອນລາຍລະອຽດ...',

    };

    const decodeHTMLEntities = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    // console.log("selectEditData===================>", selectEditData)

    return (
        <Modal
            title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {editMode === true ? popupState === "processing" ? <span style={{ fontSize: 20 }}>{t("Edit Processing")}</span> :
                        popupState === "assignment" ? <span style={{ fontSize: 20 }}>{t("Edit Assignment")}</span> :
                            popupState === "comment" ? t("add comment") : "" : popupState === "processing" ? <span style={{ fontSize: 20 }}>{t("Create Processing")}</span> :
                        popupState === "assignment" ? <span style={{ fontSize: 20 }}>{t("Create Assignment")}</span> :
                            popupState === "comment" ? t("add comment") : ""}
                    <div style={{ display: "flex" }}>
                        <Avatar.Group
                            maxCount={5}
                            size="large"
                            maxStyle={{
                                color: '#f56a00',
                                backgroundColor: '#fde3cf',
                                display: editMode === true ? "none" : ""
                            }}
                        >
                            <>
                                {selected_employee?.map((employee, index) => (
                                    // Check if employee_id is not an empty string before rendering the Tooltip and Button
                                    employee?.employee_id !== "" && (
                                        <Tooltip key={employee?.name} placement="top">
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#87d068',
                                                    display: editMode === true ? "none" : ""
                                                }}
                                                // src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2"
                                                icon={<UserOutlined />}
                                            />
                                        </Tooltip>
                                    )
                                ))}

                            </>
                        </Avatar.Group>
                        &emsp;
                        <Button
                            style={{ display: editMode === true ? 'none' : "" }}
                            onClick={() => {
                                setShowViewMember(true)
                            }}
                        >
                            {t("All")}
                        </Button>
                        &emsp;
                        <Button
                            style={{ display: editMode === true ? 'none' : "" }}
                            onClick={() => {
                                setShowAddmember(true)
                            }}
                            // type="primary"
                            // style={{ background: "#00842e" }}
                            icon={<PlusOutlined />}>
                            {t('Add Member')}
                        </Button>
                        &emsp;
                        <Button
                            // type="primary"
                            // style={{ background: "#00842e" }}
                            onClick={() =>
                                setShowEmail(true)
                            }
                            icon={<MailOutlined />}>
                            {t('Email')}
                        </Button>

                    </div>
                </div>
            }
            open={show}
            width={800}
            centered
            transitionName={false}
            // maskTransitionName={false}
            closeIcon={false}
            footer={[
                <Button
                    style={{ height: 40 }}
                    icon={<CloseOutlined />}
                    key="cancel"
                    onClick={editMode === true ? onEditClose : onClose}>
                    {t("cancel")}
                </Button>,
                <Button
                    icon={editMode === true ? <EditOutlined /> : <CheckOutlined />}
                    key="ok"
                    type="primary" // Add this line to set the button type to primary
                    style={{ backgroundColor: '#00842e', height: 40 }}
                    onClick={editMode === true ? onEditSave : onSave}
                    disabled={isLoading}
                >
                    {loadingCreate ? <Spinner size='sm' /> : editMode === true ? t("edit") : t("save")}
                </Button>
            ]}
        >
            <div>
                <Divider />
                <div className='row'>
                    <div className='col-6'>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: 14 }}>
                                {t("Title")} {popupState === "processing" ? "Processing" :
                                    popupState === "assignment" ? "Assignment" :
                                        popupState === "comment" ? t("add comment") : ""
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name='title'
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                    setErrorTitle('')
                                }}
                                value={title}
                                isInvalid={errorTitle}
                                style={{ fontSize: 14, height: 40 }}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-6'>
                        <div style={{ display: popupState === "comment" ? "none" : "" }}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: 14 }}>{t("status")}</Form.Label>
                                {/* <Form.Select
                                    onChange={(e) => {
                                        setStatusId(e.target.value)
                                        setErrorStatus('')
                                    }}
                                    value={statusId}
                                    style={{ fontSize: 14 }}
                                    isInvalid={errorStatus}
                                >
                                    <option>{t("-select status-")}</option>

                                    {status?.result?.map((e, index) => (
                                        <option value={e?.status_id}>{e?.name}</option>
                                    ))}
                                </Form.Select> */}
                                <Select
                                    value={statusId}
                                    defaultValue={t("-select status-")}
                                    style={{
                                        width: '100%',
                                        height: 40,
                                        border: errorStatus ? "1px solid red" : "", borderRadius: 8
                                    }}
                                    onChange={(value) => setStatusId(value)}
                                    options={status?.results?.map((e) => ({
                                        value: e?.status_id,  // Replace with the actual value from your category object
                                        label: e?.name,  // Replace with the actual label from your category object
                                    }))}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>

            {
                popupState === "processing" && (
                    <Collapse items={items} defaultActiveKey={1} />
                )
            }

            <div style={{ display: popupState === "comment" ? "none" : "" }}>
                <Form.Group className="mb-3 mt-3">
                    <Form.Label style={{ fontSize: 14 }}>{t("Description")}</Form.Label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={detail}
                        borderRadius={false}
                        border={false}
                        config={editorConfig}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setDetail(data);
                        }}
                    />
                </Form.Group>
            </div>

            <div style={{ display: popupState === "comment" ? "none" : "", paddingBottom: 20 }}>
                <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 50 }}>
                    <div style={{ width: "100%" }}>
                        <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <Button>{t("Upload Image / Document")}</Button>
                        </div>
                        <div className=" mt-3">
                            {uploadedFiles?.map((file, index) => (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    // alignItems: "center",
                                    fontSize: 16,
                                    justifyContent: 'flex-start',
                                    width: "100%"
                                }} key={"file" + index}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                            {
                                                ['png', 'jpg', 'jpeg', 'gif', 'svg',].includes(file?.name?.split('.').pop().toLowerCase()) ?
                                                    <div>
                                                        <Image
                                                            width={100}
                                                            height={50}
                                                            style={{ objectFit: "contain" }}
                                                            src={URL.createObjectURL(file)}
                                                        />
                                                        <span
                                                            className="detail-file-download"
                                                        >
                                                            &nbsp;
                                                            {file?.name}
                                                        </span>
                                                    </div>
                                                    : file?.name?.split('.').pop().toLowerCase() === 'pdf' ?
                                                        <div>
                                                            <Image
                                                                width={100}
                                                                height={50}
                                                                style={{ objectFit: "contain" }}
                                                                src={'https://icons.iconarchive.com/icons/treetog/file-type/256/pdf-icon.png'}
                                                            />
                                                            <span
                                                                className="detail-file-download"
                                                            >
                                                                &nbsp;
                                                                {file?.name}
                                                            </span>
                                                        </div> : file?.name?.split('.').pop().toLowerCase() === 'docx' ?
                                                            <div>
                                                                <Image
                                                                    width={100}
                                                                    height={50}
                                                                    style={{ objectFit: "contain" }}
                                                                    src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Word-icon.png'}
                                                                />
                                                                <span
                                                                    className="detail-file-download"
                                                                >
                                                                    &nbsp;
                                                                    {file?.name}
                                                                </span>
                                                            </div> : file?.name?.split('.').pop().toLowerCase() === 'xlsx' ?
                                                                <div>
                                                                    <Image
                                                                        width={100}
                                                                        height={50}
                                                                        style={{ objectFit: "contain" }}
                                                                        src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Excel-icon.png'}
                                                                    />
                                                                    <span
                                                                        className="detail-file-download"
                                                                    >
                                                                        &nbsp;
                                                                        {file?.name}
                                                                    </span>
                                                                </div> :
                                                                <div>
                                                                    <span
                                                                        className="detail-file-download"
                                                                    >
                                                                        &nbsp;
                                                                        {file?.name}
                                                                    </span>
                                                                </div>
                                            }

                                            <div>
                                                <Button onClick={() => { deleteFile(index) }} >{t("delete")}</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>

            {/* ===========> When Edit Data */}
            <div style={{ display: editMode === true ? "" : "none" }}>
                {
                    popupState === "processing" ?
                        <>
                            {meetingData?.files?.map((item, index) => (

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    // alignItems: "center",
                                    fontSize: 16,
                                    justifyContent: 'flex-start',
                                }} key={"file" + index}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                            {
                                                ['png', 'jpg', 'jpeg', 'gif', 'svg',].includes(item?.originalname?.split('.').pop().toLowerCase()) ?
                                                    <div>
                                                        <Image
                                                            width={100}
                                                            height={50}
                                                            style={{ objectFit: "contain" }}
                                                            src={item?.images?.[0]}
                                                        />
                                                        <span
                                                            className="detail-file-download"
                                                        >
                                                            &nbsp;
                                                            {item?.originalname}
                                                        </span>
                                                    </div>
                                                    : item?.originalname?.split('.').pop().toLowerCase() === 'pdf' ?
                                                        <div>
                                                            <Image
                                                                preview={true}
                                                                width={100}
                                                                height={50}
                                                                style={{ objectFit: "contain" }}
                                                                src={'https://icons.iconarchive.com/icons/treetog/file-type/256/pdf-icon.png'}
                                                            />
                                                            <span
                                                                className="detail-file-download"
                                                            >
                                                                &nbsp;
                                                                {item?.originalname}
                                                            </span>
                                                        </div> : item?.originalname?.split('.').pop().toLowerCase() === 'docx' ?
                                                            <div>
                                                                <Image
                                                                    width={100}
                                                                    height={50}
                                                                    style={{ objectFit: "contain" }}
                                                                    src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Word-icon.png'}
                                                                />
                                                                <span
                                                                    className="detail-file-download"
                                                                >
                                                                    &nbsp;
                                                                    {item?.originalname}
                                                                </span>
                                                            </div> : item?.originalname?.split('.').pop().toLowerCase() === 'xlsx' ?
                                                                <div>
                                                                    <Image
                                                                        width={100}
                                                                        height={50}
                                                                        style={{ objectFit: "contain" }}
                                                                        src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Excel-icon.png'}
                                                                    />
                                                                    <span
                                                                        className="detail-file-download"
                                                                    >
                                                                        &nbsp;
                                                                        {item?.originalname}
                                                                    </span>
                                                                </div> :
                                                                <div>
                                                                    <span
                                                                        className="detail-file-download"
                                                                    >
                                                                        &nbsp;
                                                                        {item?.originalname}
                                                                    </span>
                                                                </div>
                                            }

                                            <div>
                                                <Button onClick={() => { handleDeleteFileMeeting(item?.uplaod_id) }}>{t('delete')}</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                            ))}
                        </> :
                        <>
                            {assignmentData?.files?.map((item, index) => (

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    // alignItems: "center",
                                    fontSize: 16,
                                    justifyContent: 'flex-start',
                                }} key={"file" + index}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                            {
                                                ['png', 'jpg', 'jpeg', 'gif', 'svg',].includes(item?.originalname?.split('.').pop().toLowerCase()) ?
                                                    <div>
                                                        <Image
                                                            transitionName={false}
                                                            width={100}
                                                            height={50}
                                                            style={{ objectFit: "contain" }}
                                                            src={item?.images?.[0]}
                                                        />
                                                        <span
                                                            className="detail-file-download"
                                                        >
                                                            &nbsp;
                                                            {item?.originalname}
                                                        </span>
                                                    </div>
                                                    : item?.originalname?.split('.').pop().toLowerCase() === 'pdf' ?
                                                        <div>
                                                            <Image
                                                                preview={true}
                                                                width={100}
                                                                height={50}
                                                                style={{ objectFit: "contain" }}
                                                                src={'https://icons.iconarchive.com/icons/treetog/file-type/256/pdf-icon.png'}
                                                            />
                                                            <span
                                                                className="detail-file-download"
                                                            >
                                                                &nbsp;
                                                                {item?.originalname}
                                                            </span>
                                                        </div> : item?.originalname?.split('.').pop().toLowerCase() === 'docx' ?
                                                            <div>
                                                                <Image
                                                                    width={100}
                                                                    height={50}
                                                                    style={{ objectFit: "contain" }}
                                                                    src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Word-icon.png'}
                                                                />
                                                                <span
                                                                    className="detail-file-download"
                                                                >
                                                                    &nbsp;
                                                                    {item?.originalname}
                                                                </span>
                                                            </div> : item?.originalname?.split('.').pop().toLowerCase() === 'xlsx' ?
                                                                <div>
                                                                    <Image
                                                                        width={100}
                                                                        height={50}
                                                                        style={{ objectFit: "contain" }}
                                                                        src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Excel-icon.png'}
                                                                    />
                                                                    <span
                                                                        className="detail-file-download"
                                                                    >
                                                                        &nbsp;
                                                                        {item?.originalname}
                                                                    </span>
                                                                </div> :
                                                                <div>
                                                                    <span
                                                                        className="detail-file-download"
                                                                    >
                                                                        &nbsp;
                                                                        {item?.originalname}
                                                                    </span>
                                                                </div>
                                            }

                                            <div>
                                                <Button onClick={() => { handleDeleteFileAssignment(item?.file_id) }}>ລຶບ</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                            ))}
                        </>
                }
            </div>


            {/* ===========> End When Edit Data */}

            <Addmember
                show={showAddMember}
                handleclose={() => setShowAddmember(false)}
                members={members}
                selected_employee={selected_employee}
                setSelected_Employee={setSelected_Employee}
                employees_member={employees_member}
                setEmployeesMember={setEmployeesMember}
                project={project}
            />

            <ViewMember
                show={showViewMember}
                handleClose={() => setShowViewMember(false)}
                employees_member={employees_member}
                setEmployeesMember={setEmployeesMember}
                selected_employee={selected_employee}
                setSelected_Employee={setSelected_Employee}
                project={project}
            />

            <Email
                show={showEmail}
                handleClose={() => setShowEmail(false)}
                memberAssignHistory={memberAssignHistory}
                members={members}
                selectEditData={selectEditData}
                setSelectEditData={setSelectEditData}
            />
        </Modal >
    )
}
