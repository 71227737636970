import React, { useState, useEffect } from 'react';
import { CheckCircleOutlined, UserOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Input, List, Avatar, Checkbox, notification } from 'antd';
import { SERVER_URL } from '../../api';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEmployeesMember } from '../../contexts/EmployeesMemberContext';
import { tokenData } from '../../const';

export default function AddmemberMeeting({
    show,
    members,
    meetingId,
    fetchMemberHistoryMeeting,
    fetchMeetingMember,
    memberMeetingHistory,
    handleclose, }) {

    // --------------------- useState ---------------------

    const { t } = useTranslation()
    const [selectAll, setSelectAll] = useState(false);
    const { employees_member, setEmployeesMember, selectEditData } = useEmployeesMember()
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState(members?.Members || []);
    const ContainerHeight = "70vh";
    const _checkSelected = (productId) => memberHistory?.some(({ employee_id }) => employee_id === productId)
    const memberHistory = memberMeetingHistory?.results?.filter(name => name?.meeting_id === meetingId?.meeting_uid)

    // --------------------- useEffect ---------------------

    useEffect(() => {
        if (members) setFilteredResults(members?.Members || [])
    }, [members])

    useEffect(() => {
        if (!searchQuery) {
            setFilteredResults(members?.Members || [])
        }
        const newFilteredResults = members?.Members?.filter((item) =>
            item?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    }, [searchQuery])

    // --------------------- fuctions ---------------------

    const handleSearch = () => {
        const newFilteredResults = members?.Members?.filter((item) =>
            item?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    };

    const onScroll = (e) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
            //   appendData();
        }
    };

    const onClose = () => {
        handleclose()
        setEmployeesMember([])
    }

    const handleCheckboxChange = (employee) => {
        // Check if the employee is already in the selected employees list
        const isSelected = employees_member.some((e) => e?.employee_id === employee.employee_id);

        if (selectAll) {
            // If "Select All" is checked, remove the employee from the selected list
            const updatedEmployees = employees_member.filter((e) => e?.employee_id !== employee.employee_id);
            setEmployeesMember(updatedEmployees);
        } else {
            // If "Select All" is not checked, toggle selection for the employee
            if (isSelected) {
                // If the employee is already selected, remove them
                const updatedEmployees = employees_member.filter((e) => e?.employee_id !== employee.employee_id);
                setEmployeesMember(updatedEmployees);
            } else {
                // If the employee is not selected, add them
                const updatedEmployees = [...employees_member, { employee_id: employee.employee_id }];
                setEmployeesMember(updatedEmployees);
            }
        }
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            // If "Select All" is checked, select all employees

            const allEmployees = filteredResults.map((employee) => ({ employee_id: employee?.employee_id }));

            // Check if employee_id is not already in setEmployeesMember
            const uniqueEmployees = allEmployees.filter((employee) => !memberHistory.some((emp) => emp.employee_id === employee.employee_id));

            setEmployeesMember(prevEmployees => [...prevEmployees, ...uniqueEmployees]);
        } else {
            // If "Select All" is unchecked, deselect all employees
            setEmployeesMember([]);
        }
    };

    const handleAddMembers = () => {
        setLoading(true)

        // // Transform and filter the employees_member array
        // const transformedEmployeesMember = employees_member
        //     .map((e) => {
        //         if (e.employee_id) {
        //             return {
        //                 employee_id: e.employee_id,
        //             };
        //         }
        //         return null; // Exclude elements with missing data
        //     })
        //     .filter((e) => e !== null);

        // Use a Set to store unique employee IDs
        const uniqueEmployeeIds = new Set();

        // Filter and add unique employee IDs to the set
        employees_member.forEach((e) => {
            if (e?.employee_id) {
                uniqueEmployeeIds.add(e?.employee_id);
            }
        });

        // Convert the set back to an array of objects
        const transformedEmployeesMember = Array.from(uniqueEmployeeIds).map((employee_id) => ({
            employee_id
        }));

        if (transformedEmployeesMember.length === 0) {
            // Show an error message when there's no valid data to send
            notification.error({
                message: t("Error"),
                description: t("Please select an employee"),
                duration: 2,
            });
            setLoading(false);
            return;
        }

        const data = {
            meeting_id: meetingId?.meeting_uid,
            membersDetails: transformedEmployeesMember
        };

        if (loading) return;

        fetch(`${SERVER_URL + "meeting/create-Meeting-Members"}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    notification.success({
                        message: t("Success"),
                        description: t("save success"),
                        duration: 2,
                    });
                    // fetchAssignmentData()
                    setEmployeesMember([])
                    fetchMemberHistoryMeeting()
                    fetchMeetingMember()
                    handleclose()
                    setLoading(false)
                    setSelectAll(false)
                } else {
                    setLoading(false)
                    console.log('Failed to add members to the project.');
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                setLoading(false)
            });
    };

    // console.log("employees_member", employees_member)

    return (
        <div>
            <Modal
                centered
                title={<div>{t("Add Member")}</div>}
                open={show}
                onOk={handleAddMembers}
                okText={loading ? <Spinner size='sm' /> : t("save")}
                cancelText={t('cancel')}
                footer={null} // Disable the default footer
                transitionName={false}
                // maskTransitionName={false}
                onCancel={onClose}>
                <div style={{ paddingLeft: 20, paddingRight: 20, height: "80vh" }}>
                    <div style={{ paddingTop: 20 }}>
                        <Input
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            value={searchQuery}
                            placeholder={t("Enter the employee's name")}
                            style={{ fontFamily: 'Noto Sans Lao, sans-serif', }}
                        />
                        <Button
                            type="default"
                            style={{ marginTop: 10 }}
                        // disabled={employees_member?.length > 1 ? true : false}
                        >
                            <Checkbox checked={selectAll} onChange={handleSelectAll}>
                                ເລືອກທັງໝົດ
                            </Checkbox>
                        </Button>
                    </div>
                    <div style={{ height: ContainerHeight, overflow: "auto", paddingTop: 20 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={filteredResults?.sort((a, b) => a.name.localeCompare(b.name))}
                            height={ContainerHeight}
                            itemHeight={47}
                            onScroll={onScroll}
                            renderItem={(item, index) => (
                                <List.Item style={{ background: _checkSelected(item?.employee_id) ? "#e3f9f7" : "" }}>
                                    <List.Item.Meta
                                        onChange={() => handleCheckboxChange(item)}
                                        avatar={
                                            <div style={{ display: "flex", cursor: "pointer" }} >
                                                <div style={{ display: "flex" }}>
                                                    {_checkSelected(item?.employee_id) ?
                                                        <CheckCircleOutlined style={{ fontSize: 20, color: "green", paddingRight: 20 }} /> :
                                                        <Checkbox
                                                            style={{ paddingRight: 20 }}
                                                            checked={_checkSelected(item?.employee_id) || employees_member.some((p) => p.employee_id === item?.employee_id)}
                                                            onChange={() => handleCheckboxChange(item)} />}
                                                </div>
                                                <Avatar onClick={() => handleCheckboxChange(item)}
                                                    style={{
                                                        backgroundColor: '#87d068',
                                                    }}
                                                    icon={<UserOutlined />}
                                                /></div>}
                                        title={<div onClick={() => handleCheckboxChange(item)} style={{ cursor: "pointer", fontWeight: _checkSelected(item?.employee_id) ? 600 : "" }}>{item.name} {item.surname}</div>}
                                        description={<div onClick={() => handleCheckboxChange(item)} >
                                            <div>{item?.email}</div>
                                            <div>{item?.member_status === 0 ? t("User") : t("Admin")}</div>
                                        </div>}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Button icon={<CloseOutlined />} style={{ height: 40 }} onClick={onClose}>{t("cancel")}</Button>&emsp;
                    <Button
                        loading={loading}
                        icon={<CheckOutlined />}
                        type="primary"
                        style={{ background: "#00842e", height: 40 }}
                        onClick={handleAddMembers}>
                        {t("save")}
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
