import React, { useEffect } from 'react';
import EmployeesMemberProvider from './contexts/EmployeesMemberContext';
import AssignmentProvider from './contexts/AssignmentContext';
import BranchProvider from './contexts/BranchContext';
import DepartmentProvider from './contexts/DepartmentContext';
import ManagementProvider from './contexts/ManagementContext';
import ProjectProvider from './contexts/ProjectContext';
import StatusProvider from './contexts/StatusContext';
import { I18nextProvider } from "react-i18next";
import i18n from './language/i18n';
import Swal from 'sweetalert2';
import { ConfigProvider } from 'antd';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Router from './route/Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import MemberProvider from './contexts/MemberContext';
import DeadlineProvider from './contexts/DeadlineContext';
import CalendarProvider from './contexts/CalendarContext';
import MeetingProvider from './contexts/MeetingContext';
import setAuthToken from './helper/setAuthToken';
import OutMeetingProvider from './contexts/OutMeetingContext';
import UserGroupProvider from './contexts/UserGroupContext';
import './App.css';
import { tokenData } from './const';

function App() {

  const setCookie = (name, value, days) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  const handleButtonClick = async () => {
    try {
      const response = await fetch('http://192.168.30.50:3800/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // username: "sonethavyph",
          username: "alisaxaya",
          // username: "admin",
          // username: "john",
          password: "123456",
        })
      });

      if (!response.ok) {
        throw new Error('Authentication failed');
      }

      const data = await response.json();
      setCookie('user', JSON.stringify(data));

    } catch (error) {
      // console.error('Login error', error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!tokenData) {
      Swal.fire({
        icon: "info",
        title: "ກາລຸນາເຂົ້າສູ່ລະບົບ",
        allowOutsideClick: false,
        confirmButtonAriaLabel: "Thumbs up, great!",
        confirmButtonText: `ເຂົ້າສູ່ລະບົບ`,
        confirmButtonColor: "green",
      }).then((result) => {
        if (result.isConfirmed) {
          // handleButtonClick()
          window.location.href = "https://psvsystem.com"
        }
      });
    } else {
      if (tokenData?.token) {
        setAuthToken(tokenData?.token)
      }
    }
  }, [tokenData])

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Noto Sans Lao",
            colorPrimary: "green",
          },
          components: {
            Radio: {
              radioSize: 20
            },
            Tabs: {
              itemHoverColor: "green",
              itemSelectedColor: "green",
              titleFontSize: 14,
              cardBg: "white",
              itemColor: "green",
              itemSelectedColor: "white",
              colorBgContainer: "green"
            },
          },
        }}>
        <I18nextProvider i18n={i18n}>
          <UserGroupProvider>
            <OutMeetingProvider>
              <MeetingProvider>
                <CalendarProvider>
                  <EmployeesMemberProvider>
                    <DeadlineProvider>
                      <MemberProvider>
                        <AssignmentProvider>
                          <BranchProvider>
                            <DepartmentProvider>
                              <StatusProvider>
                                <ProjectProvider>
                                  <ManagementProvider>
                                    <Router />
                                    <ToastContainer />
                                  </ManagementProvider>
                                </ProjectProvider>
                              </StatusProvider>
                            </DepartmentProvider>
                          </BranchProvider>
                        </AssignmentProvider>
                      </MemberProvider>
                    </DeadlineProvider>
                  </EmployeesMemberProvider>
                </CalendarProvider>
              </MeetingProvider>
            </OutMeetingProvider>
          </UserGroupProvider>
        </I18nextProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
