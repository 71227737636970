import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import { tokenData } from '../const';

const AssignContext = createContext()

export default function AssignmentProvider({ children }) {

    const [assignments, setAssignments] = useState([])
    const [assignmentFile, setAssignMentFile] = useState([])
    const [loading, setLoading] = useState(true);

    const fetchData = () => {
        fetch(`${SERVER_URL + "ass/assignments"}`, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAssignments(data?.results);
                setAssignMentFile(data?.files)
                setLoading(false);
            })
            .catch((error) => {
                // console.error('Error fetching data:', error);
                setLoading(false);
            });
        // setLoading(false);
    };

    useEffect(() => {
        if (!tokenData?.token) return
        fetchData(); // Fetch initial data when the component mounts
    }, []);

    return (
        <AssignContext.Provider value={{ assignments, loading, fetchData, assignmentFile, setAssignMentFile }}>
            {children}
        </AssignContext.Provider>
    )
}

export function useAssignment() {
    return useContext(AssignContext)
}
