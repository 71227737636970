import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import { tokenData } from '../const';

const DeadlineContext = createContext()

export default function DeadlineProvider({ children }) {

    const [deadline, setDeadline] = useState([])
    const [notification, setNotification] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!tokenData?.token) return
        fetchNotification()
        fetchData() // Fetch initial data when the component mounts
    }, []);

    const fetchData = () => {
        setLoading(true);
        try {
            fetch(`${SERVER_URL + "proj/missed-deadline"}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setDeadline(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                    setLoading(false);
                });
        } catch (error) {
            console.log('Error: ', error)
        }
        // setLoading(false);
    };

    const fetchNotification = () => {
        try {
            fetch(`${SERVER_URL + "proj/notifications"}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setNotification(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                    setLoading(false);
                });
        } catch (error) {
            console.log("Error: ", error)
        }
    }




    return (
        <DeadlineContext.Provider value={{ notification, deadline, loading, fetchData, fetchNotification }}>
            {children}
        </DeadlineContext.Provider>
    )
}

export function useDeadline() {
    return useContext(DeadlineContext)
}