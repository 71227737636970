import React from 'react'
import { useRoutes, Outlet, Navigate } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Login from '../pages/login/Login'
import Home from '../pages/home/Home'
import Management from './../pages/management/Management';
import HomeScreen from '../pages/home'
import UserGroupList from '../pages/usergroup/UserGroupList'
import AllProjectList from '../pages/home/AllProjectList'
import CreateUserGroup from '../pages/usergroup/CreateUserGroup'
import UpdateUserGroup from '../pages/usergroup/UpdateUserGroup'


export default function Router() {
    return useRoutes([
        {
            path: "/",
            element: <PrivateRoute />,
            children: [
                {
                    path: "/",
                    element: <HomeScreen />,
                },
                {
                    path: "/management-list",
                    element: <Home />,
                },
                {
                    path: "/management/:id",
                    element: <Management />,
                },
                {
                    path: "/user-group-list",
                    element: <UserGroupList />,
                },
                {
                    path: "/all-project-list",
                    element: <AllProjectList />,
                },
                {
                    path: "/create-group",
                    element: <CreateUserGroup />,
                },
                {
                    path: "/update-user-group/:id",
                    element: <UpdateUserGroup />,
                }
            ]
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '*',
            element: <h1>404</h1>
        },
    ])
}
