import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import moment from 'moment';
import dayjs from 'dayjs';
import { tokenData } from '../const';

const CalendarContext = createContext()

export default function CalendarProvider({ children }) {

    const [events, setEvents] = useState([]);
    const [eventDetail, setEvenDetail] = useState()

    useEffect(() => {
        if (!tokenData?.token) return
        fetchEvent()
    }, []);

    const decodeHTMLEntities = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    const fetchEvent = () => {
        fetch(`${SERVER_URL + "meeting/get-all-meeting"}`, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                // 'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Map your data to the format expected by FullCalendar
                const formattedEvents = data?.results.map((item) => ({
                    // startTime: item.start_time,
                    // endTime: item.end_time,
                    title: decodeHTMLEntities(item.title),
                    start: moment(item.from_date)?.format("YYYY-MM-DD HH:mm:ss"),
                    end: moment(item.to_date)?.format("YYYY-MM-DD HH:mm:ss"),
                    meeting_id: item.meeting_id,
                    projects_id: item.projects_id,
                }));

                setEvents(formattedEvents);

            })
            .catch((error) => console.log('Error fetching events:', error));
    }

    return (
        <CalendarContext.Provider value={{ events, eventDetail, setEvenDetail, setEvents, fetchEvent }}>
            {children}
        </CalendarContext.Provider>
    )
}

export function useCalendar() {
    return useContext(CalendarContext)
}