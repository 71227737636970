import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import { tokenData } from '../const';

const MeetingContext = createContext()

export default function MeetingProvider({ children }) {

    const [Meeting, setMeeting] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!tokenData?.token) return
        fetchMeeting()
    }, []);

    const fetchMeeting = () => {
        fetch(`${SERVER_URL + "meeting/get-all-meeting"}`, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setMeeting(data?.results);
                setLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching data:', error);
                setLoading(false);
            });
    }

    return (
        <MeetingContext.Provider value={{ Meeting, fetchMeeting }}>
            {children}
        </MeetingContext.Provider>
    )
}

export function useMeeting() {
    return useContext(MeetingContext)
}
