import _ from "lodash";
import Swal from "sweetalert2";

export const onConfirmButton = (title, confirmButtonContent, cancelButtonContent, onConfirm) => {
    Swal.fire({
        title: title,
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FFA500",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmButtonContent,
        cancelButtonText: cancelButtonContent
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm();
        }
    });
};

export const USER_KEY = "USER_DATA"
export const VERSION = "VERSION 1.0.0 | PHONGSAVANH GROUP"

export const getUserDataFromLCStorage = () => {
    const _user = localStorage.getItem(USER_KEY);
    if (_.isEmpty(_user)) {
        return ""
    }
    const user = JSON.parse(_user);
    return user;
};

export const isUserLogin = () => {
    const userData = getUserDataFromLCStorage();
    //  check if user login or not : boolean
    const isAuthenticated = !_.isEmpty(userData);
    return isAuthenticated;
};


export const numberFormat = (_number) => {
    return new Intl.NumberFormat('en-US').format(_number);
}






const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
};

const tokenString = getCookie('user');

// Decode the URL-encoded string
const decodedTokenString = decodeURIComponent(tokenString);

export const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;