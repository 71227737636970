import React from 'react'
import { Button } from 'react-bootstrap'

export default function CustomButton({
    icons,
    title,
    width,
    border,
    color,
    onclick,
    height,
    borderRadius,
    marginTop,
    fontSize,
    marginLeft,
    background }) {
    return (
        <div>
            <Button style={{
                width: width ? width : 250,
                height: 40,
                background: background ? background : "blue",
                border: border ? border : "",
                color: color ? color : "",
                height: height ? height : "",
                borderRadius: borderRadius ? borderRadius : "",
                marginTop: marginTop ? marginTop : "",
                fontSize: fontSize ? fontSize : "",
                marginLeft: marginLeft ? marginLeft : ""
            }} onClick={onclick}>
                {icons ?? ""}
                {title}
            </Button>
        </div>
    )
}
