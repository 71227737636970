import React, { useState, useEffect } from 'react';
import { CheckCircleOutlined, SyncOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Tag, Collapse, Image, Divider, Button } from 'antd';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useCalendar } from '../../contexts/CalendarContext';
import { SERVER_URL } from '../../api';
import he from 'he';
import './Management.css'

export default function ViewDetailManagement({ show, handleClose, DetailData, setDetailData, popupState }) {

    const [assignmentData, setAssignMentData] = useState()
    const [meetingData, setMeetingData] = useState()
    const [calendarImage, setCalendarImage] = useState()
    const [calendarDate, setCalendarDate] = useState()
    const { t } = useTranslation()
    const { eventDetail, setEvenDetail } = useCalendar()
    const [disableButtons, setDisableButtons] = useState(true);

    useEffect(() => {
        if (DetailData) {
            fetchDataAssignment()
            fetchDataMeeting()
        }
    }, [DetailData])

    useEffect(() => {
        fetchDataAssignment()
        fetchDataMeeting()
        fetchMeeting()
    }, [])

    useEffect(() => {
        if (eventDetail) {
            fetch(`${SERVER_URL + "meeting/get-meeting/" + eventDetail}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setCalendarImage(data?.files)
                    setCalendarDate(data?.results)
                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                });

        }
    }, [eventDetail])

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const decodedHtml = he.decode(DetailData?.descriptions ?? '');
    const decodedHtmlCalendar = he.decode(DetailData?.result?.[0]?.description ?? '');

    const editorConfig = {
        toolbar: false,
    };

    const tokenString = getCookie('user');

    // Decode the URL-encoded string
    const decodedTokenString = decodeURIComponent(tokenString);

    const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

    const fetchDataAssignment = () => {
        fetch(`${SERVER_URL + "ass/get-assignment/" + DetailData?.as_id}`, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAssignMentData(data);
                // setLoading(false);
            })
            .catch((error) => {
                // console.log('Error fetching data:', error);
                // setLoading(false);
            });
        // setLoading(false);
    };

    const fetchDataMeeting = () => {
        fetch(`${SERVER_URL + "meeting/get-meeting/" + DetailData?.meeting_uid}`, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setMeetingData(data);
                // setLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching data:', error);
                // setLoading(false);
            });
        // setLoading(false);
    };


    const fetchMeeting = () => {
        fetch(`${SERVER_URL + "meeting/get-meeting/" + eventDetail}`, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setDetailData(data);
            })
            .catch((error) => {
                console.log('Error fetching data:', error);
            });
    }

    const Title = (
        <p
            style={{
                paddingLeft: 24,
            }}
        >
            {DetailData?.comments ? `ຄວາມຄິດເຫັນ : ${DetailData?.comments}` : ` ${DetailData?.title || DetailData?.result?.[0]?.title} `}
        </p>
    );

    const Description = (
        <p
            style={{
                paddingLeft: 24,
            }}
        >
            {/* <div dangerouslySetInnerHTML={{ __html: decodedHtml }} /> */}
            <CKEditor
                editor={ClassicEditor}
                data={decodedHtml || decodedHtmlCalendar}
                borderRadius={false}
                border={true}
                config={editorConfig}
                disabled
            />
        </p>
    );

    const MeetingDate = (
        <div style={{ paddingLeft: 24 }}>
            <div style={{ display: calendarDate ? "none" : "" }}>{t('startDate')} {moment(DetailData?.from_date)?.isValid() ? moment(DetailData?.from_date).format("DD-MM-YYYY") : "-"} {t('endDate')} {moment(DetailData?.to_date)?.isValid() ? moment(DetailData?.to_date).format("DD-MM-YYYY") : "-"}</div>
            <div style={{ display: calendarDate ? "" : "none" }}>{t('startDate')} {moment(calendarDate?.[0]?.from_date)?.isValid() ? moment(calendarDate?.[0]?.from_date).format("DD-MM-YYYY") : "-"} {t('endDate')} {moment(calendarDate?.[0]?.to_date)?.isValid() ? moment(calendarDate?.[0]?.to_date).format("DD-MM-YYYY") : "-"}</div>
            <div style={{ display: calendarDate ? "none" : "" }} className='mt-3'>{t("startTime")} {moment(DetailData?.from_date)?.isValid() ? moment(DetailData?.from_date).format("HH:mm") : "-"} {t("endTime")} {moment(DetailData?.to_date)?.isValid() ? moment(DetailData?.to_date).format("HH:mm") : "-"} </div>
            {/* <div style={{ display: calendarDate ? "" : "none" }} className='mt-3'>ເວລາ {calendarDate?.[0]?.start_time === "undefined" || "" ? "-" : calendarDate?.[0]?.start_time} - {calendarDate?.[0]?.end_time === "undefined" || "" ? "-" : calendarDate?.[0]?.end_time}  </div> */}
            <div style={{ display: calendarDate ? "" : "none" }} className='mt-3'>{t("startTime")} {moment(calendarDate?.[0]?.from_date)?.isValid() ? moment(calendarDate?.[0]?.from_date).format("HH:mm") : "-"} {t("endTime")} {moment(calendarDate?.[0]?.to_date)?.isValid() ? moment(calendarDate?.[0]?.to_date).format("HH:mm") : "-"}   </div>
        </div>
    )

    const Documents = (
        <div style={{ width: "100%", padding: 30 }}>

            <div style={{ display: popupState === "assignment" ? "" : "none" }}> {assignmentData?.files?.length === undefined || "" ? t("No Document") : ""}</div>
            <div style={{ display: popupState === "processing" ? "" : "none" }}> {meetingData?.files?.length === undefined || "" ? t("No Document") : ""}</div>

            {assignmentData?.files?.map((item, index) => (

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    fontSize: 16,
                    justifyContent: 'flex-start',
                }} key={"file" + index}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                            {
                                ['png', 'jpg', 'jpeg', 'gif', 'svg',].includes(item.originalname.split('.').pop().toLowerCase()) ?
                                    <div>
                                        <Image
                                            width={100}
                                            height={50}
                                            style={{ objectFit: "contain" }}
                                            src={item?.images?.[0]}
                                        />
                                        <span
                                            className="detail-file-download"
                                        >
                                            &nbsp;
                                            {item?.originalname}
                                        </span>
                                    </div>
                                    : item.originalname.split('.').pop().toLowerCase() === 'pdf' ?
                                        <div>
                                            <Image
                                                width={100}
                                                height={50}
                                                style={{ objectFit: "contain" }}
                                                src={'https://icons.iconarchive.com/icons/treetog/file-type/256/pdf-icon.png'}
                                            />
                                            <span
                                                className="detail-file-download"
                                            >
                                                &nbsp;
                                                {item?.originalname}
                                            </span>
                                        </div> : item.originalname.split('.').pop().toLowerCase() === 'docx' ?
                                            <div>
                                                <Image
                                                    width={100}
                                                    height={50}
                                                    style={{ objectFit: "contain" }}
                                                    src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Word-icon.png'}
                                                />
                                                <span
                                                    className="detail-file-download"
                                                >
                                                    &nbsp;
                                                    {item?.originalname}
                                                </span>
                                            </div> : item.originalname.split('.').pop().toLowerCase() === 'xlsx' ?
                                                <div>
                                                    <Image
                                                        width={100}
                                                        height={50}
                                                        style={{ objectFit: "contain" }}
                                                        src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Excel-icon.png'}
                                                    />
                                                    <span
                                                        className="detail-file-download"
                                                    >
                                                        &nbsp;
                                                        {item?.originalname}
                                                    </span>
                                                </div> :
                                                <div>
                                                    <span
                                                        className="detail-file-download"
                                                    >
                                                        &nbsp;
                                                        {item?.originalname}
                                                    </span>
                                                </div>
                            }

                            <div>
                                <Button
                                    href={`${item?.images?.[0]}`}
                                    // onClick={() => navigate(item?.images?.[0])}
                                    style={{ background: "#00842e" }}
                                    type="primary">{t('Download')}</Button>
                            </div>
                        </div>
                    </div>
                    <Divider />
                </div>
            ))}

            {meetingData?.files?.map((item, index) => (

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    fontSize: 16,
                    justifyContent: 'flex-start',
                }} key={"file" + index}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                            {
                                ['png', 'jpg', 'jpeg', 'gif', 'svg',].includes(item.originalname.split('.').pop().toLowerCase()) ?
                                    <div>
                                        <Image
                                            width={100}
                                            height={50}
                                            style={{ objectFit: "contain" }}
                                            src={item?.images?.[0]}
                                        />
                                        <span
                                            className="detail-file-download"
                                        >
                                            &nbsp;
                                            {item?.originalname}
                                        </span>
                                    </div>
                                    : item.originalname.split('.').pop().toLowerCase() === 'pdf' ?
                                        <div>
                                            <Image
                                                width={100}
                                                height={50}
                                                style={{ objectFit: "contain" }}
                                                src={'https://icons.iconarchive.com/icons/treetog/file-type/256/pdf-icon.png'}
                                            />
                                            <span
                                                className="detail-file-download"
                                            >
                                                &nbsp;
                                                {item?.originalname}
                                            </span>
                                        </div> : item.originalname.split('.').pop().toLowerCase() === 'docx' ?
                                            <div>
                                                <Image
                                                    width={100}
                                                    height={50}
                                                    style={{ objectFit: "contain" }}
                                                    src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Word-icon.png'}
                                                />
                                                <span
                                                    className="detail-file-download"
                                                >
                                                    &nbsp;
                                                    {item?.originalname}
                                                </span>
                                            </div> : item.originalname.split('.').pop().toLowerCase() === 'xlsx' ?
                                                <div>
                                                    <Image
                                                        width={100}
                                                        height={50}
                                                        style={{ objectFit: "contain" }}
                                                        src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Excel-icon.png'}
                                                    />
                                                    <span
                                                        className="detail-file-download"
                                                    >
                                                        &nbsp;
                                                        {item?.originalname}
                                                    </span>
                                                </div> :
                                                <div>
                                                    <span
                                                        className="detail-file-download"
                                                    >
                                                        &nbsp;
                                                        {item?.originalname}
                                                    </span>
                                                </div>
                            }

                            <div>
                                <Button
                                    href={`${item?.images?.[0]}`}
                                    // onClick={() => navigate(item?.images?.[0])}
                                    style={{ background: "#00842e" }}
                                    type="primary">{t("Download")}</Button>
                            </div>
                        </div>
                    </div>
                    <Divider />
                </div>
            ))}

            {calendarImage?.map((item, index) => (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    fontSize: 16,
                    justifyContent: 'flex-start',
                }} key={"file" + index}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                            {
                                ['png', 'jpg', 'jpeg', 'gif', 'svg',].includes(item?.originalname?.split('.')?.pop()?.toLowerCase()) ?
                                    <div>
                                        <Image
                                            width={100}
                                            height={50}
                                            style={{ objectFit: "contain" }}
                                            src={item?.images?.[0]}
                                        />
                                        <span
                                            className="detail-file-download"
                                        >
                                            &nbsp;
                                            {item?.originalname}
                                        </span>
                                    </div>
                                    : item.originalname.split('.').pop().toLowerCase() === 'pdf' ?
                                        <div>
                                            <Image
                                                width={100}
                                                height={50}
                                                style={{ objectFit: "contain" }}
                                                src={'https://icons.iconarchive.com/icons/treetog/file-type/256/pdf-icon.png'}
                                            />
                                            <span
                                                className="detail-file-download"
                                            >
                                                &nbsp;
                                                {item?.originalname}
                                            </span>
                                        </div> : item.originalname.split('.').pop().toLowerCase() === 'docx' ?
                                            <div>
                                                <Image
                                                    width={100}
                                                    height={50}
                                                    style={{ objectFit: "contain" }}
                                                    src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Word-icon.png'}
                                                />
                                                <span
                                                    className="detail-file-download"
                                                >
                                                    &nbsp;
                                                    {item?.originalname}
                                                </span>
                                            </div> : item.originalname.split('.').pop().toLowerCase() === 'xlsx' ?
                                                <div>
                                                    <Image
                                                        width={100}
                                                        height={50}
                                                        style={{ objectFit: "contain" }}
                                                        src={'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/256/Excel-icon.png'}
                                                    />
                                                    <span
                                                        className="detail-file-download"
                                                    >
                                                        &nbsp;
                                                        {item?.originalname}
                                                    </span>
                                                </div> :
                                                <div>
                                                    <span
                                                        className="detail-file-download"
                                                    >
                                                        &nbsp;
                                                        {item?.originalname}
                                                    </span>
                                                </div>
                            }

                            <div>
                                <Button
                                    href={`${item?.images?.[0]}`}
                                    // onClick={() => navigate(item?.images?.[0])}
                                    style={{ background: "#00842e" }}
                                    type="primary">{t("Download")}</Button>
                            </div>
                        </div>
                    </div>
                    <Divider />
                </div>
            ))}
        </div>
    );

    const ExitModal = () => {
        handleClose()
        setEvenDetail()
    }

    const Status = (
        <div
            style={{
                paddingLeft: 24,
            }}
        >
            <div>
                {/* {ConvertStatus(DetailData?.status)} */}
                {DetailData?.status === "Finished" || calendarDate?.[0]?.s_name === "Finished" ?
                    <Tag icon={<CheckCircleOutlined />} color="success">
                        {t("Finished")}
                    </Tag> : DetailData?.status === "Pending" || calendarDate?.[0]?.s_name === "Pending" ? <Tag icon={< ClockCircleOutlined />} color="processing">
                        {t("Pending")}
                    </Tag> : DetailData?.status === "Processing" || calendarDate?.[0]?.s_name === "Processing" ? <Tag icon={<SyncOutlined spin />} color="default">
                        {t("Processing")}
                    </Tag> : DetailData?.status === "Error" || calendarDate?.[0]?.s_name === "Error" ? <Tag icon={<CloseCircleOutlined />} color="error">
                        {t("ErrorS")}
                    </Tag> : ""}
            </div>
            <div className='mt-3'>{t("Update At")} : {moment(DetailData?.updated_at || DetailData?.result?.[0]?.updated_at).format("DD-MM-YYYY HH:mm A")}</div>
            <div className='mt-3'>{t("Update By")} : {DetailData?.update_by || DetailData?.result?.[0]?.update_by}</div>
        </div>
    );

    const items = [
        {
            key: '1',
            label: <div style={{ fontWeight: 600 }}>{t("Title")}</div>,
            children: Title,
            defaultActive: true,
        },
        {
            key: '2',
            label: <div style={{ fontWeight: 600 }}>{t('Meeting Or Schedule')}</div>,
            children: MeetingDate,
        },
        {
            key: '3',
            label: <div style={{ fontWeight: 600 }}>{t("Description")}</div>,
            children: Description,
            defaultActive: true,
        },

        {
            key: '4',
            label: <div style={{ fontWeight: 600 }}>{t("Image / Document")}</div>,
            children: Documents,
        },
        {
            key: '5',
            label: <div style={{ fontWeight: 600 }}>{t("status")}</div>,
            children: Status,
        },
    ];

    const filteredItems = popupState === "processing" || !popupState
        ? [...items]  // If popupState is "processing", include all items
        : items.filter(item => item.key !== '2');  // Exclude item with key '2' for other states


    // console.log("Error=========>calendarDate", calendarDate)

    return (
        <React.Fragment>
            <Modal
                title={<div style={{ color: "#FFFFFF" }}>'-'</div>}
                width={800}
                centered
                open={show}
                transitionName={false}
                // maskTransitionName={false}
                onCancel={ExitModal}
                // closable={false}
                okButtonProps={{
                    style: {
                        display: disableButtons ? 'none' : 'block',
                    },
                    disabled: disableButtons
                }}
                cancelButtonProps={{
                    style: {
                        display: disableButtons ? 'none' : 'block'
                    },
                    disabled: disableButtons
                }}
            >
                <Collapse
                    items={filteredItems}
                    bordered={false}
                    defaultActiveKey={items.filter(item => item.defaultActive).map(item => item.key)} />

            </Modal>
        </React.Fragment>
    )
}
