import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined, CheckOutlined, SearchOutlined, UserOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { List, Avatar, Input, Space, Button, Card, Form, Table, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useMember } from '../../contexts/MemberContext';
import { SERVER_URL } from '../../api';
import { useUserGroup } from '../../contexts/UserGroupContext';
import { useTranslation } from 'react-i18next';
import { tokenData } from '../../const';
import useWindowDimensions from '../../helper/useWindowDimensions';
import { errorPermissionTitleWithTextSwal } from '../../helper/sweetalert';

export default function UpdateUserGroup() {

    const { id } = useParams()
    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const { t } = useTranslation()
    const { members, setMembers } = useMember()
    const { fetchUserGroup } = useUserGroup()
    const [title, setTitle] = useState()
    const [userGroupData, setUerGroupData] = useState()
    const [cardData, setCardData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [Delete, setDelete] = useState("")
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState(members?.results || []);

    useEffect(() => {
        if (members?.results) setFilteredResults(members?.results || [])
    }, [members?.results])

    useEffect(() => {
        if (!searchQuery) {
            setFilteredResults(members?.results || [])
        }
        const newFilteredResults = members?.results?.filter((item) =>
            item?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    }, [searchQuery])

    useEffect(() => {
        fetchUserGroupOne()
    }, [])

    useEffect(() => {
        if (userGroupData) {
            setTitle(userGroupData?.[0]?.group_name)
            setCardData(userGroupData?.[0]?.employees)
        }
    }, [userGroupData])

    useEffect(() => {
        if (!cardData) {
            fetchUserGroup()
            navigate('/user-group-list')
        }
    }, [cardData])

    const fetchUserGroupOne = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}group/usergroup/${id}`);
            setUerGroupData(response.data?.results);
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const columns = [
        {
            title: t("No/"),
            dataIndex: 'number',
            key: 'number',
            render: (text) => <a>{text}</a>,
            ellipsis: true,
        },
        {
            title: t("User Name"),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
            ellipsis: true,
        },
        // {
        //     title: 'ອີເມວ',
        //     dataIndex: 'email',
        //     key: 'email',
        // },
        {
            title: t("Management"),
            dataIndex: 'delete',
            key: 'delete',
            ellipsis: true,
        },
    ]

    const data = cardData?.map((e, index) => ({
        number: index + 1,
        name: e?.surname || e?.employee_name,
        email: e?.email,
        delete: <Button icon={<DeleteOutlined />} onClick={() => onDeleteMemberInGroup(e?.employee_id)} >{t("delete")}</Button>
    }))

    const onDeleteMemberInGroup = async (DeteleId) => {

        const GroupId = id;
        const route = `${SERVER_URL}group/delete-memberId/${GroupId}/${DeteleId}`;

        try {
            await axios.delete(route, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                },
            });
            notification.success({
                message: t('Success'),
                description: t('delete success'),
                duration: 2,
            });
            fetchUserGroupOne()
        } catch (error) {
            console.log("Error: ", error)
            errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
        }
    }

    const handleCreateUserGroup = async (EmployeeId) => {

        setLoading(true);
        const GroupId = id;

        // Check if the member with DeteleId exists in cardData
        if (cardData?.some(item => item?.employee_id === EmployeeId)) {
            notification.error({
                message: t('Error'),
                description: t('Sorry, this list already exists.'),
                duration: 2,
            });
            setLoading(false);
            return; // Stop execution if member doesn't exist
        }

        try {

            const requestBody = {
                employees: [
                    {
                        employee_id: EmployeeId
                    }
                ]
            };

            // Make the request
            axios.put(`${SERVER_URL}group/add-new-members/${GroupId}`, requestBody, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                },
            });

            fetchUserGroupOne()

            notification.success({
                message: t('Success'),
                description: t('save success'),
                duration: 2,
            });

            setLoading(false)

        } catch (error) {

            console.log("Error: ", error)
            setLoading(false)

        }
    }

    const onUpdateTitleGroup = async () => {
        setLoading(true);
        const GroupId = id;
        try {
            const requestBody = {
                group_name: title
            };
            // Make the request
            axios.put(`${SERVER_URL}group/update/${GroupId}`, requestBody, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                },
            });

            await fetchUserGroup()

            notification.success({
                message: t('Success'),
                description: t('update success'),
                duration: 2,
            });
            setLoading(false);
            navigate('/user-group-list')
        } catch (error) {
            console.log("Error: ", error)
            setLoading(false);
        }
    }

    return (
        <div style={{ marginTop: 20 }}>
            <Card
                // title="Card title"
                bordered={false}
                style={{
                    width: '100%',
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: width < 700 ? "column" : ""
                }}>
                    <div style={{ fontSize: 20 }}>{t("Update User Group")}</div>
                    <Space>
                        <Button
                            onClick={() => navigate('/user-group-list')}
                            style={{ height: 40 }}
                            // type="primary"
                            icon={<ArrowLeftOutlined />}>
                            {t("Back")}
                        </Button>
                        {/* <Button
                            style={{ height: 40 }}
                            // type="primary"
                            icon={<CloseOutlined />}>
                            ຍົກເລີກ
                        </Button> */}
                        <Button
                            loading={loading}
                            onClick={() => {
                                if (!title || title?.trim() === '') {
                                    notification.error({
                                        message: t('Error'),
                                        description: t("Please Input Title Group"),
                                        duration: 2,
                                    });
                                    return;
                                }
                                fetchUserGroup()
                                onUpdateTitleGroup();
                            }
                            }
                            style={{ height: 40 }}
                            type="primary"
                            icon={<CheckOutlined />}>
                            {t("update")}
                        </Button>
                    </Space>
                </div>
            </Card>

            <Card
                // title="Card title"
                bordered={false}
                style={{
                    width: '100%',
                    marginTop: 20
                }}
            >
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: width < 700 ? 'column' : ""
                }}>
                    <div style={{ width: width < 700 ? "100%" : "50%" }}>
                        <div>
                            <Input
                                onChange={(e) => setSearchQuery(e.target.value)}
                                value={searchQuery}
                                style={{ height: 40 }}
                                placeholder={t('Search Employee Name')}
                                prefix={<SearchOutlined />} />
                        </div>

                        <div style={{ height: "55vh", overflow: "auto" }}>
                            <List
                                itemLayout="horizontal"
                                // dataSource={getChoicesForQuestion(cardData?.map(item => item?.employee_id))}
                                dataSource={filteredResults?.sort((a, b) => a.name.localeCompare(b.name))}
                                // height={ContainerHeight}
                                itemHeight={47}
                                // onScroll={onScroll}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <div style={{ display: "flex", cursor: "pointer" }} >

                                                    <Avatar
                                                        style={{
                                                            backgroundColor: '#87d068',
                                                        }}
                                                        icon={<UserOutlined />}
                                                    />
                                                </div>
                                            }
                                            title={
                                                <div>
                                                    {item.name} {item?.surname}
                                                </div>
                                            }
                                            description={<div>{item?.email}</div>}
                                        />
                                        <div style={{ display: "flex" }}>
                                            <Button
                                                disabled={tokenData?.id === item?.employee_id ? true : false}
                                                onClick={() => {
                                                    handleCreateUserGroup(item?.employee_id)
                                                    fetchUserGroupOne()
                                                }}
                                                style={{ height: 40 }}
                                                // type="primary"
                                                icon={<PlusCircleOutlined />}>
                                                {t('add')}
                                            </Button>

                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>

                    </div>
                    <div style={{ width: width < 700 ? "100%" : "50%", marginTop: width < 700 ? "50px" : "" }}>
                        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <Input
                                style={{ height: 40, marginBottom: 20, border: !title || title?.trim() === '' ? "1px solid red" : "" }}
                                value={title}
                                placeholder={t('Input Title Group')}
                                onChange={(e) => setTitle(e.target.value)} />
                        </div>

                        {/* <div style={{ textAlign: 'center', fontSize: 16 }}>ລາຍຊື່</div> */}
                        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <Table
                                scroll={{ x: true }}
                                columns={columns}
                                dataSource={data}
                                pagination={{ pageSize: 5 }}
                            />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
