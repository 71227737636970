import React, { useState, useEffect, useMemo } from 'react'
import { Form, ProgressBar, Spinner } from 'react-bootstrap'
import { Formik } from 'formik';
import moment from 'moment';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SERVER_URL } from '../../api';
import { errorSwal } from '../../helper/sweetalert';
import { useProject } from '../../contexts/ProjectContext';
import { CloseOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Divider, notification, Modal, Select, DatePicker } from 'antd';
import { tokenData } from '../../const';
dayjs.extend(utc);
export default function CreateProject({
    show,
    loadingCreate,
    setLoadingCreate,
    department,
    status,
    selectEditData,
    branch,
    setSelectEditData,
    handleclose }) {

    const { t } = useTranslation();
    const [id, setId] = useState()
    const [projectName, setProjectName] = useState()
    const [statusId, setStatusId] = useState()
    const [departmentId, setDepartmentId] = useState()
    const [branchId, setBranchId] = useState()
    const [currentStartDate, setCurrentStartDate] = useState(null);
    const [currenExtendDate, setCurrentExtendDate] = useState()
    const [percent, setPercent] = useState(1);
    const { fetchData, fetchMyProject } = useProject()
    const [editMode, setEditMode] = useState(false)
    const [disableButtons, setDisableButtons] = useState(true);

    useEffect(() => {
        if (selectEditData) {
            setEditMode(selectEditData?.editMode)
        } else {
            setEditMode(false)
        }
    }, [selectEditData])

    useEffect(() => {
        if (editMode === true) {
            setProjectName(selectEditData?.p_name)
            setDepartmentId(selectEditData?.department_department_id)
            setBranchId(selectEditData?.branch)
            setStatusId(selectEditData?.status)
            setCurrentStartDate(
                moment(selectEditData?.dl).format('YYYY-MM-DD')
            )
            // const formattedStartDate = dayjs(selectEditData?.dl);
            // setCurrentStartDate(formattedStartDate)
            setCurrentExtendDate(
                moment(selectEditData?.extend).format('YYYY-MM-DD')
            )
            setPercent(selectEditData?.precent)
            setId(selectEditData?.project_id)
        } else {
            setProjectName('')
            setDepartmentId('')
            setBranchId('')
            setStatusId('')
            setCurrentStartDate('')
            setCurrentExtendDate('')
            setPercent('')
        }
    }, [editMode])


    const onCloseModal = () => {
        handleclose()
        setSelectEditData('')
        setProjectName('')
        setDepartmentId('')
        setBranchId('')
        setStatusId('')
        setCurrentStartDate('')
        setCurrentExtendDate('')
        setPercent('')
    }

    const handleRangeChange = (event) => {
        const selectedPercent = parseInt(event.target.value, 10);
        setPercent(selectedPercent);
    };

    const handleStatusChange = (value) => {
        const selectedStatusId = value;

        // Check if the selected status is "Finish"
        if (selectedStatusId === 'e35569eb-75e1-4005-9232-bfb57303b8b3') {
            setPercent(100);
            setStatusId(selectedStatusId);
        } else {
            // Set percent to 0 or any other value if needed for other statuses
            setPercent(1);
            setStatusId(selectedStatusId);
        }

        setStatusId(selectedStatusId);
    };

    const getProgressBarStyle = () => {
        if (percent > 99) {
            return 'success';
        } else {
            return "warning"; // Default style
        }
    };

    // console.log("====percent",percent)

    return (

        <Modal
            title={editMode ? <span style={{ fontSize: 20 }}>{t("Update Project")}</span> : <span style={{ fontSize: 20 }}>{t("Create Project")}</span>}
            open={show}
            centered
            okText={t("save")}
            cancelText={t("cancel")}
            transitionName={false}
            okButtonProps={{
                style: {
                    display: disableButtons ? 'none' : 'block',
                },
                disabled: disableButtons
            }}
            cancelButtonProps={{
                style: {
                    display: disableButtons ? 'none' : 'block'
                },
                disabled: disableButtons
            }}
            onCancel={onCloseModal}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    project_name: projectName,
                    department_id: departmentId,
                    branch_id: branchId,
                    status_id: statusId,
                    precent_of_project: percent,
                    deadline: moment.parseZone(currentStartDate).toISOString(),
                    extended: moment.parseZone(currenExtendDate).toISOString()
                }}
                validate={(values) => {
                    const errors = {};
                    if (!projectName || projectName.trim() === '') {
                        errors.project_name = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!departmentId) {
                        errors.departmentId = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!branchId) {
                        errors.branchId = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!statusId) {
                        errors.statusId = 'ກະລຸນາປ້ອນຊື່';
                    }
                    // if (!percent) {
                    //     errors.percent = 'ກາລຸນາເລືອກເປີເຊັນ';
                    // }
                    if (!currentStartDate && !editMode) {
                        errors.currentStartDate = 'ກະລຸນາປ້ອນຊື່';
                    }
                    // Add validation for endDate > startDate
                    if (new Date(currenExtendDate) <= new Date(currentStartDate)) {
                        errors.currenExtendDate = 'ວັນທີ່ສິ້ນສຸດຕໍ່ວັນທີ່ໝົດກໍ່ຖືກຕ້ອງ';
                        errorSwal(t('First deadline must be more than second deadline'))
                    }
                    // if (!currenExtendDate && !editMode) {
                    //     errors.currenExtendDate = 'ກະລຸນາປ້ອນຊື່';
                    // }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {

                    values.department_id = String(values.department_id);
                    values.branch_id = String(values.branch_id);

                    setLoadingCreate(true)

                    if (editMode === true) {
                        if (loadingCreate) return;
                        const apiUrl = `${SERVER_URL + "proj/update/" + id}`;
                        axios.put(apiUrl, values, {
                            headers: {
                                'Authorization': `Bearer ${tokenData?.token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then((response) => {
                                notification.success({
                                    message: t('Success'),
                                    description: t('update success'),
                                    duration: 2,
                                });
                                handleclose()
                                fetchData()
                                fetchMyProject()
                                setSelectEditData('')
                                setLoadingCreate(false)
                            })
                            .catch((error) => {
                                setSelectEditData('')
                                handleclose()
                                setLoadingCreate(false)
                                errorSwal("Error Can not Update Project")
                                // console.log("errror project",error)
                            });
                    }
                    else {
                        if (loadingCreate) return;
                        const apiUrl = `${SERVER_URL + "proj/insert"}`;
                        axios.post(apiUrl, values, {
                            headers: {
                                'Authorization': `Bearer ${tokenData?.token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then((response) => {
                                notification.success({
                                    message: t('Success'),
                                    description: t('save success'),
                                    duration: 2,
                                });
                                handleclose()
                                fetchData()
                                fetchMyProject()
                                setLoadingCreate(false)
                                setProjectName('')
                                setDepartmentId('')
                                setBranchId('')
                                setStatusId('')
                                setCurrentStartDate('')
                                setCurrentExtendDate('')
                                setPercent('')
                            })
                            .catch((error) => {
                                notification.error({
                                    message: 'Error',
                                    description: 'Failed to create the project.',
                                    duration: 2,
                                });
                                handleclose()
                                setLoadingCreate(false)
                                setProjectName('')
                                setDepartmentId('')
                                setBranchId('')
                                setStatusId('')
                                setCurrentStartDate('')
                                setCurrentExtendDate('')
                                setPercent('')
                            });
                    }
                    setSubmitting()

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (

                    <>
                        <Divider />
                        <div>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: 14, color: "#121212BF", fontWeight: 400 }}>{t("Name of Project")}</Form.Label>
                                <Form.Control
                                    name='project_name'
                                    type="text"
                                    onChange={(e) => setProjectName(e.target.value)}
                                    value={projectName}
                                    isInvalid={errors?.project_name}
                                    style={{ fontSize: 14, borderRadius: 8, height: 40 }}
                                // placeholder={t('Name of Project') + "..."}
                                />
                            </Form.Group>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <Form.Label style={{ fontSize: 14, color: "#121212BF", fontWeight: 400 }}>{t("Department")}</Form.Label>
                                <Select
                                    value={departmentId}
                                    defaultValue={t("-select department-")}
                                    style={{
                                        width: '100%',
                                        height: 40,
                                        border: errors?.departmentId ? "1px solid red" : "", borderRadius: 8
                                    }}
                                    onChange={(value) => setDepartmentId(value)}
                                    options={department?.results?.map((e) => ({
                                        value: e?.department_id,  // Replace with the actual value from your category object
                                        label: e?.department_name,  // Replace with the actual label from your category object
                                    }))}
                                />
                            </div>
                            <div className='col-6'>
                                <Form.Label style={{ fontSize: 14, color: "#121212BF", fontWeight: 400 }}>{t("Branch")}</Form.Label>
                                <Select
                                    value={branchId}
                                    defaultValue={t('-select branch-')}
                                    placeholder={t('-select branch-')}
                                    style={{
                                        width: '100%',
                                        height: 40,
                                        border: errors?.branchId ? "1px solid red" : "", borderRadius: 8
                                    }}
                                    onChange={(value) => setBranchId(value)}
                                    options={branch?.results?.map((e) => ({
                                        value: e?.branch_id,  // Replace with the actual value from your category object
                                        label: e?.name,  // Replace with the actual label from your category object
                                    }))}
                                />
                            </div>
                        </div>

                        <div>
                            <Form.Label style={{ fontSize: 14, color: "#121212BF", fontWeight: 400, marginTop: 10 }}>{t("status")}</Form.Label>
                            <Select
                                value={statusId}
                                defaultValue={t('-select status-')}
                                style={{
                                    width: '100%',
                                    height: 40,
                                    border: errors?.statusId ? "1px solid red" : "", borderRadius: 8
                                }}
                                onChange={handleStatusChange}
                                options={status?.results?.map((e) => ({
                                    value: e?.status_id,  // Replace with the actual value from your category object
                                    label: e?.name,  // Replace with the actual label from your category object
                                }))}
                            />
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{ fontSize: 14, color: "#121212BF", fontWeight: 400, marginTop: 10 }}>{t("deadline")}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={currentStartDate}
                                        onChange={(e) => setCurrentStartDate(e.target.value)}
                                        style={{ fontSize: 14, borderRadius: 8, height: 40 }}
                                        isInvalid={errors?.currentStartDate}
                                        disabled={editMode === true ? true : false}
                                        placeholder="name..." />
                                    {/* <DatePicker
                                        value={currentStartDate}
                                        valueFormat="YYYY-MM-DD"
                                        onChange={onChangeStartDate}
                                        placeholder='ເລືອກວັນທີ'
                                        format={'YYYY-MM-DD'}
                                        disabled={editMode === true ? true : false}
                                        allowClear={false}
                                        style={{ width: '100%', height: 40 }} /> */}
                                </Form.Group>

                            </div>
                            <div className='col-6'>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{ fontSize: 14, color: "#121212BF", fontWeight: 400, marginTop: 10 }}>{t("deadline2")}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={currenExtendDate}
                                        onChange={(e) => setCurrentExtendDate(e.target.value)}
                                        style={{ fontSize: 14, borderRadius: 8, height: 40 }}
                                        placeholder="name..." />
                                    {/* <DatePicker
                                        format={'DD/MM/YYYY'}
                                        allowClear={false}
                                        style={{ width: '100%', height: 40 }}
                                        placeholder='ເລືອກວັນທີ' /> */}
                                </Form.Group>

                            </div>

                        </div>

                        <div>
                            <Form.Group>
                                <Form.Label style={{ fontSize: 14 }}>{t("percen")} <span style={{ color: "red" }}>*</span></Form.Label>
                                {errors.percent && <div style={{ width: "100%" }}>
                                    <span style={{ fontSize: 12, color: "#F03649" }}>{errors.percent}</span>
                                </div>}
                                <Form.Range
                                    onChange={handleRangeChange}
                                    value={percent}
                                    // isInvalid={errors?.percent}
                                    min={1}
                                    max={100}
                                    variant={'warning'}
                                />
                                <ProgressBar
                                    variant={getProgressBarStyle()}
                                    now={percent}
                                    label={`${percent}%`}
                                    // isInvalid={errors?.percent}
                                    style={{
                                        height: 30,
                                    }} />
                            </Form.Group>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginTop: 20
                        }}>
                            <Button icon={<CloseOutlined />} onClick={onCloseModal} style={{ height: 40 }}>{t('cancel')}</Button>
                            <Button icon={editMode ? <EditOutlined /> : <CheckOutlined />} type="primary ms-2" style={{ background: "#00842e", height: 40 }} onClick={handleSubmit}>
                                {loadingCreate ? <Spinner size='sm' /> : editMode ? t("update") : t('save')}
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
        </Modal>
    )
}
