import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import { tokenData } from '../const';

const BranchContext = createContext()

export default function BranchProvider({ children }) {

    const [branch, setBranch] = useState([])

    useEffect(() => {
        if (!tokenData?.token) return
        fetchBranch()
    }, []);

    const fetchBranch = () => {
        try {
            fetch(`${SERVER_URL + "proj/branches"}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setBranch(data);
                })
                .catch((error) => {
                    // console.error('Error fetching data:', error);
                });
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    return (
        <BranchContext.Provider value={{ branch }}>
            {children}
        </BranchContext.Provider>
    )
}

export function useBranch() {
    return useContext(BranchContext)
}