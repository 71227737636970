import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BiEdit } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import ModalAddManagement from './ModalAddManagement'
import ConfirmDelete from '../../components/ConfirmDelete'
import './Management.css'
import useWindowDimensions from '../../helper/useWindowDimensions'
import { SERVER_URL } from '../../api'
import moment from 'moment'
import ViewAllMember from './ViewAllMember'
import { useTranslation } from 'react-i18next'
import VirtualList from 'rc-virtual-list';
import {
  EllipsisOutlined,
  ArrowLeftOutlined,
  MessageOutlined,
  RightOutlined,
  UpOutlined,
  UserOutlined,
  FieldTimeOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import {
  Button,
  message,
  Popconfirm,
  Progress,
  Spin,
  Breadcrumb,
  Select,
  Divider,
  Tag,
  notification,
  FloatButton,
  Avatar,
  Tooltip,
  List,
  Typography
} from 'antd';
import ViewDetailManagement from './ViewDetailManagement'
import MemberList from './MemberList'
import ChatManagement from './ChatManagement'
import AnimationEmpty from '../../components/AnimationEmpty'
import AddmemberAssignment from './AddmemberAssignment'
import axios from 'axios'
import ViewAllMemberAssignment from './ViewAllMemberAssignment'
import { useStatusCode } from '../../contexts/StatusContext'
import { useEmployeesMember } from '../../contexts/EmployeesMemberContext'
import { useCalendar } from '../../contexts/CalendarContext'
import AddmemberMeeting from './AddmemberMeeting'
import ViewAllMemberMeeting from './ViewAllMemberMeeting'
import { useMeeting } from '../../contexts/MeetingContext'
import { useAssignment } from '../../contexts/AssignmentContext'
import { tokenData } from '../../const'
import { useProject } from '../../contexts/ProjectContext'
import { useDeadline } from '../../contexts/DeadlineContext'
import { errorPermissionTitleWithTextSwal } from '../../helper/sweetalert'
import { useOutMeeting } from '../../contexts/OutMeetingContext'
import { renderToString } from 'react-dom/server';
import { onConfirmButton } from '../../const';

const ContainerHeight = "55vh";
const { Paragraph, Text } = Typography;

export default function Management() {

  // --------------------- useState ---------------------

  const navigate = useNavigate()
  // const [openCartDetail, setOpenCartDetail] = useState(false);
  const { fetchMyProject, fetchData: fetchProjectAll } = useProject()
  const { fetchNotification } = useDeadline()
  const [openCartDetail, setOpenCartDetail] = useState([]);
  const [openAssignDetail, setOpenAssignDetail] = useState([]);
  const { eventDetail, setEvenDetail } = useCalendar()
  const { Meeting, fetchMeeting } = useMeeting()
  const { assignments, fetchData, assignmentFile } = useAssignment()
  const { id } = useParams();
  const { t } = useTranslation();
  const [project, setProject] = useState()
  const { height, width } = useWindowDimensions();
  const { selectEditData, setSelectEditData } = useEmployeesMember()
  const [employees_member, setEmployeesMember] = useState([]);
  const [selected_employee, setSelected_Employee] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { status } = useStatusCode()
  const [showPopupAddmember, setShowPopupAddMember] = useState(false)
  const [showAddMemberMeeting, setShowAddMemberMeeting] = useState(false)
  const [showViewAllMember, setShowViewAllMember] = useState(false)
  const [showViewAllMemberMeeting, setShowViewAllMemberMeeting] = useState(false)
  const [showPopupaAdd, setShowPopupAdd] = useState(false)
  const [showPopupDelete, setShowPopupDelete] = useState(false)
  const [showPopupChat, setShowPopupChat] = useState(false)
  const [showAllMemberAssign, setShowAllMemberAssign] = useState(false)
  const [loadingAssignment, setLoadingAssignment] = useState(true)
  const [loadingData, setLoadingData] = useState(false)
  const [popupState, setPopupState] = useState()

  const [processing, setProcessing] = useState([{
    title: "",
    descriptions: "",
    status: "",
    status_id: "",
    meeting_uid: "",
    from_date: "",
    to_date: "",
    start_time: "",
    end_time: "",
    created_at: "",
    create_by: "",
    files: "",
    updated_at: "",
    update_by: "",
    total_notifications: ""
  }]);

  const [assignment, setAssignMent] = useState([{
    title: "",
    descriptions: "",
    status: "",
    status_id: "",
    as_id: "",
    created_at: "",
    create_by: "",
    files: "",
    updated_at: "",
    update_by: "",
    total_notifications: ""
  }])

  const [comment, setComment] = useState([{ comments: "", comment_id: "", create_by: "", created_at: "" }])
  const [title, setTitle] = useState()
  const [detail, setDetail] = useState('')
  const [statusId, setStatusId] = useState()
  const [DetailData, setDetailData] = useState()
  const [showViewManagement, setShowViewManagement] = useState(false)
  const [showAssignmentMember, setShowAssignmentMember] = useState(false)
  const [assignmentId, setAssignmentId] = useState()
  const [meetingId, setMeetingId] = useState()
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [file, setFile] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePreview, setFilePreview] = useState(null)
  const [isVisible, setIsVisible] = useState(false);
  const [memberAssign, setMemberAssign] = useState([])
  const [memberMeeting, setMemberMeeting] = useState([])
  const [members, setMembers] = useState([])
  const [memberAssignHistory, setMemberAssignHistory] = useState([])
  const [memberMeetingHistory, setMemberMeetingHistory] = useState([])
  const [filterProcessing, setFilterProcessing] = useState(processing || []);
  const [filterAssignment, setFilterAssignment] = useState(assignment || [])
  const [selectStatusAssignment, setSelectStatusAssignment] = useState('All Status')
  const [selectStatusProcessing, setSelectStatusProcessing] = useState('All Status')
  const [currentStartDate, setCurrentStartDate] = useState()
  const [currentEndDate, setCurrentEndDate] = useState()
  const [currentStartTime, setCurrentStartTime] = useState("10:57:01")
  const [currentEndTime, setCurrentEndTime] = useState("11:57:01")

  const [notitiFetchingAssign, setNotitiFetchingAssign] = useState(false)

  const [Delete, setDelete] = useState("");

  const confirmButtonContent = renderToString(<div>{t("delete")}</div>);
  const cancelButtonContent = renderToString(<div>{t("cancel")}</div>);
  // --------------------- useEffect ---------------------

  useEffect(() => {
    if (id) {
      fetchNotification()
      fetchProjectOne()
      fetchData()
      fetchMeeting()
    }
  }, [id]);

  useEffect(() => {
    fetchNotification()
  }, [])

  useEffect(() => {
    if (eventDetail) {
      setShowViewManagement(true)
    } else {
      setShowViewManagement(false)
    }
  }, [eventDetail])

  useEffect(() => {
    if (DetailData || showViewManagement) {
      fetchNotification()
      fetchProjectOne()
      fetchData()
      fetchMeeting()
    }
  }, [DetailData, showViewManagement])

  useEffect(() => {
    // Add a scroll event listener to check if the button should be visible
    window.addEventListener('scroll', handleScroll);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fetchProjectOne = () => {
    try {
      fetch(`${SERVER_URL + "proj/get-project/" + id}`, {
        headers: {
          'Authorization': `Bearer ${tokenData?.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setProject(data);
        })
        .catch((error) => {
          // console.error('Error fetching data:', error);
        });
    } catch (error) {
      console.log("Error: ", error)
    }
  }

  useEffect(() => {

    const MeetingFilter = Meeting?.filter(name => name?.projects_id === project?.Project?.[0]?.project_id) || []

    if (MeetingFilter) {
      const meetingsData = MeetingFilter?.map((e) => ({
        title: e.title || "",
        descriptions: e?.description,
        status: e?.s_name,
        status_id: e?.status_id,
        meeting_uid: e?.meeting_id || "",
        from_date: e?.from_date || "",
        to_date: e?.to_date || "",
        start_time: e?.start_time || "",
        end_time: e?.end_time || "",
        created_at: e?.created_at || "",
        updated_at: e?.updated_at || "",
        update_by: e?.update_by || "",
        create_by: e?.create_by || "",
        files: e?.file_name ? e.file_name.split(',') : [],
        total_notifications: e?.total_notifications || ""
      }));
      setProcessing(meetingsData);
    }
  }, [Meeting, project?.Project?.[0]?.project_id]);

  useEffect(() => {

    const AssignmentFilter = assignments?.filter(name => name?.proj_id === project?.Project?.[0]?.project_id) || []

    if (AssignmentFilter) {
      const assignmentData = AssignmentFilter?.map((e) => ({
        title: e.title || "",
        descriptions: e?.description,
        status: e?.s_name,
        status_id: e?.status_id,
        created_at: e?.created_at || "",
        as_id: e?.as_id || "",
        create_by: e?.create_by || "",
        files: e?.files || "",
        updated_at: e?.updated_at || "",
        update_by: e?.update_by || "",
        total_notifications: e?.total_notifications || ""
      }));
      setAssignMent(assignmentData);
    }
  }, [assignments, project?.Project?.[0]?.project_id]);

  useEffect(() => {
    if (project?.Comments) {
      const CommentData = project?.Comments?.map((e) => ({
        comments: e?.comments || "",
        comment_id: e?.comment_id || "",
        create_by: e?.created_by || "",
        create_by_id: e?.created_by_id || "",
        created_at: e?.created_at || ""
      }));
      setComment(CommentData);
    }
  }, [project?.Comments]);

  useEffect(() => {
    if (selectStatusProcessing === "All Status" || selectStatusProcessing === "All" || selectStatusProcessing === "ທັງໝົດ") {
      setFilterProcessing(processing || [])
    } else {
      const filtered = (processing || []).filter((e) => e.status === selectStatusProcessing);
      setFilterProcessing(filtered);
    }

  }, [selectStatusProcessing, processing]);

  useEffect(() => {
    if (selectStatusAssignment === "All Status" || selectStatusAssignment === "All" || selectStatusAssignment === "ທັງໝົດ") {
      setFilterAssignment(assignment || [])
    } else {
      const filtered = (assignment || []).filter((e) => e.status === selectStatusAssignment);
      setFilterAssignment(filtered);
    }

  }, [selectStatusAssignment, assignment]);

  useEffect(() => {
    fetchAssignmentData()
    fetchAssignMember()
    fetchMeetingMember()
    fetchMemberProject()
    fetchMemberHistory()
    fetchMemberHistoryMeeting()
  }, [])

  useEffect(() => {
    if (id) return fetchAssignmentData()
  }, [id])

  // --------------------- fuctions ---------------------

  const options = status?.result?.map((e, index) => (
    <option key={index} value={e.name}>
      {e.name}
    </option>
  ));

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const fetchMemberHistory = () => {
    axios.get(`${SERVER_URL + "assignment-members/assignment-members"}`, {
      headers: {
        'Authorization': `Bearer ${tokenData?.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setMemberAssignHistory(response.data);
      })
      .catch(error => {
        console.log('Error:', error);
      });
  }

  const fetchMemberHistoryMeeting = () => {
    axios.get(`${SERVER_URL + "meeting/Meetig-Members"}`, {
      headers: {
        'Authorization': `Bearer ${tokenData?.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setMemberMeetingHistory(response.data);
      })
      .catch(error => {
        console.log('Error:', error);
      });
  }

  const fetchMemberProject = () => {
    axios.get(`${SERVER_URL + "proj//find-Member-By-ProjectId/" + id}`, {
      headers: {
        'Authorization': `Bearer ${tokenData?.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setMembers(response.data);
      })
      .catch(error => {
        console.log('Error:', error);
      });
  }

  const fetchAssignmentData = () => {
    setLoadingData(true)
    fetch(`${SERVER_URL + "proj/get-project/" + id}`, {
      headers: {
        'Authorization': `Bearer ${tokenData?.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProject(data);
        setLoadingData(false)
      })
      .catch((error) => {
        // console.log('Error fetching data:', error);
      });
  };

  const fetchAssignMember = () => {
    // Make a GET request to the API
    axios.get(`${SERVER_URL + "assignment-members/assignment-members"}`, {
      headers: {
        'Authorization': `Bearer ${tokenData?.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        // Handle the successful response
        setMemberAssign(response.data);
      })
      .catch(error => {
        // Handle any errors
        console.log('Error:', error);
      });
  }

  const fetchMeetingMember = () => {
    axios.get(`${SERVER_URL + "meeting/Meetig-Members"}`, {
      headers: {
        'Authorization': `Bearer ${tokenData?.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        // Handle the successful response
        setMemberMeeting(response.data);
      })
      .catch(error => {
        // Handle any errors
        console.log('Error:', error);
      });
  }

  // --------------- Add-Processing ---------------

  const DeleteProcessing = async (index) => {
    // Get the assignment you want to delete
    const meetingToDelete = index;
    setLoadingAssignment(true)
    try {
      // Send a DELETE request to the server to delete the assignment
      const response = await fetch(`${SERVER_URL + "meeting/delete/" + meetingToDelete}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${tokenData?.token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        // The request was successful, you can handle it here
        // fetchAssignmentData()
        fetchMeeting()
        notification.success({
          message: t('Success'),
          description: t('delete success'),
          duration: 2,
        });
        // Remove the assignment from the local state
        const newProcessing = processing.filter((_, i) => i !== index);
        setProcessing(newProcessing);
      } else {
        errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
      }
    } catch (error) {
      errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
    }
  };

  const handleShowDelete = (id, name) => {
    setDelete({ name, id });
    onConfirmButton(t('Do you want to Delete or not?'), confirmButtonContent, cancelButtonContent, () => DeleteProcessing(id))
  };

  const addProcessing = async () => {
    try {

      setLoadingCreate(true)

      const transformedEmployeesMember = selected_employee
        .filter((e) => e.employee_id)
        .map((e) => ({
          employee_id: e.employee_id,
        }));

      // Format the date strings before appending to FormData
      const formattedStartDate = moment(currentStartDate).format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = moment(currentEndDate).format('YYYY-MM-DD HH:mm:ss')

      const formData = new FormData();

      formData.append('title', title);
      formData.append('descriptions', detail || "");
      formData.append('status_id', statusId);
      formData.append('project_id', id);
      formData.append('membersDetails', JSON.stringify(transformedEmployeesMember));
      formData.append('fromdate', currentStartDate);
      formData.append('todate', currentEndDate);
      formData.append('start_time', currentStartTime);
      formData.append('end_time', currentEndTime);
      
      uploadedFiles.forEach((file, index) => {
        formData.append(`file_name`, file || "");
      });

      // Send a POST request with the FormData

      if (loadingCreate) return;
      const response = await fetch(`${SERVER_URL + "meeting/insert"}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${tokenData?.token}`,
          // 'Content-Type': 'application/json'
        },
        body: formData,
      });

      if (response.ok) {
        notification.success({
          message: t('Success'),
          description: t('save success'),
          duration: 2,
        });
        fetchMeeting()
        fetchMemberHistoryMeeting()
        fetchMeetingMember()
        setSelectStatusProcessing('All Status')
        setShowPopupAdd(false);
        setTitle("");
        setDetail("");
        setStatusId("");
        setFile("")
        setUploadedFiles([])
        setFilePreview("")
        setCurrentStartDate('')
        setCurrentEndDate('')
        setCurrentStartTime()
        setCurrentEndTime()
        setEmployeesMember([])
        setSelected_Employee([])
      } else {
        console.log('Failed to add assignment.');
        setFilePreview("")
      }

    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoadingCreate(false)
    }
  };

  const UpdateProcessing = async () => {
    try {
      setLoadingCreate(true)

      const formData = new FormData();

      // Format the date strings before appending to FormData
      // const formattedStartDate = moment(currentStartDate).format('YYYY-MM-DD HH:mm:ss');
      // const formattedEndDate = moment(currentEndDate).format('YYYY-MM-DD HH:mm:ss')

      const data = {
        title: title,
        descriptions: detail,
        status_id: statusId,
        fromdate: currentStartDate,
        todate: currentEndDate,
        start_time: currentStartTime,
        end_time: currentEndTime
      }

      const response = await axios.put(
        `${SERVER_URL + "meeting/update/" + selectEditData?.meeting_uid}`,
        data, {
        headers: {
          'Authorization': `Bearer ${tokenData?.token}`,
          'Content-Type': 'application/json',
        },
      }
      );

      notification.success({
        message: t('Success'),
        description: t('update success'),
        duration: 2,
      });

      fetchMeeting()
      setShowPopupAdd(false);
      setDetail('')
      setSelectEditData('')
      setStatusId("");
      setCurrentStartDate('')
      setCurrentEndDate('')
      setCurrentStartTime('')
      setCurrentEndTime('')
      setUploadedFiles([])
      setDetail('')

    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoadingCreate(false)
    }
  }

  // --------------- Add-Assignment ---------------

  const DeleteAssignment = async (index) => {
    // Get the assignment you want to delete
    const assignmentToDelete = index;
    setLoadingAssignment(true)
    try {
      // Send a DELETE request to the server to delete the assignment
      const response = await fetch(`${SERVER_URL + "ass/delete/" + assignmentToDelete}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${tokenData?.token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        // The request was successful, you can handle it here
        notification.success({
          message: t('Success'),
          description: t('delete success'),
          duration: 2,
        });
        // fetchAssignmentData()
        fetchData()
        // Remove the assignment from the local state
        const newAssignmentList = assignment.filter((_, i) => i !== index);
        setAssignMent(newAssignmentList);
      } else {
        // Handle errors or validation issues here
        console.log('Failed to delete assignment.');
        errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
      }
    } catch (error) {
      errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
      // console.log('Error:', error);
    }
  };

  const handleShowDeleteAssignMent = (id, name) => {
    setDelete({ name, id });
    onConfirmButton(t('Do you want to Delete or not?'), confirmButtonContent, cancelButtonContent, () => DeleteAssignment(id))
  };

  const addAssignment = async () => {
    try {
      if (title) {
        setLoadingCreate(true)

        const transformedEmployeesMember = selected_employee
          .filter((e) => e.employee_id) // Exclude elements with missing data
          .map((e) => ({
            employee_id: e.employee_id,
          }));


        const formData = new FormData();

        formData.append('title', title);
        formData.append('descriptions', detail);
        formData.append('status_id', statusId);
        formData.append('project_id', id);
        formData.append('memberDetails', JSON.stringify(transformedEmployeesMember));
        uploadedFiles.map((blob, index) => {
          formData.append('file_name', blob);
        });

        // Send a POST request with the FormData

        if (loadingCreate) return;
        const response = await fetch(`${SERVER_URL + "ass/insert"}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${tokenData?.token}`,
            // 'Content-Type': 'application/json'
          },
          body: formData,
        });

        if (response.ok) {
          notification.success({
            message: t('Success'),
            description: t('save success'),
            duration: 2,
          });
          fetchMemberHistory()
          fetchAssignmentData()
          fetchAssignMember()
          fetchData()
          setShowPopupAdd(false);
          setSelectStatusAssignment('All Status')
          setTitle("");
          setDetail("");
          setStatusId("");
          setFile("")
          setUploadedFiles([])
          setEmployeesMember([])
          setSelected_Employee([])
          setFilePreview("")
        } else {
          console.log('Failed to add assignment.');
          setFilePreview("")
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoadingCreate(false)
    }
  }

  const UpdateAssignment = async () => {
    try {
      setLoadingCreate(true)

      const formData = new FormData();

      const data = {
        "status_id": statusId,
        "title": title,
        "descriptions": detail
      }

      const response = await axios.put(
        `${SERVER_URL + "ass/update/" + selectEditData?.as_id}`,
        data, {
        headers: {
          'Authorization': `Bearer ${tokenData?.token}`,
          'Content-Type': 'application/json',
        },
      }
      );

      notification.success({
        message: t('Success'),
        description: t('update success'),
        duration: 2,
      });

      fetchData()
      fetchAssignMember()
      setSelectEditData()
      setDetail('')
      setUploadedFiles([])
      setShowPopupAdd(false);

    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoadingCreate(false)
    }
  }

  const DeleteComment = async (index) => {
    // Get the assignment you want to delete
    const commentToDelete = index;
    try {
      // Send a DELETE request to the server to delete the assignment
      const response = await fetch(`${SERVER_URL + "project-comments/delete/" + commentToDelete}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${tokenData?.token}`,
          // 'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        // The request was successful, you can handle it here
        // message.success('ລຶບສຳເລັດ')
        fetchAssignmentData()

        // Remove the assignment from the local state
        const newCommenttList = comment.filter((_, i) => i !== index);
        setComment(newCommenttList);
      } else {
        errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
        // Handle errors or validation issues here
        console.log('Failed to delete assignment.');
      }
    } catch (error) {
      errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
      // console.log('Error:', error);
    }
  };

  const handleShowDeleteComment = (id, name) => {
    setDelete({ name, id });
    onConfirmButton(t('Do you want to Delete or not?'), confirmButtonContent, cancelButtonContent, () => DeleteComment(id))
  };

  const addComment = async () => {
    try {
      if (title) {
        setLoadingCreate(true)
        const newCommentData = {
          comments: title,
          project_id: id,
        };

        if (loadingCreate) return;
        const response = await fetch(`${SERVER_URL + "project-comments/insert"}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${tokenData?.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newCommentData)
        });

        if (response.ok) {
          // message.success('ເພີ່ມ comment ສຳເລັດ')
          fetchAssignmentData();
          setShowPopupAdd(false);
          setTitle("");
          setDetail("");
          setStatusId("");
        } else {
          // Handle errors or validation issues here
          console.log('Failed to add assignment.');
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoadingCreate(false)
    }
  };

  const decodeHTMLEntities = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  // console.log("project ======>", project)

  return (
    <React.Fragment>
      <div className='management-main'>
        <div className='management-header'
          style={{
            display: "flex",
            justifyContent: width < 700 ? "" : "space-between",
            alignItems: "center",
            flexDirection: width < 700 ? "column" : ""
          }}>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => {
              navigate('/')
              fetchProjectAll()
              fetchMyProject()
              fetchNotification()
            }}>
              <div style={{ cursor: "pointer" }}> <ArrowLeftOutlined style={{ paddingRight: 10 }} />{t("Back")}</div>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Project Detail")}&emsp;{loadingData ? <Spin size="middle" /> : ""}</Breadcrumb.Item>
          </Breadcrumb>
          &emsp;
          <Avatar.Group
            maxCount={7}
            size="large"
            maxStyle={{
              color: '#f56a00',
              backgroundColor: '#fde3cf',
            }}
          >
            {project?.Members?.slice(0, 10)?.sort((a, b) => {
              // Sort by create date in descending order (latest first)
              return new Date(b.cteated_at) - new Date(a.cteated_at);
            })?.map((e, index) => (

              <>
                <Tooltip key={index} title={e?.name} placement="top">
                  <Avatar
                    style={{
                      backgroundColor: '#87d068',
                    }}
                    // src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2"
                    icon={<UserOutlined />}
                  />
                </Tooltip>
              </>
            ))}
          </Avatar.Group>
          <div style={{
            marginTop: 15,
            display: width < 700 ? "" : "flex",
            alignItems: "center",
          }}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: width < 700 ? "20px" : "",
            }}>
              &emsp;
              <Button onClick={() => setShowViewAllMember(true)}>{t('All Members')} ({project?.Members?.length})  </Button>
              &emsp;
              <Button type="primary" onClick={() => setShowPopupAddMember(true)} style={{ background: "#00842e" }}>{t("Add Member")}</Button>
            </div>
          </div>
        </div>

        <div className='management-content'>
          <div className='mamagement-card' style={{ width: width < 700 ? "" : "100%" }}>
            <div
              className={width < 700 ? "" : 'management-card-header'}
              style={{
                display: width < 700 ? "flex" : "",
                justifyContent: width < 700 ? "flex-start" : "",
                width: width < 700 ? "100%" : "",
                paddingLeft: width < 700 ? 20 : "",
                fontWeight: 700,
                fontSize: 14
              }}
            >
              {t("Project Name")}
            </div>
            <div style={{
              paddingLeft: width < 700 ? 20 : "",
              paddingRight: width < 700 ? 20 : "",
              maxWidth: width < 700 ? "100%" : "100%",
              width: width < 700 ? "100%" : "100%",
            }}
            // onClick={() => setShowPopupDetail(true)}
            >
              <div
                // className='management-card-content'
                style={{
                  width: width < 700 ? "100%" : "100%",
                  maxWidth: width < 700 ? "100%" : "100%",
                  // background: "blue"
                  boxShadow: " 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
                  marginTop: 20,
                  background: "#FFFFFF",
                }}
              >
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  padding: "0px 20px 20px 20px",
                  width: "100%"
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "start",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    // background:"red"
                  }}>
                    <Divider orientation="left" orientationMargin="0" style={{ fontSize: 14, fontWeight: 600 }}>{t("Name of Project")}</Divider>

                    <div style={{ display: "flex", fontSize: 14 }}>
                      <RightOutlined />&emsp;
                      <div dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(project?.Project?.[0]?.p_name) }} />
                    </div>

                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />

                    <div style={{
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                      <div style={{ fontWeight: 600 }}>{t("percen")}</div>
                      <div style={{ width: "50%" }}>
                        {/* {Percen(project?.Project?.[0]?.precent)} */}
                        <Progress strokeColor={project?.Project?.[0]?.precent > 99 ? '#00802b' : "#ffec11"} percent={project?.Project?.[0]?.precent} size="large" />
                      </div>
                    </div>

                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />

                    <div style={{
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: 14
                    }}>
                      <div style={{ fontWeight: 600 }}>{t("deadline")}</div>
                      <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>{moment(project?.Project?.[0]?.deadline).format("DD-MM-YYYY")}</div>
                    </div>

                    <Divider />

                    <div style={{
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: 14
                    }}>
                      <div style={{ fontWeight: 600 }}>{t("deadline2")}</div>
                      <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                        {moment(project?.Project?.[0]?.extend).isValid() ? moment(project?.Project?.[0]?.extend).format("DD-MM-YYYY") : "-"}
                      </div>
                    </div>

                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />

                    <div style={{
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: 14
                    }}>
                      <div style={{ fontWeight: 600 }}>{t("status")}</div>
                      <div style={{ width: "50%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        {/* {ConvertStatus(project?.Project?.[0]?.s_name)} */}
                        {project?.Project?.[0]?.s_name === "Finished" ?
                          <Tag icon={<CheckCircleOutlined />} color="success" style={{ background: "green", color: "white", padding: 5 }}>
                            {t("Finished")}
                          </Tag> : project?.Project?.[0]?.s_name === "Pending" ? <Tag icon={<ClockCircleOutlined />} color="processing" style={{ padding: 5 }}>
                            {t("Pending")}
                          </Tag> : project?.Project?.[0]?.s_name === "Processing" ? <Tag icon={<SyncOutlined spin />} color="default" style={{ padding: 5 }}>
                            {t("Processing")}
                          </Tag> : project?.Project?.[0]?.s_name === "Error" ? <Tag icon={<CloseCircleOutlined />} color="error" style={{ background: "red", color: "white", padding: 5 }}>
                            {t("ErrorS")}
                          </Tag> : ""}
                      </div>
                    </div>
                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                    <div style={{
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: 14
                    }}>
                      <div style={{ fontWeight: 600 }}> {project?.Project?.[0]?.create_project_by}</div>
                      <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                        {moment(project?.Project?.[0]?.update_project_at).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div className='mamagement-card' style={{ paddingLeft: width < 700 ? "" : 20 }}>
            <div
              className={width < 700 ? "" : 'management-card-header'}
              style={{
                display: width < 700 ? "flex" : "",
                justifyContent: width < 700 ? "flex-start" : "",
                width: width < 700 ? "100%" : "",
                paddingLeft: width < 700 ? 20 : "",
                marginTop: width < 700 ? 20 : "",
                fontWeight: 700,
                fontSize: 14
              }}
            >
              {t("Processing or Detail")}
            </div>

            <Select
              showArrow={false}
              labelInValue
              transitionName={false}
              defaultValue={{ value: selectStatusProcessing, label: selectStatusProcessing }}
              style={{
                width: "100%",
                marginTop: 20,
                paddingLeft: width < 700 ? 20 : "",
                paddingRight: width < 700 ? 20 : ""
              }}
              onChange={(value) => setSelectStatusProcessing(value.label)}
            >
              <option onClick={() => setSelectStatusProcessing("All Status")}>{t("All")}</option>
              {options}
            </Select>

            <div style={{
              display: width < 700 ? "flex" : "",
              justifyContent: width < 700 ? "flex-start" : "",
              overflowX: width < 700 ? "auto" : "",
              width: width < 700 ? "100%" : "",
              paddingLeft: width < 700 ? 20 : "",
              paddingRight: width < 700 ? 20 : ""
            }}>
              {filterProcessing?.map((processing, index) => (
                !index === 0 ? "" :
                  <>
                    <div
                      key={index}
                      style={{
                        background: processing?.total_notifications === "0" ? "" : "#EEF0F6",
                      }}
                      className='management-card-content' onClick={() => {
                        setShowViewManagement(true)
                        setPopupState('processing')
                        setDetailData(processing)
                      }}>
                      <div style={{ position: "absolute", top: -10, left: -10 }}>
                        <Button
                          type="primary"
                          shape="circle"
                          style={{
                            background: "red",
                            display: processing?.total_notifications === "0" ? "none" : ""
                          }}>
                          {processing?.total_notifications}
                        </Button>
                      </div>
                      <div className='management-card-content-kebap-menu' onClick={(e) => e.stopPropagation()}>

                        <Popconfirm
                          key={index}
                          cancelButtonProps={() => setOpenCartDetail(prevState => {
                            const newState = [...prevState];
                            newState[index] = false;
                            return newState;
                          })}
                          onVisibleChange={(visible) => setOpenCartDetail(prevState => {
                            const newState = [...prevState];
                            newState[index] = visible;
                            return newState;
                          })}
                          open={openCartDetail[index]}
                          placement='bottom'
                          icon={''}
                          showCancel={false}
                          okButtonProps={{ style: { display: 'none' } }}
                          transitionName={false}
                          description={
                            <div style={{ display: "flex", flexDirection: "column", padding: 20, }}>
                              <Button
                                onClick={() => {
                                  setShowPopupAdd(true);
                                  setPopupState('processing')
                                  setSelectEditData({ editMode: true, ...processing })
                                  setOpenCartDetail(prevState => {
                                    const newState = [...prevState];
                                    newState[index] = false;
                                    return newState;
                                  });
                                }}
                                type="primary" icon={<BiEdit style={{ fontSize: 20 }} />}>
                                {t("edit")}
                              </Button>
                              <div style={{ width: '100%', marginTop: 10 }}>
                                <Button
                                  onClick={() => {
                                    handleShowDelete(processing?.meeting_uid)
                                    setOpenCartDetail(prevState => {
                                      const newState = [...prevState];
                                      newState[index] = false;
                                      return newState;
                                    });
                                  }}
                                  type=""
                                  style={{ background: "#FFA500", color: "white" }}
                                  icon={<RiDeleteBin6Line
                                    style={{ fontSize: 20 }} />}>
                                  {t("delete")}
                                </Button>
                              </div>
                            </div>
                          }
                        >
                          <div onClick={(e) => e.preventDefault()} style={{ paddingRight: 20 }}>
                            <EllipsisOutlined style={{ fontSize: 30 }} />
                          </div>
                        </Popconfirm>
                      </div>
                      <div style={{
                        fontSize: 14,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        fontSize: 14,
                        paddingLeft: 25,
                        paddingRight: 25
                      }}>
                        <div style={{ fontWeight: 600 }}>{t("status")}</div>
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                          {/* {ConvertStatus(processing?.status)} */}
                          {processing?.status === "Finished" ?
                            <Tag icon={<CheckCircleOutlined />} color="" style={{ background: "green", color: "white", padding: 5 }}>
                              {t("Finished")}
                            </Tag> : processing?.status === "Pending" ? <Tag icon={<ClockCircleOutlined />} color="processing" style={{ padding: 5 }}>
                              {t("Pending")}
                            </Tag> : processing?.status === "Processing" ? <Tag icon={<SyncOutlined spin />} color="default" style={{ padding: 5 }}>
                              {t("Processing")}
                            </Tag> : processing?.status === "Error" ? <Tag icon={<CloseCircleOutlined />} color="error" style={{ padding: 5, background: "red", color: "white" }}>
                              {t("ErrorS")}
                            </Tag> : ""}
                        </div>
                      </div>
                      <Divider orientation="left" style={{ fontSize: 14, fontWeight: 600 }}>{t("Title")}</Divider>
                      <div style={{ width: "100%", paddingLeft: 20, paddingRight: 20 }}>
                        <Paragraph style={{ fontFamily: 'Noto Sans Lao, sans-serif' }}>
                          <div style={{ display: "flex" }}>
                            <RightOutlined />&emsp;
                            <div dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(processing?.title) }} />
                          </div>
                        </Paragraph>
                      </div>
                      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                      <div style={{
                        fontSize: 14,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        fontSize: 11,
                        paddingLeft: 25,
                        paddingRight: 25
                      }}>
                        <div >{processing?.update_by === "" ? "-" : processing?.update_by}</div>
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                          {moment(processing?.updated_at).format("DD-MM-YYYY")}
                        </div>
                      </div>
                      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          paddingTop: 5,
                          paddingLeft: 20,
                          cursor: "default"
                        }} onClick={(e) => e.stopPropagation()}>
                        <div>
                          <Tooltip title={t("Add Member")} onClick={(e) => e.stopPropagation()}>
                            <Button
                              onClick={() => {
                                setMeetingId(processing)
                                setShowAddMemberMeeting(true)
                              }}
                              type="primary"
                              shape="circle"
                              style={{ background: "#00842e" }}
                              icon={<PlusOutlined />} />
                          </Tooltip>
                        </div>&emsp;
                        {memberMeetingHistory?.results?.filter(name => name?.meeting_id === processing?.meeting_uid).length <= 0 ?
                          <div style={{ width: "70%" }}>{t("Add Member")}</div>
                          : ""}
                        <Avatar.Group
                          maxCount={2}
                          size="large"
                          maxStyle={{
                            color: '#f56a00',
                            backgroundColor: '#fde3cf',
                          }}
                        >
                          {memberMeetingHistory?.results?.filter(name => name?.meeting_id === processing?.meeting_uid)?.map((e, index) => (
                            <>
                              <Tooltip title={e?.name} placement="top">
                                <Avatar
                                  style={{
                                    backgroundColor: '#87d068',
                                  }}
                                  icon={<UserOutlined />}
                                />
                              </Tooltip>

                            </>
                          ))}
                        </Avatar.Group>

                        &emsp;
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingRight: 20 }} >
                          <Button style={{ fontSize: 15 }} onClick={() => {
                            setMeetingId(processing)
                            setShowViewAllMemberMeeting(true)
                          }}>
                            {t("All")}
                          </Button>
                        </div>
                      </div>
                    </div >
                    {width < 700 ? <div style={{ marginLeft: 20 }} ></div> : ""}
                  </>
              ))}

              <div className='management-card-content-add' onClick={() => {
                setShowPopupAdd(true)
                setPopupState('processing')
              }}>
                <div> <PlusCircleOutlined style={{ fontSize: 30 }} className='management-icons-add' /></div>
                <div style={{ fontSize: 16, paddingTop: 10 }}>{t("add")}</div>
              </div>
            </div>
          </div>

          <div className='mamagement-card' style={{ paddingLeft: width < 700 ? "" : 20 }}>
            <div
              className={width < 700 ? "" : 'management-card-header'}
              style={{
                display: width < 700 ? "flex" : "",
                justifyContent: width < 700 ? "flex-start" : "",
                width: width < 700 ? "100%" : "",
                paddingLeft: width < 700 ? 20 : "",
                marginTop: width < 700 ? 20 : "",
                fontWeight: 700
              }}
            >
              {t("Assignment / task")}
            </div>

            <Select
              labelInValue
              showArrow={false}
              transitionName={false}
              defaultValue={{ value: selectStatusAssignment, label: selectStatusAssignment }}
              style={{
                width: "100%",
                marginTop: 20,
                paddingLeft: width < 700 ? 20 : "",
                paddingRight: width < 700 ? 20 : ""
              }}
              onChange={(value) => setSelectStatusAssignment(value.label)}
            >
              <option onClick={() => setSelectStatusAssignment("All Status")}>{t("All")}</option>
              {options}
            </Select>

            <div style={{
              display: width < 700 ? "flex" : "",
              justifyContent: width < 700 ? "flex-start" : "",
              overflowX: width < 700 ? "auto" : "",
              width: width < 700 ? "100%" : "",
              paddingLeft: width < 700 ? 20 : "",
              paddingRight: width < 700 ? 20 : ""
            }}>
              {filterAssignment?.map((assignment, index) => (
                !index === 0 ? "" :
                  <>
                    <div
                      key={index}
                      className='management-card-content'
                      style={{
                        background: assignment?.total_notifications === "0" ? "" : "#EEF0F6",
                      }}
                      onClick={() => {
                        setShowViewManagement(true)
                        setDetailData(assignment)
                        setPopupState('assignment')
                      }} >
                      <div style={{ position: "absolute", top: -10, left: -10 }}>
                        <Button
                          type="primary"
                          shape="circle"
                          style={{
                            background: "red",
                            display: assignment?.total_notifications === "0" ? "none" : ""
                          }}>
                          {assignment?.total_notifications}
                        </Button>
                      </div>
                      <div className='management-card-content-kebap-menu' onClick={(e) => e.stopPropagation()}>
                        <Popconfirm
                          key={index}
                          cancelButtonProps={() => setOpenAssignDetail(prevState => {
                            const newState = [...prevState];
                            newState[index] = false;
                            return newState;
                          })}
                          onVisibleChange={(visible) => setOpenAssignDetail(prevState => {
                            const newState = [...prevState];
                            newState[index] = visible;
                            return newState;
                          })}
                          open={openAssignDetail[index]}
                          placement='bottom'
                          icon={''}
                          showCancel={false}
                          okButtonProps={{ style: { display: 'none' } }}
                          transitionName={false}
                          description={
                            <div style={{ display: "flex", flexDirection: "column", padding: 20, }}>
                              <Button
                                onClick={() => {
                                  setShowPopupAdd(true);
                                  setPopupState('assignment')
                                  setSelectEditData({ editMode: true, ...assignment });
                                  setOpenAssignDetail(prevState => {
                                    const newState = [...prevState];
                                    newState[index] = false;
                                    return newState;
                                  })
                                }}
                                type="primary" icon={<BiEdit style={{ fontSize: 20 }} />}>
                                {t("edit")}
                              </Button>
                              <div style={{ width: '100%', marginTop: 10 }}>
                                <Button
                                  onClick={() => {
                                    handleShowDeleteAssignMent(assignment?.as_id)
                                    setOpenAssignDetail(prevState => {
                                      const newState = [...prevState];
                                      newState[index] = false;
                                      return newState;
                                    })
                                  }}
                                  type=""
                                  style={{ background: "#FFA500", color: "white" }}
                                  icon={<RiDeleteBin6Line
                                    style={{ fontSize: 20 }} />}>
                                  {t("delete")}
                                </Button>
                              </div>
                            </div>
                          }
                        >
                          <div onClick={(e) => e.preventDefault()} style={{ paddingRight: 20 }}>
                            <EllipsisOutlined style={{ fontSize: 30 }} />
                          </div>
                        </Popconfirm>
                      </div>

                      <div style={{
                        fontSize: 14,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        fontSize: 14,
                        paddingLeft: 25,
                        paddingRight: 25
                      }}>
                        <div style={{ fontWeight: 600 }}>{t("status")}</div>
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                          {assignment?.status === "Finished" ?
                            <Tag icon={<CheckCircleOutlined />} color="" style={{ background: "green", color: "white", padding: 5 }}>
                              {t("Finished")}
                            </Tag> : assignment?.status === "Pending" ? <Tag icon={<ClockCircleOutlined />} color="processing" style={{ padding: 5 }}>
                              {t("Pending")}
                            </Tag> : assignment?.status === "Processing" ? <Tag icon={<SyncOutlined spin />} color="default" style={{ padding: 5 }}>
                              {t("Processing")}
                            </Tag> : assignment?.status === "Error" ? <Tag icon={<CloseCircleOutlined />} color="error" style={{ background: "red", color: "white", padding: 5 }}>
                              {t("ErrorS")}
                            </Tag> : ""}
                        </div>
                      </div>
                      <Divider orientation="left" style={{ fontSize: 14, fontWeight: 600 }}>{t("Title")}</Divider>
                      <div style={{ width: "100%", paddingLeft: 20, paddingRight: 20 }}>
                        <Paragraph style={{ fontFamily: 'Noto Sans Lao, sans-serif' }}>
                          <div style={{ display: "flex" }}>
                            <RightOutlined />&emsp;
                            <div dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(assignment?.title) }} />
                          </div>
                        </Paragraph>
                      </div>
                      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                      <div style={{
                        fontSize: 14,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        fontSize: 11,
                        paddingLeft: 25,
                        paddingRight: 25
                      }}>
                        <div>{assignment?.update_by === "" ? "-" : assignment?.update_by}</div>
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                          {moment(assignment?.updated_at).format("DD-MM-YYYY")}
                        </div>
                      </div>
                      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          paddingTop: 5,
                          paddingLeft: 20,
                          cursor: "default"
                        }} onClick={(e) => e.stopPropagation()}>
                        <div>
                          <Tooltip title={t("Add Member")} onClick={(e) => e.stopPropagation()}>
                            <Button
                              onClick={() => {
                                setAssignmentId(assignment)
                                setShowAssignmentMember(true)
                              }}
                              type="primary"
                              shape="circle"
                              style={{ background: "#00842e" }}
                              icon={<PlusOutlined />} />
                          </Tooltip>
                        </div>&emsp;
                        {memberAssign?.results?.filter(name => name?.ass_id === assignment?.as_id).length <= 0 ?
                          <div style={{ width: "70%" }}>{t("Add Member")}</div>
                          : ""}
                        <Avatar.Group
                          maxCount={2}
                          size="large"
                          maxStyle={{
                            color: '#f56a00',
                            backgroundColor: '#fde3cf',
                          }}
                        >
                          {memberAssign?.results?.filter(name => name?.ass_id === assignment?.as_id)?.map((e, index) => (
                            <>
                              <Tooltip title={e?.name} placement="top">
                                <Avatar
                                  style={{
                                    backgroundColor: '#87d068',
                                  }}
                                  icon={<UserOutlined />}
                                />
                              </Tooltip>

                            </>
                          ))}
                        </Avatar.Group>

                        &emsp;
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingRight: 20 }} >
                          <Button style={{ fontSize: 15 }} onClick={() => {
                            setAssignmentId(assignment)
                            setShowAllMemberAssign(true)
                          }}>
                            {t("All")}
                          </Button>
                        </div>
                      </div>
                    </div>

                    {width < 700 ? <div style={{ marginLeft: 20 }}></div> : ""}
                  </>
              ))}

              <div className='management-card-content-add' onClick={() => {
                setShowPopupAdd(true)
                setPopupState('assignment')
              }}>
                <div> <PlusCircleOutlined style={{ fontSize: 30 }} className='management-icons-add' /></div>
                <div style={{ fontSize: 16, paddingTop: 10 }}>{t("add")}</div>
              </div>
            </div>
          </div>

          <div className='mamagement-card' style={{ paddingLeft: width < 700 ? "" : 20 }}>
            <div
              className={width < 700 ? "" : 'management-card-header'}
              style={{
                display: width < 700 ? "flex" : "",
                justifyContent: width < 700 ? "flex-start" : "",
                width: width < 700 ? "100%" : "",
                paddingLeft: width < 700 ? 20 : "",
                marginTop: width < 700 ? 20 : "",
                fontWeight: 700
              }}
            >
              {t("Comment / Chat")}
            </div>
            <div style={{
              display: width < 700 ? "flex" : "",
              justifyContent: width < 700 ? "flex-start" : "",
              fontWeight: 700,
              paddingLeft: width < 700 ? 20 : "",
              paddingRight: width < 700 ? 20 : "",
              width: width < 700 ? "100%" : "",
              maxWidth: width < 700 ? "100%" : "",
            }}>

              <div className='management-card-content'
                style={{
                  width: width < 700 ? "100%" : "100%",
                  maxWidth: width < 700 ? "100%" : "100%",
                  boxShadow: " 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
                  marginTop: 20,
                  background: "#FFF",
                  height: width < 700 ? "70vh" : "60vh", position: "relative", cursor: "default"
                }}
              >
                {comment?.length <= 0 ? <div><AnimationEmpty /></div> : <List>
                  <VirtualList
                    data={comment}
                    height={ContainerHeight}
                    style={{ width: width < 700 ? 300 : 260, paddingLeft: 5, paddingRight: 5 }}
                  >
                    {(item) => (
                      <List.Item key={item}>
                        <List.Item.Meta
                          avatar={<Avatar style={{
                            backgroundColor: '#87d068',
                          }}
                            icon={<UserOutlined />} />}
                          title={<div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ fontSize: 12, fontWeight: 600 }}>{item?.create_by}</div>
                            <div style={{ color: "#757f7e", fontSize: 10 }}>
                              <FieldTimeOutlined />&ensp;{moment(item?.created_at).format("DD-MM-YYYY HH:mm ")}
                            </div>

                          </div>}
                          description={
                            <div style={{
                              background: "#e3f9f7",
                              padding: 10,
                              borderRadius: 20,
                              fontSize: 12
                            }}>

                              <Paragraph style={{ fontSize: 12, fontFamily: 'Noto Sans Lao, sans-serif' }}>
                                {item?.comments}
                              </Paragraph>
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  </VirtualList>
                </List>}

                <div style={{ position: "absolute", top: 10, right: 20, display: "flex", alignItems: "center" }}>
                  <Button
                    type="primary"
                    shape="circle"
                    size='large'
                    style={{ boxShadow: '1px 2px 2px #F4AAB9', background: "#00842e" }}
                    onClick={() => setShowPopupChat(true)}
                    icon={<MessageOutlined style={{ fontSize: 20 }} />} />
                </div>
              </div>
            </div>
          </div>

        </div>
        {isVisible && (
          <FloatButton
            icon={<UpOutlined style={{ color: "#FFFFFF" }} />}
            type="success"
            style={{
              right: 94,
              backgroundColor: '#FF0000',
            }}
            onClick={scrollToTop} />
        )}

      </div >

      < MemberList
        show={showPopupAddmember}
        handleClose={() => setShowPopupAddMember(false)
        }
        fetchAssignmentData={fetchAssignmentData}
        id={id}
        project={project}
        fetchMemberProject={fetchMemberProject}
      />

      <AddmemberAssignment
        show={showAssignmentMember}
        handleclose={() => setShowAssignmentMember(false)}
        assignmentId={assignmentId}
        setAssignmentId={setAssignmentId}
        fetchAssignMember={fetchAssignMember}
        id={id}
        members={members}
        memberAssignHistory={memberAssignHistory}
        fetchMemberHistory={fetchMemberHistory}
      />

      <AddmemberMeeting
        show={showAddMemberMeeting}
        handleclose={() => setShowAddMemberMeeting(false)}
        members={members}
        meetingId={meetingId}
        memberMeetingHistory={memberMeetingHistory}
        fetchMeetingMember={fetchMeetingMember}
        fetchMemberHistoryMeeting={fetchMemberHistoryMeeting}
      />

      <ChatManagement
        show={showPopupChat}
        handleClose={() => setShowPopupChat(false)}
        comment={comment}
        addComment={addComment}
        isLoading={isLoading}
        title={title}
        setTitle={setTitle}
        fetchAssignmentData={fetchAssignmentData}
        DeleteComment={handleShowDeleteComment}
        loadingCreate={loadingCreate}
        project={project}
      />

      <ViewAllMember
        show={showViewAllMember}
        project={project}
        handleClose={() => setShowViewAllMember(false)}
        fetchAssignmentData={fetchAssignmentData}
        id={id}
        fetchMemberProject={fetchMemberProject}
      />

      <ViewDetailManagement
        show={showViewManagement}
        handleClose={() => setShowViewManagement(false)}
        DetailData={DetailData}
        setDetailData={setDetailData}
        fetchAssignmentData={fetchAssignmentData}
        popupState={popupState}
        assignmentFile={assignmentFile}
      // AssignmentFilter={AssignmentFilter}
      />

      <ModalAddManagement
        show={showPopupaAdd}
        handleclose={() => setShowPopupAdd(false)}
        isLoading={isLoading}
        addProcessing={addProcessing}
        addAssignment={addAssignment}
        addComment={addComment}
        UpdateProcessing={UpdateProcessing}
        UpdateAssignment={UpdateAssignment}
        popupState={popupState}
        setPopupState={setPopupState}
        title={title}
        setTitle={setTitle}
        detail={detail}
        setDetail={setDetail}
        statusId={statusId}
        setStatusId={setStatusId}
        file={file}
        setFile={setFile}
        loadingCreate={loadingCreate}
        selectEditData={selectEditData}
        setSelectEditData={setSelectEditData}
        filePreview={filePreview}
        setFilePreview={setFilePreview}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        members={members}
        currentStartDate={currentStartDate}
        setCurrentStartDate={setCurrentStartDate}
        currentEndDate={currentEndDate}
        setCurrentEndDate={setCurrentEndDate}
        currentStartTime={currentStartTime}
        setCurrentStartTime={setCurrentStartTime}
        currentEndTime={currentEndTime}
        setCurrentEndTime={setCurrentEndTime}
        memberAssignHistory={memberAssignHistory}
        selected_employee={selected_employee}
        setSelected_Employee={setSelected_Employee}
        employees_member={employees_member}
        setEmployeesMember={setEmployeesMember}
        project={project}
      />

      <ViewAllMemberAssignment
        show={showAllMemberAssign}
        handleClose={() => setShowAllMemberAssign(false)}
        assignmentId={assignmentId}
        setAssignmentId={setAssignmentId}
        fetchAssignmentData={fetchAssignmentData}
        fetchAssignMember={fetchAssignMember}
        memberAssign={memberAssign}
        fetchMemberProject={fetchMemberProject}
        fetchMemberHistory={fetchMemberHistory}
        project={project}
      />

      <ViewAllMemberMeeting
        show={showViewAllMemberMeeting}
        handleClose={() => setShowViewAllMemberMeeting(false)}
        memberMeeting={memberMeeting}
        meetingId={meetingId}
        fetchMemberHistoryMeeting={fetchMemberHistoryMeeting}
        fetchMeetingMember={fetchMeetingMember}
        project={project}
        fetchMeeting={fetchMeeting}
        fetchMemberHistory={fetchMemberHistory}
      />
      
      <ConfirmDelete
        show={showPopupDelete}
        handleClose={() => setShowPopupDelete(false)}
      />

    </React.Fragment >
  )
}
