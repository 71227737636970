import React, { useState } from 'react';
import { UsergroupAddOutlined, CloseOutlined, CheckOutlined, UserOutlined } from '@ant-design/icons';
import { Modal, Button, Input, notification, Avatar, Select, Tooltip, Radio, Checkbox, Flex, DatePicker } from 'antd';
import { SketchPicker } from 'react-color'; // Import the color picker
import AddmemberCalendar from './AddmemberCalendar';
import { onConfirmButton, tokenData } from '../../const';
import axios from 'axios';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap'
import { SERVER_URL } from '../../api';
import { useOutMeeting } from '../../contexts/OutMeetingContext';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useWindowDimensions from '../../helper/useWindowDimensions';
import utc from 'dayjs/plugin/utc';
import { errorSwal } from '../../helper/sweetalert';
import ViewMemberEvent from './ViewMemberEvent';
import { renderToString } from 'react-dom/server';
const { TextArea } = Input;
const { Option } = Select;
dayjs.extend(utc);

export default function AddEvent({ isModalOpen, setSelectedEvent, handleCancel }) {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const { fetchOutMeeting, fetchOutMeetingPublic, employees_member, setEmployeesMember } = useOutMeeting()
    const [showAddmember, setShowddMember] = useState(false)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [allDay, setAllDay] = useState(false)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [color, setColor] = useState('#000000')
    const [count, setCount] = useState(1)
    const [freq, setFreq] = useState('WEEKLY')
    const [interval, setInterval] = useState(1)
    const [location, setLocation] = useState('')
    const [video_conference, setVideo_conference] = useState('')
    const [status, setStatus] = useState('private')
    const [loading, setLoading] = useState(false)
    const [repeat, setRepeat] = useState("NOREPEAT")
    const [selectedItems, setSelectedItems] = useState([])
    const [showAllMemberEvent, setShowAllMemberEvent] = useState(false)
    const [showPopupDelete, setShowPopupDelete] = useState(false)
    const [Delete, setDelete] = useState("");

    const confirmButtonContent = renderToString(<div>{t("delete")}</div>);
    const cancelButtonContent = renderToString(<div>{t("cancel")}</div>);

    const handleTitleChange = (e) => setTitle(e.target.value)
    const handleDescriptionChange = (e) => setDescription(e.target.value)

    const handleOk = async (values) => {
        setLoading(true)
        try {

            if (loading) return

            const apiUrl = `${SERVER_URL + "out-meeting/insert"}`;

            await axios.post(apiUrl, values, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                },
            })
                .then((response) => {

                    notification.success({
                        message: t('Success'),
                        description: t('save success'),
                        duration: 2,
                    });

                    setFreq("WEEKLY")
                    setRepeat("NOREPEAT")
                    setSelectedItems([])
                    setEndDate('')
                    setStartDate('')
                    setEmployeesMember([])
                    fetchOutMeeting()
                    fetchOutMeetingPublic()
                    handleCancel()
                    setTitle('')
                    setLocation('')
                    setVideo_conference('')
                    setCount(1)
                    setDescription('')
                    setLoading(false)
                })
                .catch((error) => {
                    console.log('Error: ', error)
                    setLoading(false)
                });

        } catch (error) {
            console.log("Error: ", error)
            setLoading(false)
        }

    };

    const onClose = () => {
        handleCancel()
        setSelectedEvent()
        setTitle()
        setDescription()
        setStartDate('')
        setEndDate('')
        setFreq("WEEKLY")
        setRepeat("NOREPEAT")
        setSelectedItems([])
    }

    const WeekDay = [
        { name: "MONDAY", value: "MO" },
        { name: "TUESDAY", value: "TU" },
        { name: "WEDNESDAY", value: "WE" },
        { name: "THURSDAY", value: "TH" },
        { name: "FRIDAY", value: "FR" },
        { name: "SATURDAY", value: "SA" },
        { name: "SUNDAY", value: "SU" },
    ]

    const handleCheckDepartMent = (itemValue) => {
        const currentIndex = selectedItems?.indexOf(itemValue);
        const newChecked = [...selectedItems];

        if (currentIndex === -1) {
            newChecked.push(itemValue);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItems(newChecked);
    };

    const handleChangeDepartMent = (selectedItems) => {
        setSelectedItems(selectedItems);
    };

    function onChangeDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);
        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD HH:mm:ss")
        // Set the selected date in the new time zone
        setStartDate(bangkokTime);
    }

    function onChangeEndDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);
        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD HH:mm:ss")
        // Set the selected date in the new time zone
        setEndDate(bangkokTime);
    }

    const onDeleteMember = (indexToDelete) => {
        setEmployeesMember(prevArray => {
            // Filter out the item at the specified index
            return prevArray.filter((item, index) => index !== indexToDelete);
        });
    };

    const handleShowDelete = (id, name) => {
        setDelete({ name, id });
        onConfirmButton(t('Do you want to Delete or not?'), confirmButtonContent, cancelButtonContent, () => onDeleteMember(id))
    };

    return (
        <Modal
            width={width < 700 ? "100%" : '60%'}
            centered
            title={t("Add Meeting")}
            visible={isModalOpen}
            transitionName={false}
            onCancel={onClose}
            footer={[]}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    title: title || "",
                    description: description || "",
                    fromdate: startDate || "",
                    todate: endDate || "",
                    backgroundColor: color || "",
                    allDay: "1",
                    location: location || "",
                    is_repeat: repeat || "",
                    video_conference: video_conference || "",
                    freq: freq || "",
                    interval: "1",
                    count: count || "",
                    wkst: "",
                    tzid: "",
                    bysetpos: "",
                    bymonth: "",
                    bymonthday: "",
                    byyearday: "",
                    byweekno: "",
                    byweekday: selectedItems || [],
                    byhour: "",
                    byminute: "",
                    bysecond: "",
                    byeaster: "",
                    status: status || "",
                    guests: employees_member || []
                }}
                validate={(values) => {
                    const errors = {};
                    if (!title || title.trim() === '') {
                        errors.title = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!startDate) {
                        errors.startDate = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!endDate) {
                        errors.endDate = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!freq) {
                        errors.freq = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!count) {
                        errors.count = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!interval) {
                        errors.interval = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!location || location.trim() === '') {
                        errors.location = 'ກະລຸນາປ້ອນຊື່';
                    }

                    if (new Date(endDate) <= new Date(startDate)) {
                        errors.endDate = 'ວັນທີ່ສິ້ນສຸດຕໍ່ວັນທີ່ໝົດກໍ່ຖືກຕ້ອງ';
                        errorSwal(t('End date must be more than Start Date'))
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    // if (status === "public" && employees_member?.length <= 0) {
                    //     errorSwal(t('Please select an employee'))
                    // } else {
                    handleOk(values)
                    // }

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (

                    <>
                        <div style={{
                            paddingTop: 20,
                            paddingBottom: 20,
                            display: "flex",
                            flexDirection: width < 700 ? "column" : ""
                        }}>
                            <div style={{ width: width < 700 ? "100%" : "60%" }}>

                                <Form.Group className="mb-3">
                                    <Form.Label style={{ fontSize: 14, color: "#121212BF", fontWeight: 400 }}>{t("Title")}</Form.Label>
                                    <Form.Control
                                        name='title'
                                        type="text"
                                        onChange={handleTitleChange}
                                        value={title}
                                        isInvalid={errors?.title}
                                        style={{ fontSize: 14, height: 40 }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("Description")}</Form.Label>
                                    <Form.Control
                                        name='description'
                                        type="text"
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        style={{ fontSize: 14, height: 40 }}
                                    />
                                </Form.Group>

                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "50%" }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("Location")}</Form.Label>
                                            <Form.Control
                                                name='location'
                                                type="text"
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                                style={{ fontSize: 14, height: 40 }}
                                                isInvalid={errors?.location}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div style={{ width: 10 }}></div>

                                    <div style={{ width: "50%" }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("Video Conference")}</Form.Label>
                                            <Form.Control
                                                name='video_conference'
                                                type="text"
                                                value={video_conference}
                                                onChange={(e) => setVideo_conference(e.target.value)}
                                                style={{ fontSize: 14, height: 40 }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div style={{ marginTop: 20 }}>

                                    <Radio.Group onChange={(e) => setRepeat(e.target.value)} value={repeat}>
                                        <Radio value={'REPEAT'}>{t("repeat")}</Radio>
                                        <Radio value={"NOREPEAT"}>{t("Don't repeat.")}</Radio>
                                    </Radio.Group>

                                </div>

                                <div style={{ width: "100%" }}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>{t("Select Day Repeat")}</Form.Label>
                                        <Select
                                            disabled={repeat === "NOREPEAT" ? true : false}
                                            mode="multiple"
                                            defaultValue={'MO'}
                                            placeholder={'Select Day'}
                                            style={{ width: "100%", minHeight: 40 }}
                                            value={selectedItems}
                                            onChange={handleChangeDepartMent}
                                        >

                                            {WeekDay?.map((item, index) => (
                                                <Option key={item?.value} value={item?.value}>
                                                    <Checkbox
                                                        checked={selectedItems?.includes(item?.value)}
                                                        onChange={() => handleCheckDepartMent(item?.value)}
                                                    >
                                                        {item?.name}
                                                    </Checkbox>
                                                </Option>
                                            ))}

                                        </Select>
                                    </Form.Group>
                                </div>

                                <div style={{ display: "flex" }}>

                                    <div style={{ width: "50%" }}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>{t("Repeat type")}</Form.Label>
                                            <Select
                                                disabled={repeat === "NOREPEAT" ? true : false}
                                                defaultValue="WEEKLY"
                                                style={{
                                                    width: '100%',
                                                    height: 40,
                                                    border: errors?.freq ? "1px solid red" : "",
                                                }}
                                                onChange={(value) => setFreq(value)}
                                                value={freq}
                                                options={[
                                                    {
                                                        value: 'YEARLY',
                                                        label: 'YEARLY',
                                                    },
                                                    {
                                                        value: 'MONTHLY',
                                                        label: 'MONTHLY',
                                                    },
                                                    {
                                                        value: 'WEEKLY',
                                                        label: 'WEEKLY',
                                                    },
                                                    {
                                                        value: 'DAILY',
                                                        label: 'DAILY',
                                                    },
                                                    {
                                                        value: 'HOURLY',
                                                        label: 'HOURLY',
                                                    },
                                                    {
                                                        value: 'MINUTELY',
                                                        label: 'MINUTELY',
                                                    },
                                                    {
                                                        value: 'SECONDLY',
                                                        label: 'SECONDLY',
                                                    },
                                                ]}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div style={{ width: 10 }}></div>

                                    <div style={{ width: "50%" }}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>{t("Guest")}</Form.Label>
                                            <Select
                                                defaultValue="private"
                                                style={{
                                                    width: '100%',
                                                    height: 40
                                                }}
                                                onChange={(value) => setStatus(value)}
                                                value={status}
                                                options={[
                                                    {
                                                        value: 'public',
                                                        label: 'public',
                                                    },
                                                    {
                                                        value: 'private',
                                                        label: 'private',
                                                    },
                                                ]}
                                            />
                                        </Form.Group>
                                    </div>

                                </div>

                                <div style={{ display: "flex" }}>
                                    {/* <div style={{ width: "50%" }}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>{t("Repeat number")}</Form.Label>
                                            <Form.Control
                                                disabled={repeat === "NOREPEAT" ? true : false}
                                                min={1}
                                                type='number'
                                                defaultValue={1}
                                                value={interval}
                                                onChange={(e) => setInterval(e?.target.value)}
                                                isInvalid={errors?.interval}
                                                style={{ height: 40 }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div style={{ width: 10 }}></div> */}

                                    <div style={{ width: "100%" }}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>{t("Count")}</Form.Label>
                                            <Form.Control
                                                disabled={repeat === "NOREPEAT" ? true : false}
                                                min={1}
                                                type='number'
                                                defaultValue={1}
                                                value={count}
                                                // onChange={(e) => setCount(e?.target.value)}
                                                onChange={(e) => {
                                                    // Ensure the value is at least 1
                                                    const newValue = Math.max(1, parseInt(e.target.value));

                                                    // Update the state with the new value
                                                    setCount(newValue);
                                                }}
                                                isInvalid={errors?.count}
                                                style={{ height: 40 }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div style={{ display: "flex", width: "100%" }}>

                                    <div style={{ width: "50%" }}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>{t("startDate")}</Form.Label>
                                            {/* <Form.Control
                                                type="datetime-local"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                style={{ width: '100%', height: 40 }}
                                                isInvalid={errors?.startDate}
                                            /> */}
                                            <DatePicker
                                                value={startDate}
                                                onChange={onChangeDate}
                                                placeholder={t("Select Date")}
                                                showTime
                                                format="YYYY-MM-DD HH:mm:ss"
                                                valueFormat="YYYY-MM-DD HH:mm:ss"
                                                allowClear={false} // Disable the clear icon
                                                style={{
                                                    fontSize: 14, borderRadius: 8, width: "100%", height: 40,
                                                    border: errors?.startDate ? "1px solid red" : ""
                                                }}
                                                needConfirm={false} />
                                        </Form.Group>
                                    </div>

                                    <div style={{ width: 20 }}></div>

                                    {!allDay &&
                                        <div style={{ width: "50%" }}>
                                            <Form.Group className="mt-3">
                                                <Form.Label>{t("endDate")}</Form.Label>
                                                {/* <Form.Control
                                                    type="datetime-local"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    style={{ width: '100%', height: 40 }}
                                                    isInvalid={errors?.endDate}
                                                /> */}
                                                <DatePicker
                                                    value={endDate}
                                                    onChange={onChangeEndDate}
                                                    placeholder={t('Select Date')}
                                                    showTime
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                                    allowClear={false} // Disable the clear icon
                                                    style={{
                                                        fontSize: 14, borderRadius: 8, width: "100%", height: 40,
                                                        border: errors?.endDate ? "1px solid red" : ""
                                                    }}
                                                    needConfirm={false} />
                                            </Form.Group>
                                        </div>
                                    }

                                </div>

                                <div style={{ marginTop: 20, display: status === "private" ? "none" : "flex", justifyContent: "space-between" }}>
                                    <Button
                                        onClick={() => setShowddMember(true)}
                                        icon={<UsergroupAddOutlined
                                            style={{ fontSize: 20 }} />}
                                        type="primary"
                                        style={{ height: 40 }}>{t("Add Member")}</Button>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Avatar.Group
                                            maxCount={5}
                                            size="large"
                                            // maxCount={employees_member?.length}
                                            maxStyle={{
                                                color: '#f56a00',
                                                backgroundColor: '#fde3cf',
                                            }}
                                        >
                                            {
                                                employees_member?.map((e) => (
                                                    <Tooltip title={e?.name} placement="top">
                                                        <Avatar
                                                            size="large"
                                                            // size={40}
                                                            style={{
                                                                backgroundColor: '#87d068',
                                                            }}
                                                            icon={<UserOutlined size={40} />}
                                                        />
                                                    </Tooltip>
                                                ))}
                                        </Avatar.Group>
                                    </div>

                                    <div>
                                        <Button
                                            onClick={() => setShowAllMemberEvent(true)}
                                            // type="primary"
                                            style={{ height: 40 }}>{t("All")}
                                        </Button>
                                    </div>

                                </div>

                            </div>

                            <div style={{
                                // marginTop: 20,
                                width: width < 700 ? "100%" : "40%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: 'column',
                                alignItems: "center"
                            }}>
                                <label style={{ paddingBottom: 20, fontSize: 16 }}>{t("Selected Color")} :</label>
                                <SketchPicker color={color} onChange={(selectedColor) => setColor(selectedColor.hex)} />
                            </div>

                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Flex gap="small" wrap="wrap">
                                <Button
                                    icon={<CloseOutlined />}
                                    style={{ height: 40 }}
                                    key="cancel"
                                    onClick={onClose}>
                                    {t("cancel")}
                                </Button>
                                <Button
                                    icon={<CheckOutlined />}
                                    loading={loading}
                                    style={{ height: 40 }}
                                    key="submit"
                                    type="primary"
                                    onClick={handleSubmit}>
                                    {t("save")}
                                </Button>
                            </Flex>
                        </div>
                    </>
                )}
            </Formik>

            <AddmemberCalendar
                isModalOpen={showAddmember}
                handleCancel={() => setShowddMember(false)}
                employees_member={employees_member}
                setEmployeesMember={setEmployeesMember}
            />

            <ViewMemberEvent
                show={showAllMemberEvent}
                handleClose={() => setShowAllMemberEvent(false)}
                employees_member={employees_member}
                onDeleteMember={onDeleteMember}
                setShowPopupDelete={setShowPopupDelete}
                handleShowDelete={handleShowDelete}
            />

        </Modal>
    );
}
