import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./en.json";
import la from "./la.json";



i18n.use(initReactI18next).init({
  resources: {
    EN: {
      translation: en,
    },
    LA: {
      translation: la,
    },
  },
  fallbackLng: localStorage.getItem('locale') || "la",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
