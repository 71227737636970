import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';

const EmployeesMemberConext = createContext()

export default function EmployeesMemberProvider({ children }) {
    const [selectEditData, setSelectEditData] = useState()
    const [employees_member, setEmployeesMember] = useState([{ employee_id: '' }]);
    return (
        <EmployeesMemberConext.Provider value={{ employees_member, selectEditData, setSelectEditData, setEmployeesMember }} >
            {children}
        </EmployeesMemberConext.Provider>
    )
}

export function useEmployeesMember() {
    return useContext(EmployeesMemberConext)
}
