import React, { useState, useEffect } from 'react'
import { notification, Avatar, Button, List, Skeleton, Divider } from 'antd';
import VirtualList from 'rc-virtual-list';
import { UserOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'react-bootstrap'
import { SERVER_URL } from '../../api';
import { useTranslation } from 'react-i18next';
import { tokenData } from '../../const';
import { errorPermissionTitleWithTextSwal } from '../../helper/sweetalert';
import { renderToString } from 'react-dom/server';
import { onConfirmButton } from '../../const';

export default function ViewAllMemberMeeting({
    show,
    meetingId,
    memberMeeting,
    fetchMeetingMember,
    fetchMemberHistoryMeeting,
    fetchMeeting,
    fetchMemberHistory,
    project,
    handleClose, }) {

    const ContainerHeight = "68vh";
    const { t } = useTranslation()
    const [showPopupDelete, setShowPopupDelete] = useState(false)
    const [Delete, setDelete] = useState("");
    const [memberAll, setMemberAll] = useState([]);
    const confirmButtonContent = renderToString(<div>{t("delete")}</div>);
    const cancelButtonContent = renderToString(<div>{t("cancel")}</div>);

    // Load member data initially
    useEffect(() => {
        const initialMemberAll = memberMeeting?.results?.filter(name => name?.meeting_id === meetingId?.meeting_uid);
        setMemberAll(initialMemberAll);
    }, [memberMeeting, meetingId]);

    const handleDeleteMember = (memberId) => {
        // Make a DELETE request to delete the member
        fetch(`${SERVER_URL + `meeting/delete-member/${memberId}`}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    fetchMemberHistory()
                    fetchMemberHistoryMeeting()
                    fetchMeetingMember()
                    notification.success({
                        message: t('Success'),
                        description: t('delete success'),
                        duration: 2,
                    });
                } else {
                    // console.error('Failed to delete the member.');
                    errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
                }
            })
            .catch((error) => {
                // console.error('Error:', error);
                errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
            });
    };

    const handleShowDelete = (id, name) => {
        setDelete({ name, id });
        onConfirmButton(t('Do you want to Delete or not?'), confirmButtonContent, cancelButtonContent, () => handleDeleteMember(id))
    };


    const onScroll = (e) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
        }
    };

    const onClose = () => {
        handleClose()
    }

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                keyboard={false}
                centered
                animation={false}
                onHide={onClose}>
                <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
                    <Modal.Title style={{ fontSize: 20 }}>{t("All Members")} ({memberMeeting?.results?.filter(name => name?.meeting_id === meetingId?.meeting_uid)?.length ?? "0"}) </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingLeft: 50, paddingRight: 50, height: "70vh" }}>
                    {memberAll?.length <= 0 ? <div style={{ height: "53vh", display: "flex", justifyContent: "center", alignItems: "center" }}>{t("No one has joined yet")}</div> :
                        <div
                            id="scrollableDiv"
                            style={{
                                height: "68vh",
                                overflow: 'auto',
                            }}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={memberAll?.sort((a, b) => a?.name?.localeCompare(b?.name))}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#87d068',
                                                    }}
                                                    icon={<UserOutlined />}
                                                />
                                            }
                                            title={<div>{item?.name}&ensp;{item?.surname}</div>}
                                            description={item?.email}
                                        />
                                        <Button
                                            icon={<DeleteOutlined />}
                                            // disabled={project?.Project[0]?.create_project_by_id === item?.emp_id ? false : true}
                                            onClick={() => handleShowDelete(item?.members_meeting_uid)}>{t("delete")}</Button>
                                    </List.Item>
                                )}
                            />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        icon={<CloseOutlined />}
                        variant="secondary"
                        onClick={handleClose}
                        style={{ width: 150, height: 40 }}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
