import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { errorPermissionTitleWithTextSwal, errorSwal } from '../../helper/sweetalert'
import ConfirmDelete from '../../components/ConfirmDelete'
import useWindowDimensions from '../../helper/useWindowDimensions'
import _ from 'lodash'
import './Home.css'
import { useProject } from '../../contexts/ProjectContext'
import moment from 'moment/moment'
import { useStatusCode } from '../../contexts/StatusContext'
import { SERVER_URL } from '../../api'
import axios from 'axios'
import { useDepartment } from '../../contexts/DepartmentContext'
import { useBranch } from '../../contexts/BranchContext'
import CreateProject from './CreateProject'
import { useTranslation } from 'react-i18next'
import { SearchOutlined, EditOutlined, DeleteOutlined, ClockCircleOutlined, CheckCircleOutlined, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Space, Select, Progress, Skeleton, Tag, Table, notification, Button, Input, Pagination, Card } from 'antd';
import { Spinner } from 'react-bootstrap'
import { tokenData } from '../../const'
import { useDeadline } from '../../contexts/DeadlineContext'
import { renderToString } from 'react-dom/server';
import { onConfirmButton } from '../../const';
const { Option } = Select;

export default function AllProjectList() {

    // --------------------- useState ---------------------

    const navigate = useNavigate()
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const { project, myProject, loading, fetchMyProject, fetchData } = useProject()
    const { fetchNotification } = useDeadline()
    const { department } = useDepartment()
    const { branch } = useBranch()
    const { status } = useStatusCode()
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showPopUpDelete, setShowPopUpDelete] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All Status');
    const [filteredResults, setFilteredResults] = useState(project?.result || []);
    const [Delete, setDelete] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false)

    const initialPageNumber = localStorage.getItem('Management-PageNumber-All') || "1";
    const [currentPage, setCurrentPage] = useState(initialPageNumber);
    const [pageSize, setPageSize] = useState(15);

    const [selectEditData, setSelectEditData] = useState()
    const [projectIDs, setProjectIDs] = useState([{ "projectID": "" }]);


    const confirmButtonContent = renderToString(<div>{t("delete")}</div>);
    const cancelButtonContent = renderToString(<div>{t("cancel")}</div>);

    // --------------------- useEffect ---------------------

    useEffect(() => {
        if (project) setFilteredResults(project?.result || [])
    }, [project])

    useEffect(() => {
        if (!searchQuery) {
            setFilteredResults(project?.result || [])
        }
        const newFilteredResults = project?.result?.filter((item) =>
            item?.p_name.toLowerCase().includes(searchQuery.toLowerCase()) || item?.create_project_by.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    }, [searchQuery])

    useEffect(() => {
        if (selectedStatus === "All Status" || selectedStatus === "All" || selectedStatus === "ທັງໝົດ") {
            setFilteredResults(project?.result || [])
        } else {
            const filtered = (project?.result || []).filter((e) => e.s_name === selectedStatus);
            setFilteredResults(filtered);
            if (filtered.length === 0) {
                notification.error({
                    message: t('Error'),
                    description: t('There is no information you searched for'),
                    duration: 2,
                });
            }
        }
    }, [selectedStatus, project?.result]);

    // --------------------- Fuctions ---------------------

    const handleSearch = () => {
        const newFilteredResults = project?.result?.filter((item) =>
            item?.p_name.toLowerCase().includes(searchQuery.toLowerCase()) || item?.create_project_by.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    };

    const onDetailProject = (id) => {
        navigate("/management/" + id);
    }

    // const handleShowDelete = (id, name) => {
    //     setDelete({ name, id });
    //     setShowPopUpDelete(true);
    // };

    const onHandleDeleteProject = (projectIDToDelete) => {
        setIsLoading(true);

        // const projectIDToDelete = Delete?.id;

        const updatedProjectIDs = projectIDs.filter(item => item.projectID !== projectIDToDelete);

        const updatedBody = {
            projectIDs: updatedProjectIDs.map(item => ({
                projectID: item.projectID === "" ? projectIDToDelete : item.projectID
            }))
        };

        // Send the updated data to the server
        const apiUrl = SERVER_URL + "proj/delete";
        axios.put(apiUrl, updatedBody, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                notification.success({
                    message: t('Success'),
                    description: t('delete success'),
                    duration: 2,
                });
                setIsLoading(false);
                setShowPopUpDelete(false);
                fetchMyProject();
                fetchData()
            })
            .catch((error) => {
                setShowPopUpDelete(false);
                errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
                setIsLoading(false);
            });
    };

    const handleShowDelete = (id, name) => {
        setDelete({ name, id });
        onConfirmButton(t('Do you want to Delete or not?'), confirmButtonContent, cancelButtonContent, () => onHandleDeleteProject(id))
    };

    const handlePageChange = (page) => {
        localStorage.setItem('Management-PageNumber-All', page);
        setCurrentPage(page);
    };

    const options = status?.results?.map((e, index) => (
        <option key={index} value={e.name}>
            {e.name}
        </option>
    ));

    const columns = [
        {
            title: t("Notification"),
            dataIndex: 'doing',
            key: 'doing',
            render: (text) => <a>{text}</a>,
            ellipsis: true,
            align: "center"
        },
        {
            title: t("No/"),
            dataIndex: 'No',
            key: 'No',
            render: (text) => <a>{text}</a>,
            ellipsis: true,
            align: "center"
        },
        {
            title: t("Name of Project"),
            dataIndex: 'Name',
            key: 'Name',
            ellipsis: true,
        },
        {
            title: t("Department"),
            dataIndex: 'Department',
            key: 'Department',
            ellipsis: true,
        },
        {
            title: t("Branch"),
            dataIndex: 'Branch',
            key: 'Branch',
            ellipsis: true,
        },
        {
            title: t("status"),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
        },
        {
            title: t("percen"),
            dataIndex: 'percen',
            key: 'percen',
            ellipsis: true,
            align: "center"
        },
        {
            title: t("create By"),
            dataIndex: 'create',
            key: 'create',
            ellipsis: true,
        },
        {
            title: t("deadline"),
            dataIndex: 'deadline',
            key: 'deadline',
            ellipsis: true,
        },
        {
            title: t("Management"),
            dataIndex: 'Management',
            key: 'Management',
            ellipsis: true,
        },

    ];

    const data = filteredResults?.map((e, index) => (
        {
            project_id: e?.project_id,
            No: index + 1,
            Name: _.truncate(e?.p_name, {
                length: width < 700 ? 20 : 30,
            }),
            Department: e?.d_name,
            Branch: e?.b_name,
            status: e?.s_name === "Finished" ?
                <Tag icon={<CheckCircleOutlined />} color="success" style={{ background: "green", color: "white", padding: 5 }}>
                    {t("Finished")}
                </Tag> : e?.s_name === "Pending" ? <Tag icon={<ClockCircleOutlined />} color="processing" style={{ padding: 5 }}>
                    {t("Pending")}
                </Tag> : e?.s_name === "Processing" ? <Tag icon={<SyncOutlined spin />} color="default" style={{ padding: 5 }}>
                    {t("Processing")}
                </Tag> : e?.s_name === "Error" ? <Tag icon={<CloseCircleOutlined />} color="error" style={{ background: "red", color: "white", padding: 5 }}>
                    {t("ErrorS")}
                </Tag> : "",
            percen: <Progress
                type="circle"
                strokeColor={e?.precent > 99 ? "#00802b" : "#ffec11"}
                percent={e?.precent}
                size={40} />,
            create: e?.create_project_by ?? "-",
            deadline: moment(e?.dl).format("DD-MM-YYYY "),
            Management: <div style={{ display: "flex" }} onClick={(e) => e.stopPropagation()}>
                <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                        setShowModalAdd(true);
                        setSelectEditData({ editMode: true, ...e });
                    }}>
                    {t("edit")}
                </Button>
                <Button
                    icon={<DeleteOutlined />}
                    type="primary ms-2"
                    style={{ background: "#FFA500" }}
                    onClick={() => handleShowDelete(e?.project_id)}
                >
                    {t("delete")}
                </Button>
            </div>,
            doing:
                <Button
                    type="primary"
                    shape="circle"
                    style={{ backgroundColor: "red", display: e?.total_notifications === "0" || e?.total_notifications === null ? "none" : "" }}>
                    {e?.total_notifications}
                </Button>
        }
    ))

    return (
        <React.Fragment>

            <div className='' >
                <Card
                    // title="Card title"
                    bordered={false}
                    style={{
                        width: '100%',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                        <div className='header-home-search' >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: width < 700 ? "column" : ""
                            }}>
                                <div style={{ display: "flex" }}>
                                    <Input
                                        value={searchQuery}
                                        onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                                handleSearch()
                                            }
                                        }}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        placeholder={t("Search name")}
                                        style={{ fontFamily: 'Noto Sans Lao, sans-serif', height: 40, width: "100%" }}
                                    />
                                    <Button
                                        // type="ms-2"
                                        style={{ marginLeft: 10, height: 40 }}
                                        onClick={handleSearch}
                                        icon={<SearchOutlined />}>
                                        {t("search")}
                                    </Button>
                                </div>
                                <div style={{ display: width < 700 ? "flex" : "none", marginTop: 20 }}>
                                    <Select
                                        labelInValue
                                        transitionName={false}
                                        defaultValue={{ value: selectedStatus, label: selectedStatus }}
                                        style={{
                                            width: "100%",
                                            paddingLeft: width < 700 ? "" : 10,
                                            height: 40
                                        }}
                                        onChange={(value) => setSelectedStatus(value?.label)}
                                    >
                                        <Option onClick={() => setSelectedStatus("All Status")}>{t("All")}</Option>
                                        {options}
                                    </Select>
                                    &ensp;
                                    {/* <Button
                                        style={{ width: "50%", background: "#00842e", color: "#FFFFFF", height: 40 }}
                                        onClick={() => setShowModalAdd(true)}>
                                        {t("create new")}
                                    </Button> */}
                                </div>
                                <Select
                                    labelInValue
                                    transitionName={false}
                                    defaultValue={{ value: selectedStatus, label: selectedStatus }}
                                    style={{
                                        width: width < 700 ? "100%" : 200,
                                        paddingLeft: width < 700 ? "" : 10,
                                        marginTop: width < 700 ? 20 : "",
                                        display: width < 700 ? "none" : "",
                                        height: 40
                                    }}
                                    onChange={(value) => setSelectedStatus(value?.label)}
                                >
                                    <Option onClick={() => setSelectedStatus("All Status")}>{t('All')}</Option>
                                    {options}
                                </Select>
                            </div>
                        </div>
                        <Space>
                            <Button
                                onClick={() => {
                                    fetchMyProject()
                                    fetchData()
                                    fetchNotification()
                                }}
                                style={{ height: 40, display: width < 700 ? "none" : "" }}
                                type="primary"
                                icon={loading ? <Spinner size='sm' /> : <SyncOutlined />}>
                                {t('Refresh')}
                            </Button>
                            {/* <Button
                                onClick={() => setShowModalAdd(true)}
                                style={{ height: 40 }}
                                type="primary"
                                icon={<PlusOutlined />}>
                                {t("Create Project")}
                            </Button> */}
                        </Space>
                    </div>
                </Card>

                <div className='main-content-home' >
                    <div className='content-title-home' style={{ display: "flex", fontWeight: "bold" }}>
                        {t("All items")} ( {filteredResults?.length} )
                        <div style={{ paddingLeft: 20 }}> {t('Number Of Page')} {currentPage}</div>
                    </div>
                    <div className='content-home'>
                        <Skeleton
                            loading={loading}
                            paragraph={{
                                rows: 13,
                            }}
                            style={{ padding: 20 }}
                            active Table>
                            <Table
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: async () => {
                                            onDetailProject(record?.project_id)
                                        },
                                        className: record?.total_notifications === "0" ? ' ' : 'bg-[#017e84]', // Add a class for styling active rows
                                    };
                                }}
                                rowClassName={(record) => {
                                    return { className: record?.total_notifications === "0" ? ' ' : 'bg-[#017e84]', }
                                }}
                                className='red'
                                size='small'
                                scroll={{ x: true }}
                                pagination={{
                                    position: ['', 'bottomCenter'],
                                    current: currentPage,
                                    total: filteredResults?.length,
                                    defaultCurrent: 1,
                                    pageSize: pageSize,
                                    onChange: handlePageChange,
                                    showSizeChanger: false
                                }}
                                columns={columns}
                                dataSource={data} />
                        </Skeleton>

                    </div>
                </div>
            </div>

            <CreateProject
                show={showModalAdd}
                handleclose={() => setShowModalAdd(false)}
                loadingCreate={loadingCreate}
                setLoadingCreate={setLoadingCreate}
                branch={branch}
                department={department}
                status={status}
                selectEditData={selectEditData}
                setSelectEditData={setSelectEditData}
            />

        </React.Fragment >
    )
}
