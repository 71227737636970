// Calendar.js
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth'
import rrulePlugin from '@fullcalendar/rrule';
import { useNavigate } from 'react-router-dom';
import { useCalendar } from '../../contexts/CalendarContext';
import AddEvent from './AddEvent';
import { useOutMeeting } from '../../contexts/OutMeetingContext';
import { PlusOutlined } from '@ant-design/icons';
import UpdateEvent from './UpdateEvent';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../helper/useWindowDimensions';

const Calendar = () => {

  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const { outMeeting, outMeetingPublic } = useOutMeeting()
  const { events, setEvenDetail } = useCalendar()
  const [showAddEvent, setShowAddEvent] = useState(false)
  const [showUpdateEvent, setShowUpDateEvent] = useState(false)
  const navigate = useNavigate()
  const [event, setEvent] = useState([])
  const [selectedEvent, setSelectedEvent] = useState()
  const [allEvent, setAllEvent] = useState([])

  useEffect(() => {
    if (outMeeting && outMeetingPublic && events) {
      const updatedOutMeeting = outMeeting.map(item => {
        if (item && item.is_repeat === "NOREPEAT") {
          const { rrule, ...rest } = item;
          return rest;
        }
        return item;
      });

      const updateOutMeetingPublic = outMeetingPublic?.map(item => {
        if (item && item?.is_repeat === "NOREPEAT") {
          const { rrule, ...rest } = item;
          return rest;
        }
        return item;
      });

      const combinedEvents = [...updatedOutMeeting, ...events, ...updateOutMeetingPublic];
      setAllEvent(combinedEvents);
    }
  }, [outMeeting, events, outMeetingPublic]);

  const handleEventAdd = (eventData) => {
    setEvent([...event, eventData]);
    setShowAddEvent(false);
  };

  const handleUpdateEvent = (eventData) => {
    const updatedEvents = event.map(event => {
      if (event.id === eventData.id) {
        return eventData;
      }
      return event;
    });
    setEvent(updatedEvents);
    setSelectedEvent(null);
    setShowAddEvent(false);
  }

  const handleDeleteEvent = () => {
    const updatedEvents = event.filter(event => event.id !== selectedEvent.id);
    setEvent(updatedEvents);
    setSelectedEvent(null);
    setShowAddEvent(false);
  }

  const handleEventClick = (info) => {
    const clickedEvent = info.event;
    const { outmeeting_uid } = clickedEvent.extendedProps;
    setSelectedEvent(outmeeting_uid)
    setShowUpDateEvent(true)

    if (info.event.extendedProps.projects_id && info.event.extendedProps.meeting_id) {
      // Use the navigate function to redirect to the desired URL
      const { meeting_id } = info.event.extendedProps;
      setEvenDetail(meeting_id)
      navigate("/management/" + info.event.extendedProps.projects_id);
    }
  }

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: 'short',
  };

  // console.log("outMeeting========>", outMeeting)
  // console.log("outMeetingPublic========>", outMeetingPublic)
  // console.log("events-meeting========>", events)

  // console.log("eventAll ===========>",allEvent)

  return (
    <div className={`bg-white ${width < 700 ? "" : "p-5"} `} >

      <div style={{ paddingBottom: 20 }}>
        <Button icon={<PlusOutlined />} style={{ height: 40 }} onClick={() => setShowAddEvent(true)}>{t("Add Meeting")}</Button>
      </div>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin, rrulePlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear',
        }}
        displayEventEnd="true"
        eventDisplay='true'
        editable={true}
        events={allEvent}
        height={'100vh'}
        eventClick={handleEventClick}
        // dateClick={() => setShowAddEvent(true)}
        eventTimeFormat={eventTimeFormat}
        eventColor='green'
        scrollTime={false}
      />

      <AddEvent
        handleEventAdd={handleEventAdd}
        handleUpdateEvent={handleUpdateEvent}
        handleDeleteEvent={handleDeleteEvent}
        setSelectedEvent={setSelectedEvent}
        event={selectedEvent}
        isModalOpen={showAddEvent}
        handleCancel={() => setShowAddEvent(false)}
      />

      <UpdateEvent
        isModalOpen={showUpdateEvent}
        handleCancel={() => setShowUpDateEvent(false)}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />

    </div>
  );
};

export default Calendar;
