import React, { useState } from 'react'
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

export default function ConfirmDelete({ show, handleClose, title, subtitle, onSubmit, isLoading }) {
    const { t } = useTranslation()
    return (
        <React.Fragment>
            <Modal
                open={show}
                // title={title}
                centered
                onOk={onSubmit}
                transitionName={false}
                onCancel={handleClose}
                closeIcon={false}
                // okText={isLoading ? <Spinner size='sm' /> : "ລຶບ"}
                footer={[
                    <Button icon={<CloseOutlined />} key="cancel" onClick={handleClose} style={{ height: 40 }}>
                        {t("cancel")}
                    </Button>,
                    <Button
                        icon={<DeleteOutlined/>}
                        loading={isLoading}
                        key="ok"
                        type="primary" // Add this line to set the button type to primary
                        style={{ backgroundColor: '#FFA500', height: 40 }}
                        onClick={onSubmit}
                        disabled={isLoading}
                    >
                        {t("delete")}
                    </Button>,
                ]}
            // cancelText={t("cancel")}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 250
                }}>
                    <DeleteOutlined style={{ fontSize: 50 }} />
                    <span style={{ fontSize: 20, paddingTop: 20 }}>{title}</span>
                </div>
            </Modal>
        </React.Fragment>
    )
}
