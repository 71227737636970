import React, { useState, useEffect } from "react";
import { useDeadline } from "../contexts/DeadlineContext";
import {
  UsergroupAddOutlined,
  HomeOutlined,
  DownOutlined,
  ReloadOutlined,
  CalendarOutlined,
  BellOutlined,
  FieldTimeOutlined,
  MenuOutlined,
  MessageFilled,
  UserOutlined
} from "@ant-design/icons";
import { Outlet, useLocation } from "react-router-dom";
import { Layout, Menu, theme, Space, Badge, Button, Popconfirm, List, Tabs, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { useProject } from "../contexts/ProjectContext";
const { Header, Sider, Content } = Layout;

const MainLayout = () => {

  const [selectedKey, setSelectedKey] = useState(localStorage.getItem('selectedKey') || '/');
  const [language, setLanguage] = useState(localStorage.getItem('locale'));
  const { token: { colorBgContainer }, } = theme.useToken();
  const { notification, deadline, loading, fetchData, fetchNotification } = useDeadline()
  const { project, fetchMyProject, fetchData: fetchAllProject } = useProject()
  const [collapsed, setCollapsed] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const ContainerHeight = 400;

  // Filter items by status
  const itemsWithStatus1 = notification?.results?.filter(item => item.status === 1);
  const itemsWithStatus0 = notification?.results?.filter(item => item.status === 0);

  // Concatenate the two arrays with itemsWithStatus1 on top
  const sortedItems = itemsWithStatus1?.concat(itemsWithStatus0);

  useEffect(() => {
    localStorage.setItem('selectedKey', selectedKey);
  }, [selectedKey]);

  useEffect(() => {
    fetchNotification();
  }, []);
  const onScroll = (e) => {
    // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
      fetchNotification();
    }
  };

  const menu = [
    {
      key: '/home',
      icon: <HomeOutlined style={{ fontSize: 20 }} />,
      label: t('Home'),
    },
    {
      key: '/',
      icon: <CalendarOutlined style={{ fontSize: 20 }} />,
      label: t('Work tracking management'),
    },
    {
      key: '/user-group-list',
      icon: <UsergroupAddOutlined style={{ fontSize: 20 }} />,
      label: t('User Group'),
    }
  ]

  useEffect(() => {
    localStorage.getItem('locale')
  }, [language])

  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };

  const tokenString = getCookie('user');

  // Decode the URL-encoded string
  const decodedTokenString = decodeURIComponent(tokenString);

  const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

  const onDetailProject = (id) => {
    navigate("/management/" + id);
    fetchNotification()
  }

  const _changeLanguage = (locale) => {
    const { pathname, search } = location;
    const newLocation = `${pathname}${search}`;
    // Save the selected locale to localStorage
    localStorage.setItem('locale', locale);
    navigate(newLocation, { state: { locale } });
    i18n.changeLanguage(locale);
    setLanguage(locale);
  };

  const projectName = (value) => {
    // Find the project with the matching ID
    const projects = project?.result?.find(item => item?.project_id === value);

    // console.log("projects=========>", projects)
    // If project is found, return its name, otherwise return null or an empty string
    return projects ? projects?.p_name : null; // You can change null to '' if you prefer an empty string
  }

  const content = (
    <div style={{ width: 400, height: 500 }}>
      <Tabs
        // onChange={onChange}
        type="card"
        items={[
          {
            label: <div>{t("General")} ({itemsWithStatus1?.length})</div>,
            key: 1,
            children:
              <div style={{
                height: "55vh",
                overflow: "auto"
              }}>
                {/* {notification?.results?.map((e) => (
                  <div>{e?.message}</div>
                ))} */}
                <List
                  itemLayout="horizontal"
                  dataSource={sortedItems}
                  style={{ width: 400, paddingLeft: 20, paddingRight: 20 }}
                  renderItem={(item, index) => (
                    <List.Item
                      onClick={() => onDetailProject(item?.project_id)}>
                      <List.Item.Meta
                        style={{ background: item?.status === 0 ? "" : "#EEF0F6", cursor: "pointer", padding: 10 }}
                        avatar={<Avatar icon={<UserOutlined />} />}
                        title={<div style={{ color: "#737489", fontWeight: "bold" }}>{item.message}</div>}
                        description={
                          <div >
                            <div style={{ fontWeight: "initial", color: "#737489" }}>{t("Project Name Noti")} :  {projectName(item?.project_id)}</div>
                            <div>{t("Time")} : {moment(item?.created_at)?.format("YYYY-MM-DD HH:mm")}</div>
                            <div><MessageFilled style={{ color: item?.status === 0 ? "" : "green" }} /> : {item?.status === 0 ? t("Already read") : t("Haven't read it yet.")}</div>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
          },
          {
            label: <div>{t("DeadLine Project")} ({deadline?.results?.length})</div>,
            key: 2,
            children: <List
              dataSource={deadline?.results?.slice(0, 5)}
              style={{ width: 400, paddingLeft: 20, paddingRight: 20, height: "auto" }}
              renderItem={(item) => {
                // Calculate the remaining days
                const deadlineDate = moment(item?.deadline, "YYYY-MM-DD");
                const currentDate = moment();
                const remainingDays = deadlineDate?.diff(currentDate, 'days');

                return (
                  <List.Item key={item.email}>
                    <List.Item.Meta
                      avatar={<Avatar icon={<FieldTimeOutlined />} />}
                      title={<a onClick={() => onDetailProject(item?.project_id)}>{item?.p_name}</a>}
                      description={
                        <div>
                          {t("deadline")} {moment(item?.deadline).format("DD-MM-YYYY")}
                        </div>
                      }
                    />
                    {/* <div style={{ color: "red", display: "flex", flexDirection: "column" }}>ກາຍໄປ&emsp;{`${remainingDays} `} {t("Day")}</div> */}
                    <div style={{ color: "red", display: "flex", flexDirection: "column" }}>{`${remainingDays} `} {t("Day")}</div>
                  </List.Item>
                );
              }}
            />,
          }
        ]}
      />

      {/* {
        deadline?.results?.length > 5 ? <div style={{ textAlign: "center" }}>
          <Button>
            ເບີ່ງທັງໝົດ
            <CaretDownOutlined />
          </Button>
        </div> : ""
      } */}

    </div>
  );


  return (
    <Layout >
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <span className="sm-logo">P</span>
            {/* <span className="lg-logo">{t("Work tracking management")}</span> */}
          </h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          onClick={({ key }) => {
            if (key == "/home") {
              window.location.href = 'https://psvsystem.com';
            } else {
              navigate(key);
              setSelectedKey(key)
            }
          }}
          defaultSelectedKeys={[selectedKey]}
          items={menu}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuOutlined : MenuOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ cursor: "pointer", paddingRight: 20 }}>
              <Popconfirm
                transitionName={false}
                icon={''}
                showCancel={false}
                okButtonProps={{ style: { display: 'none' } }} // Hide the Ok button
                description={content}
                title={
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}>
                    <div style={{ fontSize: 16 }}>{t("Notification")}</div>
                    <div>
                      <Button
                        onClick={() => {
                          fetchData()
                          fetchNotification()
                          fetchAllProject()
                          fetchMyProject()
                        }}
                        icon={loading ? <Spinner size="sm" /> : <ReloadOutlined />}>
                        {t("Refresh")}
                      </Button>
                    </div>
                  </div>
                }>
                <Badge count={deadline?.results?.length + itemsWithStatus1?.length || '0'}>
                  <BellOutlined style={{ fontSize: 20, color: "green" }} />
                </Badge>
              </Popconfirm>
            </div>
            <div>
              <Popconfirm
                icon={''}
                showCancel={false}
                okButtonProps={{ style: { display: 'none' } }} // Hide the Ok button
                transitionName={false}
                description={
                  <div style={{
                    padding: 20,
                    cursor: "pointer"
                  }}>
                    <div
                      onClick={() => _changeLanguage('LA')}
                      style={{
                        cursor: "pointer",
                        background: language === "LA" ? "#e6f4ff" : "",
                        color: language === "LA" ? "green" : "",
                        fontWeight: language === "LA" ? "400" : "",
                      }}>
                      <img
                        src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Laos.svg/2560px-Flag_of_Laos.svg.png'}
                        alt=""
                        style={{ width: 24, height: 16 }}
                      />
                      &ensp;
                      {'LAO'}
                    </div>
                    <div
                      onClick={() => _changeLanguage('EN')}
                      style={{
                        cursor: "pointer",
                        marginTop: 20,
                        background: language === "EN" ? "#e6f4ff" : "",
                        color: language === "EN" ? "green" : "",
                        fontWeight: language === "EN" ? "400" : "",
                      }}>
                      <img
                        src={'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/2880px-Flag_of_the_United_Kingdom_%281-2%29.svg.png'}
                        alt=""
                        style={{ width: 24, height: 16 }}
                      />
                      &ensp;
                      {'ENGLISH'}
                    </div>
                    {/* ))} */}
                  </div>
                }
              >
                <div onClick={(e) => e.preventDefault()} style={{ fontWeight: 400, color: "green" }}>
                  <Space>
                    <img
                      src={language === "EN" ? "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/2880px-Flag_of_the_United_Kingdom_%281-2%29.svg.png" :
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Laos.svg/2560px-Flag_of_Laos.svg.png"}
                      alt=""
                      style={{ width: 24, height: 16 }}
                      width={600}
                      height={900}
                    />
                    {language}
                    <DownOutlined />
                  </Space>
                </div>
              </Popconfirm>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "8px 16px",
            minHeight: 280,
            background: "#64748b",
            overflowY: "auto",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
