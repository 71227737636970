import React, { useState, useEffect } from 'react';
import { DeleteOutlined, EditOutlined, CloseOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { Modal, Button, Input, notification, Select, Checkbox, Radio, Avatar, Tooltip, Flex, DatePicker } from 'antd';
import { SketchPicker } from 'react-color'; // Import the color picker
import moment from 'moment';
import AddmemberCalendar from './AddmemberCalendar';
import { onConfirmButton, tokenData } from '../../const';
import axios from 'axios';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap'
import { SERVER_URL } from '../../api';
import { useOutMeeting } from '../../contexts/OutMeetingContext';
import { useTranslation } from 'react-i18next';
import { errorPermissionTitleWithTextSwal, errorSwal } from '../../helper/sweetalert';
import dayjs from 'dayjs';
import useWindowDimensions from '../../helper/useWindowDimensions';
import utc from 'dayjs/plugin/utc';
import ViewMemberEvent from './ViewMemberEvent';
import ConfirmDelete from '../../components/ConfirmDelete';
import { renderToString } from 'react-dom/server';
const { TextArea } = Input;
const { Option } = Select;
dayjs.extend(utc);

export default function UpdateEvent({
    isModalOpen,
    handleCancel,
    selectedEvent,
    setSelectedEvent,
}) {

    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const { fetchOutMeeting, fetchOutMeetingPublic, employees_member, setEmployeesMember } = useOutMeeting()
    const [evenData, setEventData] = useState([])
    const [evenPublicData, setEvenPublicData] = useState([])
    const [showAddmember, setShowddMember] = useState(false)
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [allDay, setAllDay] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [color, setColor] = useState(''); // State for storing the selected color
    const [count, setCount] = useState()
    const [freq, setFreq] = useState('WEEKLY')
    const [status, setStatus] = useState('')
    const [interval, setInterval] = useState(1)
    const [location, setLocation] = useState('')
    const [video_conference, setVideo_conference] = useState('')
    const [loading, setloading] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [repeat, setRepeat] = useState("NOREPEAT")
    const [selectedItems, setSelectedItems] = useState([]);
    const [showAllMemberEvent, setShowAllMemberEvent] = useState(false)
    const [showPopupDelete, setShowPopupDelete] = useState(false)
    const [Delete, setDelete] = useState("");

    const confirmButtonContent = renderToString(<div>{t("delete")}</div>);
    const cancelButtonContent = renderToString(<div>{t("cancel")}</div>);

    useEffect(() => {
        if (selectedEvent) {
            fetchEvent()
            fetchEventPublic()
        }
    }, [selectedEvent])

    useEffect(() => {
        if (selectedEvent || evenData || evenPublicData) {
            setTitle(evenData?.title || evenPublicData?.title)
            setDescription(evenData?.description || evenPublicData?.description)
            // const formattedStartDate = dayjs(evenData?.rrule?.dtstart)
            // const formattedStartDatePublic = dayjs(evenPublicData?.rrule?.dtstart)

            // const formattedEndStartDate = dayjs(evenData?.rrule?.untils)
            // const formattedEndStartDatePublic = dayjs(evenPublicData?.rrule?.untils)

            // setStartDate(formattedStartDate || formattedStartDatePublic)
            // setEndDate(formattedEndStartDate || formattedEndStartDatePublic)

            setCount(evenData?.rrule?.count || evenPublicData?.rrule?.count)
            setFreq(evenData?.rrule?.freq || evenPublicData?.rrule?.freq)
            setColor(evenData?.backgroundColor || evenPublicData?.backgroundColor)
            setStatus(evenData?.status || evenPublicData?.status)
            setEmployeesMember(evenPublicData?.guests?.map((item => ({ value: item?.employee_id, name: item?.employee_name }))) || [])
            setRepeat(evenData?.is_repeat || evenPublicData?.is_repeat)
            setLocation(evenData?.location || evenPublicData?.location)
            setVideo_conference(evenData?.video_conference || evenPublicData?.video_conference)
            setInterval(evenData?.interval || evenPublicData?.interval)
            const parsedByweekday = evenData?.rrule?.byweekday !== undefined ? JSON.parse(evenData?.rrule?.byweekday) : [];
            const parsedByweekdayPublic = evenPublicData?.rrule?.byweekday !== undefined ? JSON.parse(evenPublicData?.rrule?.byweekday) : [];

            setSelectedItems(parsedByweekday || parsedByweekdayPublic)

            if (evenData?.is_repeat === "NOREPEAT" || evenPublicData?.is_repeat === "NOREPEAT") {
                setSelectedItems([])
                setCount(1)
            }
        }
    }, [evenData, evenPublicData, selectedEvent])

    useEffect(() => {
        if (evenPublicData) {
            const formattedStartDatePublic = dayjs(evenPublicData?.rrule?.dtstart)
            const formattedEndStartDatePublic = dayjs(evenPublicData?.rrule?.untils)
            setStartDate(formattedStartDatePublic)
            setEndDate(formattedEndStartDatePublic)
        } else {
            const formattedStartDate = dayjs(evenData?.rrule?.dtstart)
            const formattedEndStartDate = dayjs(evenData?.rrule?.untils)
            setStartDate(formattedStartDate)
            setEndDate(formattedEndStartDate)
        }
    }, [selectedEvent, evenPublicData, evenData])

    useEffect(() => {
        fetchEvent()
    }, [])

    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);

    const fetchEvent = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}out-meeting/outmeeting/${selectedEvent}`);
            setEventData(response.data?.results?.[0]);
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const fetchEventPublic = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}out-meeting/out-meeting/${selectedEvent}`);
            setEvenPublicData(response.data?.results?.[0]);
        } catch (error) {
            console.log("Error: ", error)
            errorSwal('Foriben')
        }
    }

    const handleOk = async (values) => {

        setLoadingUpdate(true)

        try {
            const apiUrl = `${SERVER_URL + "out-meeting/update/" + selectedEvent}`;

            await axios.put(apiUrl, values, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`
                },
            })
                .then((response) => {
                    notification.success({
                        message: t('Success'),
                        description: t('save success'),
                        duration: 2,
                    });
                    setEmployeesMember([])
                    fetchOutMeeting()
                    fetchOutMeetingPublic()
                    handleCancel()
                    setLoadingUpdate(false)
                })
                .catch((error) => {
                    console.log('Error: ', error)
                    errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
                    setLoadingUpdate(false)
                });

        } catch (error) {
            console.log("Error: ", error)
            errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
        }

    };

    const handleCancelEvent = async () => {
        setloading(true)
        try {
            // Make a PUT or PATCH request to update the data
            axios.put(`${SERVER_URL}out-meeting/outmeeting/cancel/${selectedEvent}`, '', {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                },
            })
                .then(response => {
                    fetchOutMeeting()
                    fetchOutMeetingPublic()
                    notification.success({
                        message: t('Success'),
                        description: t('delete success'),
                        duration: 2,
                    });
                    handleCancel()
                    setloading(false)
                })
                .catch(error => {
                    console.error('Error updating data:', error);
                    setloading(false)
                    errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
                });
        } catch (error) {
            console.log("Error: ", error)
            errorPermissionTitleWithTextSwal('403 Forbidden', 'You do not have required permissions to perform the action.')
        }
    }

    const onClose = () => {
        handleCancel()
        setSelectedEvent('')
        setTitle()
        setDescription()
        setStartDate(null)
        setEndDate(null)
        setEmployeesMember([])
        setEvenPublicData([])
    }

    const onDeleteMember = (indexToDelete) => {
        setEmployeesMember(prevArray => {
            // Filter out the item at the specified index
            return prevArray.filter((item, index) => index !== indexToDelete);
        });
    };

    const handleShowDelete = (id, name) => {
        setDelete({ name, id });
        onConfirmButton(t('Do you want to Delete or not?'), confirmButtonContent, cancelButtonContent, () => onDeleteMember(id))
    };

    const WeekDay = [
        { name: "MONDAY", value: "MO" },
        { name: "TUESDAY", value: "TU" },
        { name: "WEDNESDAY", value: "WE" },
        { name: "THURSDAY", value: "TH" },
        { name: "FRIDAY", value: "FR" },
        { name: "SATURDAY", value: "SA" },
        { name: "SUNDAY", value: "SU" },
    ]

    const handleCheckDepartMent = (itemValue) => {
        const currentIndex = selectedItems?.indexOf(itemValue);
        const newChecked = [...selectedItems];

        if (currentIndex === -1) {
            newChecked.push(itemValue);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItems(newChecked);
    };

    const handleChangeDepartMent = (selectedItems) => {
        setSelectedItems(selectedItems);
    };

    function onChangeDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);
        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD HH:mm:ss")
        // Set the selected date in the new time zone
        setStartDate(bangkokTime);
    }

    function onChangeEndDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);
        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD HH:mm:ss")
        // Set the selected date in the new time zone
        setEndDate(bangkokTime);
    }

    return (
        <Modal
            width={width < 700 ? "100%" : '60%'}
            centered
            title={t("Update Meeting")}
            visible={isModalOpen}
            onCancel={onClose}
            transitionName={false}
            footer={[]}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    title: title || "",
                    description: description || "",
                    fromdate: startDate || "",
                    todate: endDate || "",
                    backgroundColor: color || "",
                    allDay: "1",
                    location: location || "",
                    is_repeat: repeat || "",
                    video_conference: video_conference || "",
                    freq: freq || "",
                    interval: "",
                    count: count || "",
                    wkst: "",
                    tzid: "",
                    bysetpos: "",
                    bymonth: "",
                    bymonthday: "",
                    byyearday: "",
                    byweekno: "",
                    byweekday: selectedItems || "",
                    byhour: "",
                    byminute: "",
                    bysecond: "",
                    byeaster: "",
                    status: status || "",
                    guests: employees_member || []
                }}
                validate={(values) => {
                    const errors = {};
                    if (!title || title.trim() === '') {
                        errors.title = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!startDate) {
                        errors.startDate = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!endDate) {
                        errors.endDate = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!freq) {
                        errors.freq = 'ກະລຸນາປ້ອນຊື່';
                    }
                    if (!count) {
                        errors.count = 'ກະລຸນາປ້ອນຊື່';
                    }

                    if (new Date(endDate) <= new Date(startDate)) {
                        errors.endDate = 'ວັນທີ່ສິ້ນສຸດຕໍ່ວັນທີ່ໝົດກໍ່ຖືກຕ້ອງ';
                        errorSwal(t('End date must be more than Start Date'))
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    handleOk(values)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (

                    <>
                        <div style={{
                            paddingTop: 20,
                            paddingBottom: 20,
                            display: "flex",
                            flexDirection: width < 700 ? "column" : ""
                        }}>
                            <div style={{ width: width < 700 ? "100%" : "60%" }}>

                                <Form.Group className="mb-3">
                                    <Form.Label style={{ fontSize: 14, color: "#121212BF", fontWeight: 400 }}>ຫົວຂໍ້</Form.Label>
                                    <Form.Control
                                        name='title'
                                        type="text"
                                        onChange={handleTitleChange}
                                        value={title}
                                        isInvalid={errors?.title}
                                        style={{ fontSize: 14, height: 40 }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("Description")}</Form.Label>
                                    <Form.Control
                                        name='description'
                                        type="text"
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        style={{ fontSize: 14, height: 40 }}
                                    />
                                </Form.Group>

                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "50%" }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("Location")}</Form.Label>
                                            <Form.Control
                                                name='location'
                                                type="text"
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                                style={{ fontSize: 14, height: 40 }}
                                            // isInvalid={errors?.location}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div style={{ width: 10 }}></div>

                                    <div style={{ width: "50%" }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("Video Conference")}</Form.Label>
                                            <Form.Control
                                                name='video_conference'
                                                type="text"
                                                value={video_conference}
                                                onChange={(e) => setVideo_conference(e.target.value)}
                                                style={{ fontSize: 14, height: 40 }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div style={{ marginTop: 20 }}>
                                    <Radio.Group onChange={(e) => setRepeat(e.target.value)} value={repeat}>
                                        <Radio value={'REPEAT'}>{t("repeat")}</Radio>
                                        <Radio value={"NOREPEAT"}>{t("Don't repeat.")}</Radio>
                                    </Radio.Group>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>{t("Select Day Repeat")}</Form.Label>
                                        <Select
                                            disabled={repeat === "NOREPEAT" ? true : false}
                                            mode="multiple"
                                            placeholder="Select Day"
                                            style={{ width: "100%", minHeight: 40 }}
                                            value={selectedItems}
                                            onChange={handleChangeDepartMent}
                                        >
                                            {WeekDay?.map((item, index) => (
                                                <Option key={item?.value} value={item?.value}>
                                                    <Checkbox
                                                        checked={selectedItems?.includes(item?.value)}
                                                        onChange={() => handleCheckDepartMent(item?.value)}
                                                    >
                                                        {item?.name}
                                                    </Checkbox>
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Group>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>{t("Repeat type")}</Form.Label>
                                        <Select
                                            disabled={repeat === "NOREPEAT" ? true : false}
                                            defaultValue="WEEKLY"
                                            style={{
                                                width: '100%',
                                                height: 40,
                                                border: errors?.freq ? "1px solid red" : "",
                                            }}
                                            onChange={(value) => setFreq(value)}
                                            value={freq}
                                            options={[
                                                {
                                                    value: 'YEARLY',
                                                    label: 'YEARLY',
                                                },
                                                {
                                                    value: 'MONTHLY',
                                                    label: 'MONTHLY',
                                                },
                                                {
                                                    value: 'WEEKLY',
                                                    label: 'WEEKLY',
                                                },
                                                {
                                                    value: 'DAILY',
                                                    label: 'DAILY',
                                                },
                                                {
                                                    value: 'HOURLY',
                                                    label: 'HOURLY',
                                                },
                                                {
                                                    value: 'MINUTELY',
                                                    label: 'MINUTELY',
                                                },
                                                {
                                                    value: 'SECONDLY',
                                                    label: 'SECONDLY',
                                                },
                                            ]}
                                        />
                                    </Form.Group>
                                </div>

                                <div style={{ display: "flex" }}>
                                    {/* <div style={{ width: "50%" }}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>{t("Repeat number")}</Form.Label>
                                            <Form.Control
                                                disabled={repeat === "NOREPEAT" ? true : false}
                                                min={1}
                                                type='number'
                                                defaultValue={1}
                                                value={interval}
                                                onChange={(e) => setInterval(e?.target.value)}
                                                // isInvalid={errors?.interval}
                                                style={{ height: 40 }}
                                            />
                                        </Form.Group>
                                    </div> */}

                                    {/* <div style={{ width: 10 }}></div> */}

                                    <div style={{ width: "100%" }}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>{t("Count")}</Form.Label>
                                            <Form.Control
                                                disabled={repeat === "NOREPEAT" ? true : false}
                                                min={1}
                                                type='number'
                                                defaultValue={1}
                                                value={count}
                                                // onChange={(e) => setCount(e?.target.value)}
                                                onChange={(e) => {
                                                    // Ensure the value is at least 1
                                                    const newValue = Math.max(1, parseInt(e.target.value));

                                                    // Update the state with the new value
                                                    setCount(newValue);
                                                }}
                                                isInvalid={errors?.count}
                                                style={{ height: 40 }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div style={{ display: "flex" }}>

                                    <div style={{ width: "50%" }}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>ເເຕ່ວັນທີ</Form.Label>
                                            {/* <Form.Control
                                                type="datetime-local"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                style={{ width: '100%', height: 40 }}
                                                isInvalid={errors?.startDate}
                                            /> */}

                                            <DatePicker
                                                value={startDate}
                                                onChange={onChangeDate}
                                                placeholder={t("Select Date")}
                                                showTime
                                                format="YYYY-MM-DD HH:mm:ss"
                                                valueFormat="YYYY-MM-DD HH:mm:ss"
                                                allowClear={false} // Disable the clear icon
                                                style={{ fontSize: 14, borderRadius: 8, width: "100%", height: 40 }}
                                                needConfirm={false} />
                                        </Form.Group>
                                    </div>


                                    <div style={{ width: 20 }}></div>

                                    {!allDay &&
                                        <div style={{ width: "50%" }}>
                                            <Form.Group className="mt-3">
                                                <Form.Label>ຫາວັນທີ</Form.Label>
                                                {/* <Form.Control
                                                    type="datetime-local"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    style={{ width: '100%', height: 40 }}
                                                    isInvalid={errors?.endDate}
                                                /> */}
                                                <DatePicker
                                                    value={endDate}
                                                    onChange={onChangeEndDate}
                                                    placeholder={t("Select Date")}
                                                    showTime
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                                    allowClear={false} // Disable the clear icon
                                                    style={{ fontSize: 14, borderRadius: 8, width: "100%", height: 40 }}
                                                    needConfirm={false} />
                                            </Form.Group>
                                        </div>
                                    }

                                </div>

                                <div style={{ marginTop: 20, display: status === "private" ? "none" : "flex", justifyContent: "space-between" }}>
                                    <Button
                                        onClick={() => setShowddMember(true)}
                                        icon={<UsergroupAddOutlined
                                            style={{ fontSize: 20 }} />}
                                        type="primary"
                                        style={{ height: 40 }}>{t("Add Member")}</Button>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <Avatar.Group
                                            maxCount={5}
                                            size="large"
                                            maxStyle={{
                                                color: '#f56a00',
                                                backgroundColor: '#fde3cf',
                                            }}
                                        >
                                            {
                                                employees_member?.map((e, index) => (
                                                    <Tooltip title={
                                                        <div>
                                                            {e?.name}
                                                            {/* <Button
                                                                style={{ marginLeft: 10 }}
                                                                type="primary"
                                                                // onClick={() => onDeleteMember(index)}
                                                                shape="circle"
                                                            /> */}
                                                        </div>}
                                                        placement="top">
                                                        <Avatar
                                                            size="large"
                                                            style={{
                                                                backgroundColor: '#87d068',
                                                            }}
                                                            icon={<UserOutlined size={40} />}
                                                        />
                                                    </Tooltip>
                                                ))}


                                        </Avatar.Group>
                                    </div>

                                    <div>
                                        <Button
                                            onClick={() => setShowAllMemberEvent(true)}
                                            // type="primary"
                                            style={{ height: 40 }}>{t("All")}
                                        </Button>
                                    </div>
                                </div>

                            </div>
                            <div style={{

                                // marginTop: 20,
                                width: width < 700 ? "100%" : "40%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: 'column',
                                alignItems: "center"
                            }}>
                                <label style={{ paddingBottom: 20, fontSize: 16 }}>{t("Selected Color")} :</label>
                                <SketchPicker color={color} onChange={(selectedColor) => setColor(selectedColor.hex)} />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Flex gap="small" wrap="wrap">
                                <Button
                                    icon={<DeleteOutlined />}
                                    loading={loading}
                                    style={{ height: 40 }}
                                    key="cancel"
                                    onClick={handleCancelEvent}
                                >
                                    {t("delete")}
                                </Button>
                                <Button
                                    icon={<CloseOutlined />}
                                    style={{ height: 40 }}
                                    key="cancel"
                                    onClick={onClose}
                                >
                                    {t("cancel")}
                                </Button>
                                <Button
                                    icon={<EditOutlined />}
                                    style={{ height: 40 }}
                                    key="submit"
                                    loading={loadingUpdate}
                                    type="primary"
                                    onClick={handleSubmit}>
                                    {t("save")}
                                </Button>
                            </Flex>
                        </div>
                    </>
                )}
            </Formik>

            <AddmemberCalendar
                isModalOpen={showAddmember}
                handleCancel={() => setShowddMember(false)}
            />

            <ViewMemberEvent
                show={showAllMemberEvent}
                handleClose={() => setShowAllMemberEvent(false)}
                employees_member={employees_member}
                onDeleteMember={onDeleteMember}
                setShowPopupDelete={setShowPopupDelete}
                handleShowDelete={handleShowDelete}
            />



        </Modal>
    )
}
