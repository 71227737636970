import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import axios from 'axios';
import Cookies from 'js-cookie';
import { tokenData } from '../const';

const ProjectContext = createContext()

export default function ProjectProvider({ children }) {

    const [project, setProject] = useState([])
    const [myProject, setMyProject] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!tokenData?.token) return
        fetchData();
        fetchMyProject()
    }, []);

    const fetchData = async () => {
        // setLoading(true);
        const url = `${SERVER_URL + "proj/projects"}`
        const payload = {
            // employee_id: 'PSV-00-000001',
            employee_id: tokenData?.id,
        };

        try {
            const response = await axios.post(url, payload, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            });
            setProject(response.data);
            setLoading(false);
            // console.log('Response data:', response.data);

        } catch (error) {
            setLoading(false);
            console.log('Error:', error.message);
            // Handle errors
        }
    };

    const fetchMyProject = async () => {
        // setLoading(true);
        const url = `${SERVER_URL + "proj/find-My-Project-list"}`
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                },
            });
            setMyProject(response?.data)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("Error: ", error)
        }
    }

    return (
        <ProjectContext.Provider value={{ project, myProject, loading, fetchData, fetchMyProject }}>
            {children}
        </ProjectContext.Provider>
    )
}

export function useProject() {
    return useContext(ProjectContext)
}