import React, { Component } from 'react'
import contactAnimationData from '../helper/AnimationEmpty.json'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: contactAnimationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        color: '#FF0000'
    }
};



export default class AnimationEmpty extends Component {
render() {
    return (
        // <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Lottie options={defaultOptions} width={"100%"} />
        // </div>
    )
}
}
