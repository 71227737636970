import React, { useState, useEffect } from 'react'
import { notification, Avatar, Button, List } from 'antd';
import VirtualList from 'rc-virtual-list';
import { UserOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'react-bootstrap'
import { SERVER_URL } from '../../api';
import { useTranslation } from 'react-i18next';
import { errorPermissionTitleWithTextSwal } from '../../helper/sweetalert';

export default function ViewMemberEvent({ show, handleClose, employees_member, onDeleteMember, handleShowDelete }) {

    const ContainerHeight = "70vh";
    const { t } = useTranslation()

    const onScroll = (e) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
            // fetchAssignmentData();
        }
    }

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                keyboard={false}
                centered
                animation={false}
                onHide={handleClose}>
                <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
                    <Modal.Title style={{ fontSize: 20 }}>{t("All Members")} ({employees_member?.length ?? "0"}) </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingLeft: 50, paddingRight: 50 }}>
                    {employees_member?.length <= 0 ?
                        <div style={{ height: "53vh", display: "flex", justifyContent: "center", alignItems: "center" }}>{t("No one has joined yet")}</div> :
                        <List>
                            <VirtualList
                                data={employees_member?.sort((a, b) => a?.name?.localeCompare(b.name))}
                                height={ContainerHeight}
                                // itemHeight={47}
                                itemKey="email"
                                onScroll={onScroll}
                            >
                                {(item, index) => (
                                    <List.Item key={item}>
                                        <List.Item.Meta
                                            avatar={<Avatar
                                                style={{
                                                    backgroundColor: '#87d068',
                                                }}
                                                icon={<UserOutlined />}
                                            />}
                                            title={<div>{item?.name} {item?.surname}</div>}
                                            description={
                                                <div>
                                                    <div>{item?.email}</div>
                                                    {/* <div>{item?.member_status === 0 ? t("User") : t("Admin")}</div> */}
                                                </div>
                                            }
                                        />
                                        <Button
                                            icon={<DeleteOutlined />}
                                            // disabled={project?.Project[0]?.create_project_by_id === item?.employee_id ? true : false}
                                            onClick={() => handleShowDelete(index)}>{t("delete")}</Button>
                                    </List.Item>
                                )}
                            </VirtualList>
                        </List>}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        icon={<CloseOutlined />}
                        variant="secondary"
                        onClick={handleClose}
                        style={{ width: 150, height: 40 }}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
