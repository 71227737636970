import React, { useState, useEffect } from 'react'
import { UserOutlined, CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Input, Modal, Button, Popover, Avatar, Typography, notification, List, Checkbox } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../../api';
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;


export default function Email({ show, handleClose, memberAssignHistory, members, assignmentId, selectEditData, setSelectEditData }) {

    // --------------------- useState ---------------------

    const { t } = useTranslation()
    const [filteredResults, setFilteredResults] = useState(members?.Members || []);
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState([{ employee_id: '' }])
    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [open, setOpen] = useState(false);
    const ContainerHeight = 400;
    const _checkSelected = (productId) => memberHistory?.some(({ emp_id }) => emp_id === productId)
    const memberHistory = memberAssignHistory?.results?.filter(name => name?.ass_id === assignmentId?.as_id)

    // --------------------- useEffect ---------------------

    useEffect(() => {
        if (members) setFilteredResults(members?.Members || [])
    }, [members])

    useEffect(() => {
        if (selectEditData) {
            setTitle(selectEditData?.title)
            setDescription('ໃຫ້ທ່ານກວດສອບເບີ່ງການອັບເດດ')
        }
        else {
            setTitle('')
            setDescription('')
        }
    }, [selectEditData])

    // --------------------- fuctions ---------------------

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const tokenString = getCookie('user');

    // Decode the URL-encoded string
    const decodedTokenString = decodeURIComponent(tokenString);

    const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

    const onScroll = (e) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
        }
    };

    const handleOpenChange = (visible) => {
        setOpen(visible);
    };

    const sendEmail = async () => {
        setLoading(true)
        const apiUrl = `${SERVER_URL + "sending-email/send-email"}`;
        const emailArray = email.filter((e) => e.employee_id !== "").map(item => item.employee_id)

        // Validate title and email
        if (!title?.trim()) {
            notification.error({
                message: t('Error'),
                description: t('Please enter subject'),
                duration: 2,
            });
            return;
        }

        if (emailArray.length === 0 || emailArray.some(email => email === "")) {
            notification.error({
                message: t('Error'),
                description: t('Please select email'),
                duration: 2,
            });
            return;
        }

        const requestData = {
            title: title,
            guests_email_arr: emailArray,
            descriptions: description
        };

        try {

            const apiResponse = await axios.post(apiUrl, requestData, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                },
            });

            notification.success({
                message: t('Success'),
                description: t('Email sent successfully'),
                duration: 2,
            });
            setLoading(false)
            handleClose()
            setTitle()
            setDescription()
            setEmail([])

        } catch (error) {
            console.log('Error making API request:', error);
            setLoading(false)
            notification.error({
                message: t('Error'),
                description: t('Unable to send email'),
                duration: 2,
            });
        }
    };

    const handleCheckboxChange = (employee) => {
        // Check if the employee is already in the selected employees list
        const isSelected = email.some((e) => e?.employee_id === employee.email);

        if (isSelected) {
            // If the employee is already selected, remove them
            const updatedEmployees = email.filter((e) => e?.employee_id !== employee.email);
            setEmail(updatedEmployees);
        } else {
            // If the employee is not selected, add them
            const updatedEmployees = [...email, { employee_id: employee.email }];
            setEmail(updatedEmployees);
        }
    };

    const handleDeleteEmail = (index) => {
        const updatedEmails = [...email];
        updatedEmails.splice(index, 1); // Remove the email at the specified index
        setEmail(updatedEmails);
    };

    const onClose = () => {
        handleClose()
    }

    return (
        <div>

            <Modal
                title={<div style={{ fontWeight: 400, fontSize: 20, paddingBottom: 20 }}>{t("New message")}</div>}
                centered
                open={show}
                onCancel={onClose}
                transitionName={false}
                // maskTransitionName={false}
                footer={[
                    <Button onClick={() => handleClose()}>{t('cancel')}</Button>,
                    <Button onClick={sendEmail}>{t("Send")}</Button>
                ]}
            >
                <div className='mb-5'>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <Popover
                                content={<div style={{ display: "flex", width: 420, height: "70vh", overflow: 'auto' }}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={filteredResults}
                                        height={ContainerHeight}
                                        itemHeight={47}
                                        onScroll={onScroll}
                                        renderItem={(item, index) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    onChange={() => handleCheckboxChange(item)}
                                                    avatar={
                                                        <div style={{ display: "flex", cursor: "pointer" }} >
                                                            <div style={{ display: "flex" }}>
                                                                <Checkbox
                                                                    style={{ paddingRight: 20 }}
                                                                    checked={_checkSelected(item?.employee_id) || email.some((p) => p.employee_id === item?.email)}
                                                                    onChange={() => handleCheckboxChange(item)} />
                                                            </div>
                                                            <Avatar onClick={() => handleCheckboxChange(item)}
                                                                style={{
                                                                    backgroundColor: '#87d068',
                                                                }}
                                                                icon={<UserOutlined />}
                                                            /></div>}
                                                    title={<div onClick={() => handleCheckboxChange(item)} style={{ cursor: "pointer", fontWeight: _checkSelected(item?.employee_id) ? 600 : "" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div>{item.name}</div>
                                                            <div style={{ paddingLeft: 10 }}>{item.surname}</div>

                                                        </div>
                                                    </div>}
                                                    // description={<div onClick={() => handleCheckboxChange(item)} >{item?.member_status === 0 ? "user" : "admin"}</div>}
                                                    description={<div onClick={() => handleCheckboxChange(item)} >{item?.email}</div>}
                                                />
                                            </List.Item>
                                        )}
                                    />

                                </div>}
                                // title="Title"
                                placement="right"
                                trigger="click"
                                open={open}
                                style={{ zIndex: 1000 }}
                                onOpenChange={handleOpenChange}
                            >
                                <Button>{t("TO")}</Button>
                            </Popover>
                        </div>
                        <div style={{ paddingLeft: 20, display: 'flex', flexDirection: "column" }}>
                            {email?.map((e, index) => (
                                e?.employee_id !== "" && (
                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <Button
                                            style={{ width: "auto", display: "flex", justifyContent: 'space-between', alignItems: "center" }}
                                        >
                                            {e?.employee_id}
                                            <CloseOutlined onClick={() => handleDeleteEmail(index)} style={{ fontSize: 15 }} />
                                        </Button>
                                        <div style={{ height: 10 }}></div>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>

                    <Typography.Title
                        style={{
                            fontFamily: 'Noto Sans Lao, sans-serif',
                            fontSize: 14,
                            fontWeight: 400,
                            marginTop: 20
                        }}  >{t("subject")}</Typography.Title>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} />

                    <Typography.Title
                        style={{
                            fontFamily: 'Noto Sans Lao, sans-serif',
                            fontSize: 14,
                            fontWeight: 400,
                            marginTop: 20
                        }}  >{t("detail")}</Typography.Title>

                    <TextArea
                        showCount
                        maxLength={500}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{
                            fontFamily: 'Noto Sans Lao, sans-serif',
                            height: 120,
                            resize: 'none',
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}
