import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, Navigate } from "react-router-dom"
import MainLayout from '../components/MainLayout';
import useWindowDimensions from '../helper/useWindowDimensions';
import Menus from '../components/Drawer';
import { useProject } from '../contexts/ProjectContext';
import LanguageSwitcher from '../components/LanguageSwitcher';

export default function PrivateRoute() {
    const navigate = useNavigate()
    const { height, width } = useWindowDimensions();
    const { fetchData } = useProject()

    return (
        <div>
            {width > 700 ?
                <div>
                    {/* <LanguageSwitcher /> */}
                    <MainLayout>
                        <Outlet />
                    </MainLayout>
                </div> :
                <>
                    <Menus />
                    <div style={{ paddingTop: 70 }}>
                        <Outlet />
                    </div>
                </>}
        </div>
    );
    // }
    // return <Navigate to={'/redirect'} />
}
