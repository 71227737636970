import Swal from 'sweetalert2'

export const warring = (onClick) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            onClick={onClick}
        }
    })
}

export const successSwal = (item) => {
    Swal.fire({
        icon: 'success',
        title: item,
        showConfirmButton: false,
        timer: 1800
    })
}
export const successUpdateSwal = (item) => {
    Swal.fire({
        icon: 'success',
        title: item,
        showConfirmButton: false,
        timer: 1800
    })
}
export const successDeleteSwal = (item) => {
    Swal.fire({
        icon: 'success',
        title: item,
        showConfirmButton: false,
        timer: 1800
    })
}
export const errorSwal = (err) => {
    Swal.fire({
        icon: 'error',
        text: err,
        showConfirmButton: false,
        timer: 3000
    })
}

export const errorDisconnect = (err,icons) => {
    Swal.fire({
        icon: icons,
        text: err,
        showConfirmButton: false,
        timer:5000
    })
}

export const errorConnect = (err) => {
    Swal.fire({
        icon: 'question',
        text: err,
        showConfirmButton: false,
        timer: 3000
    })
}

export const errorTitleWithTextSwal = (title, text) => {
    Swal.fire({
        icon: 'error',
        title,
        text,
        showConfirmButton: false,
        timer: 5000
    })
}

export const errorPermissionTitleWithTextSwal = (title, text) => {
    Swal.fire({
        icon: 'info',
        confirmButtonColor: "green",
        title,
        text,
        timer: 3000
    })
}

export const wariningWithTextAndConfirmButton = (title, detail, onConfirm) => {
    Swal.fire({
        title: title,
        text: detail,
        icon: 'success',
        timer: 5000
    }).then(async () => {
        try {
            await onConfirm()
        } catch (error) {
            errorSwal(error)
            // console.log("Error: ", error)
        }
    })
}
