import React, { useState, useEffect, useRef } from 'react'
import { Button, Input, Avatar, List, Typography } from 'antd';
import VirtualList from 'rc-virtual-list';
import { DeleteOutlined, SendOutlined, UserOutlined, FieldTimeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Spinner, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { tokenData } from '../../const';
const { TextArea } = Input;

const ContainerHeight = "auto";

const { Paragraph, Text } = Typography;

export default function ChatManagement({
    show,
    handleClose,
    comment,
    addComment,
    title,
    DeleteComment,
    loadingCreate,
    setTitle,
    fetchAssignmentData,
    project }) {

    // --------------------- useState ---------------------

    const { t } = useTranslation()
    const [errorTitle, setErrorTitle] = useState('');
    const modalBodyRef = useRef(null);

    // --------------------- fuctions ---------------------

    const onSave = async () => {

        if (!title) {
            setErrorTitle('Title  are required.'); // Set the error message
            return;
        }

        await addComment({ title });
        fetchAssignmentData()

        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = 0;
        }

    }

    // console.log("comment===============>", comment)

    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            centered
            animation={false}
            onHide={handleClose}
        >
            <Modal.Header style={{ display: "flex", justifyContent: "center" }} closeButton>
                <Modal.Title style={{ fontSize: 20 }}>{project?.Project?.[0]?.p_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body ref={modalBodyRef} style={{ paddingLeft: 30, paddingRight: 30, overflow: "auto" }}>
                <div style={{ height: "60vh" }} >
                    <div>
                        {comment?.length <= 0 ? <div style={{ marginBottom: 500, height: "53vh", display: "flex", justifyContent: "center", alignItems: "center" }}>{t("There are no comments yet")}</div> : <List>
                            <VirtualList data={comment} height={ContainerHeight} >
                                {(item) => (
                                    <List.Item key={item.email}>
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar style={{
                                                    display: tokenData?.id === item?.create_by_id ? "none " : "",
                                                    backgroundColor: '#87d068',
                                                    alignItems: "center"
                                                }}
                                                    icon={<UserOutlined />} />
                                            }
                                            title={
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: tokenData?.id === item?.create_by_id ? "flex-end" : 'flex-end', alignItems: tokenData?.id === item?.create_by_id ? "end" : "" }}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Avatar
                                                            style={{
                                                                display: tokenData?.id !== item?.create_by_id ? "none " : "",
                                                                backgroundColor: '#87d068',
                                                                alignItems: "center"
                                                            }}
                                                            icon={<UserOutlined />} />
                                                        <div style={{ paddingLeft: tokenData?.id === item?.create_by_id ? 10 : "" }}>{item?.create_by}</div>
                                                    </div>
                                                    <div style={{ color: "#757f7e", fontSize: 12 }}><FieldTimeOutlined />&ensp;{moment(item?.created_at).format("DD-MM-YYYY HH:mm A")}</div>
                                                </div>}
                                            description={<div style={{
                                                display: "flex",
                                                justifyContent: tokenData?.id === item?.create_by_id ? "end" : "space-between",
                                                alignItems: "center",
                                                paddingTop: 10
                                            }}>
                                                <div style={{
                                                    background: "#e3f9f7",
                                                    width: "70%",
                                                    height: "auto",
                                                    padding: 10,
                                                    borderRadius: 20,
                                                    color: "#68706f",
                                                }}>
                                                    <Paragraph style={{ fontFamily: 'Noto Sans Lao, sans-serif' }}>
                                                        {item?.comments}
                                                    </Paragraph>
                                                </div>
                                                <div style={{ cursor: "pointer" }} onClick={() => DeleteComment(item?.comment_id)}>
                                                    <DeleteOutlined style={{ fontSize: 20, color: "red" }} />
                                                </div>
                                            </div>}
                                        />
                                    </List.Item>
                                )}
                            </VirtualList>
                        </List>}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}>
                    <Input
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                            setErrorTitle('')
                        }}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                onSave()
                            }
                        }}
                        placeholder={t("Input Comment")}
                        style={{ fontFamily: 'Noto Sans Lao, sans-serif', height: 40 }}
                    />
                    &emsp;
                    <Button type="primary" style={{ height: 40, background: "#00842e" }} onClick={() => onSave()} icon={<SendOutlined />}>
                        {loadingCreate ? <Spinner size='sm' /> : t("Send")}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
