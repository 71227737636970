import React, { useEffect, useState } from 'react'
import { PlusOutlined, SyncOutlined, EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Button, Space, Table, Flex, notification, Skeleton } from 'antd';
import { useUserGroup } from '../../contexts/UserGroupContext';
import UserGroupDetail from './UserGroupDetail';
import ConfirmDelete from '../../components/ConfirmDelete';
import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../../api';
import { errorSwal } from '../../helper/sweetalert';
import axios from 'axios';
import { tokenData } from '../../const';
import { useNavigate } from 'react-router-dom';

export default function UserGroupList() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { UserGroup, setUserGroup, loading, fetchUserGroup } = useUserGroup()
    const [userGroupDetail, setUserGroupDetail] = useState(false)
    const [selectedData, setSelectedData] = useState([])
    const [Delete, setDelete] = useState("")
    const [showPopUpDelete, setShowPopUpDelete] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            title: t("No/"),
            dataIndex: 'number',
            key: 'number',
            render: (text) => <a>{text}</a>,
            ellipsis: true,
        },
        {
            title: t("Group Name"),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
            ellipsis: true,
        },
        {
            title: t("Number of people"),
            dataIndex: 'age',
            key: 'age',
            ellipsis: true,
        },
        {
            title: t("create By"),
            dataIndex: 'created_by',
            key: 'created_by',
            ellipsis: true,
        },
        {
            title: t("Management"),
            dataIndex: 'manage',
            key: 'manage',
            ellipsis: true,
        },
    ]

    const handleShowDelete = (id, name) => {
        setDelete({ name, id });
        setShowPopUpDelete(true);
    }

    const onUpdateUserGroup = (id) => {
        navigate("/update-user-group/" + id);
    }

    const data = UserGroup?.map((e, index) => ({
        id: e,
        number: index + 1,
        name: e?.group_name,
        age:
            <Button
                onClick={() => {
                    setUserGroupDetail(true)
                    setSelectedData(e)
                }}
                type="primary"
                icon={<EyeOutlined />}>
                {t("detail")}
            </Button>,
        created_by: e?.creator_name,
        manage: <Flex gap="small" wrap="wrap">
            <Button icon={<EditOutlined />} onClick={() => onUpdateUserGroup(e?.groupId)}>{t("edit")}</Button>
            <Button
                icon={<DeleteOutlined />}
                onClick={() => handleShowDelete(e?.groupId)}>
                {t("delete")}
            </Button>
        </Flex>
    }))

    const onHandleDeleteProject = () => {

        setIsLoading(true);
        // Send the updated data to the server
        const apiUrl = SERVER_URL + "group/delete/" + Delete?.id;
        axios.put(apiUrl, {
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
            },
        })
            .then((response) => {
                notification.success({
                    message: t('Success'),
                    description: t('delete success'),
                    duration: 2,
                });
                setIsLoading(false);
                setShowPopUpDelete(false);
                fetchUserGroup()
            })
            .catch((error) => {
                setShowPopUpDelete(false);
                errorSwal('Error');
                setIsLoading(false);
            });
    };

    // console.log("UserGroup===========>",UserGroup)

    return (
        <div style={{ marginTop: 20 }}>

            <Card
                // title="Card title"
                bordered={false}
                style={{
                    width: '100%',
                }}
            >
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: 20 }}>{t("Group List")}</div>
                    <Space>
                        <Button
                            onClick={() => fetchUserGroup()}
                            style={{ height: 40 }}
                            // type="primary"
                            icon={<SyncOutlined />}>
                            {t("Refresh")}
                        </Button>
                        <Button
                            onClick={() => navigate('/create-group')}
                            style={{ height: 40 }}
                            type="primary"
                            icon={<PlusOutlined />}>
                            {t("Create Group")}
                        </Button>
                    </Space>
                </div>
            </Card>

            <Skeleton
                loading={loading}
                paragraph={{
                    rows: 13,
                }}
                style={{ padding: 20 }}
                active Table>
                <div style={{ marginTop: 20 }}>
                    <Table
                        size='small'
                        columns={columns}
                        scroll={{ x: true }}
                        dataSource={data} />
                </div>
            </Skeleton>

            <UserGroupDetail
                isModalOpen={userGroupDetail}
                handleCancel={() => setUserGroupDetail(false)}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
            />

            <ConfirmDelete
                show={showPopUpDelete}
                handleClose={() => setShowPopUpDelete(false)}
                title={t('Do you want to Delete or not?')}
                isLoading={isLoading}
                onSubmit={onHandleDeleteProject}
            />



        </div>
    )
}
