import React, { useState, useEffect } from 'react'
import { notification, Avatar, Button, List, Skeleton } from 'antd';
import VirtualList from 'rc-virtual-list';
import { UserOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'react-bootstrap'
import { SERVER_URL } from '../../api';
import { useTranslation } from 'react-i18next';
import { onConfirmButton } from '../../const';
import { renderToString } from 'react-dom/server';

export default function ViewAllMember({ show, handleClose, project, fetchAssignmentData, id, fetchMemberProject }) {

    const { t } = useTranslation()
    const ContainerHeight = "70vh";
    const [showPopupDelete, setShowPopupDelete] = useState(false)
    const [Delete, setDelete] = useState("");

    const confirmButtonContent = renderToString(<div>{t("delete")}</div>);
    const cancelButtonContent = renderToString(<div>{t("cancel")}</div>);

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const tokenString = getCookie('user');

    // Decode the URL-encoded string
    const decodedTokenString = decodeURIComponent(tokenString);

    const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

    const handleDeleteMember = (memberId) => {
        // Make a DELETE request to delete the member
        fetch(`${SERVER_URL + `project-member/delete/${memberId}`}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    notification.success({
                        message: t('Success'),
                        description: t("delete success"),
                        duration: 2,
                    });
                    fetchAssignmentData()
                    fetchMemberProject()
                } else {
                    console.log('Failed to delete the member.');
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }

    const handleShowDelete = (id, name) => {
        setDelete({ name, id });
        onConfirmButton(t('Do you want to Delete or not?'), confirmButtonContent, cancelButtonContent, () => handleDeleteMember(id))
    };

    const onScroll = (e) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
            fetchAssignmentData()
        }
    }

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                keyboard={false}
                centered
                animation={false}
                onHide={handleClose}>
                <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
                    <Modal.Title style={{ fontSize: 20 }}>{t("All Members")} ({project?.Members?.length})</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingLeft: 50, paddingRight: 50 }}>
                    {project?.Members?.length <= 0 ? <div style={{ height: "53vh", display: "flex", justifyContent: "center", alignItems: "center" }}>{t("No one has joined yet")}</div> :
                        <div
                            id="scrollableDiv"
                            style={{
                                height: "68vh",
                                overflow: 'auto',
                            }}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={project?.Members?.sort((a, b) => a?.name?.localeCompare(b?.name))}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#87d068',
                                                    }}
                                                    icon={<UserOutlined />}
                                                />
                                            }
                                            title={<div>{item?.name}&ensp;{item?.surname}</div>}
                                            description={<div>
                                                <div>{item?.email}</div>
                                                <div>{item?.member_status === 0 ? t("User") : t("Admin")}</div>
                                            </div>}
                                        />
                                        <Button
                                            icon={<DeleteOutlined />}
                                            disabled={project?.Project[0]?.create_project_by_id === item?.employee_id ? true : false}
                                            onClick={() => handleShowDelete(item?.member_id)}>{t("delete")}</Button>
                                    </List.Item>
                                )}
                            />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        icon={<CloseOutlined />}
                        variant="secondary"
                        onClick={handleClose} style={{ width: 150, height: 40 }}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
