import React, { useState, useEffect } from 'react'
import { Spinner } from "react-bootstrap"
import { UserOutlined, CheckCircleOutlined, SearchOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Input, Checkbox, Select, Avatar, List } from 'antd';
import { useMember } from '../../contexts/MemberContext'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { SERVER_URL } from '../../api';
import { tokenData } from '../../const';
import { useUserGroup } from '../../contexts/UserGroupContext';
import { Await } from 'react-router-dom';
import useWindowDimensions from '../../helper/useWindowDimensions';
import { errorSwal } from '../../helper/sweetalert';

export default function MemberList({
    show,
    fetchAssignmentData,
    id,
    project,
    fetchMemberProject,
    handleClose }) {

    // --------------------- useState ---------------------

    const { width } = useWindowDimensions()
    const { t } = useTranslation()
    const { UserGroup, setUserGroup, fetchUserGroup } = useUserGroup()
    const { members } = useMember()
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState(members?.results || []);
    const [loading, setLoading] = useState(false)
    const [employees_member, setEmployeesMember] = useState([
        {
            employee_id: '',
            member_status: '',
        },
    ]);
    const ContainerHeight = 400;
    const [selectUserGroup, setSelectUserGroup] = useState(null)

    const _checkSelected = (productId) => project?.Members?.some(({ employee_id }) => employee_id === productId)

    // --------------------- useEffect ---------------------

    useEffect(() => {
        if (members) setFilteredResults(members?.results || [])
    }, [members])

    useEffect(() => {
        if (!searchQuery) {
            setFilteredResults(members?.results || [])
        }
        const newFilteredResults = members?.results?.filter((item) =>
            item?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    }, [searchQuery])

    useEffect(() => {
        if (selectUserGroup) {
            const employeeIds = selectUserGroup?.employees?.map(item => item?.employee_id);
            const filtered = members?.results?.filter(item => employeeIds?.some(id => id === item?.employee_id));
            setFilteredResults(filtered);
        }
        else {
            setFilteredResults(members?.results || [])
        }
    }, [selectUserGroup]);

    // --------------------- fuctions ---------------------

    const handleChange = (value, index) => {
        // Clone the selected employee object
        const selectedEmployee = { ...employees_member[index] };

        // Update the member_status property with the new value
        selectedEmployee.member_status = value;

        // Clone the employees_member array
        const updatedEmployees = [...employees_member];

        // Update the selected employee in the cloned array
        updatedEmployees[index] = selectedEmployee;

        // Update the state with the modified array
        setEmployeesMember(updatedEmployees);
    };


    const handleSearch = () => {
        const newFilteredResults = members?.results?.filter((item) =>
            item?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    };

    const handleCheckboxChange = (employee) => {
        const isSelected = employees_member.some((e) => e?.employee_id === employee?.employee_id);

        if (isSelected) {
            // If the employee is already selected, remove them
            const updatedEmployees = employees_member.filter((e) => e?.employee_id !== employee?.employee_id);
            setEmployeesMember(updatedEmployees);
        } else {
            // If the employee is not selected, add them
            const updatedEmployees = [

                ...employees_member, { employee_id: employee?.employee_id, member_status: '0' },
            ];
            setEmployeesMember(updatedEmployees);
        }
    };

    const handleAddMembers = () => {
        setLoading(true)

        // Transform and filter the employees_member array
        const transformedEmployeesMember = employees_member
            .map((e) => {
                if (e?.employee_id && e?.member_status) {
                    return {
                        employee_id: e?.employee_id,
                        member_status: e?.member_status,
                    };
                }
                return null; // Exclude elements with missing data
            })
            .filter((e) => e !== null);

        if (transformedEmployeesMember.length === 0) {
            // Show an error message when there's no valid data to send
            notification.error({
                message: t("Error"),
                description: t("Please select an employee"),
                duration: 2,
            });
            setLoading(false);
            return;
        }

        const data = {
            project_id: id,
            employees_member: transformedEmployeesMember
        };

        if (loading) return

        fetch(`${SERVER_URL + "project-member/insert"}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${tokenData?.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    notification.success({
                        message: t('Success'),
                        description: t('save success'),
                        duration: 2,
                    });
                    fetchMemberProject()
                    fetchAssignmentData()
                    setEmployeesMember([])
                    handleClose()
                    setLoading(false)
                } else {
                    response.json().then((errorResponse) => {
                        if (response.status === 409) {
                            const conflictMessage = errorResponse.message;
                            if (conflictMessage === "Conflict") {
                                // Handle conflict error, maybe display a message to the user
                                errorSwal(t('Sorry, this name has already been added as a member'));
                            } else {
                                console.log('Conflict with unknown message:', conflictMessage);
                            }
                        } else {
                            console.log('Failed to add members to the project.');
                        }
                    });
                    setLoading(false)
                    console.log('Failed to add members to the project.');
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                setLoading(false)
            });
    };

    const closeModal = () => {
        handleClose()
        setEmployeesMember([])
    }

    const handleSelectUserGroup = async (userGroupData) => {
        setSelectUserGroup(userGroupData)
    }

    // console.log("===========>selectUserGroup", selectUserGroup)
    // console.log("===========>filteredResults", filteredResults)

    return (
        <Modal
            centered
            title={t("Add Member")}
            open={show}
            onOk={handleAddMembers}
            okText={loading ? <Spinner size='sm' /> : t("save")}
            cancelText={t("cancel")}
            footer={null} // Disable the default footer
            transitionName={false}
            // maskTransitionName={false}
            width={width < 700 ? '100%' : "50%"}
            onCancel={closeModal}>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Button
                    style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        background: selectUserGroup === null ? 'green' : '',
                        color: selectUserGroup === null ? 'white' : '',
                    }}
                    onClick={() => handleSelectUserGroup(null)} // Reset selectUserGroup when the "All" button is clicked
                >
                    ທັງໝົດ
                </Button>
                {UserGroup?.map((e, index) => (
                    <Button
                        onClick={() => {
                            handleSelectUserGroup(e)
                        }}
                        key={index}
                        style={{
                            marginRight: "10px",
                            marginBottom: "10px",
                            background: selectUserGroup === e ? 'green' : '',
                            color: selectUserGroup === e ? 'white' : '',
                        }}>
                        {e?.group_name}
                    </Button>
                ))}
            </div>

            <div style={{ display: "flex", marginTop: 20 }}>
                <div style={{ width: "100%" }}>
                    <Input
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                        prefix={<SearchOutlined />}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        placeholder={t("Enter the employee's name")}
                        style={{ height: 40 }}
                    />
                </div>
                {/* <div style={{ width: "20%", marginLeft: 20 }}>
                    <Button type="primary w-100" style={{ background: "#00842e" }} onClick={() => handleSearch()}>ຄົ້ນຫາ</Button>
                </div> */}
            </div>

            <div style={{ overflow: "auto", height: "60vh" }}>

                <div style={{ height: "50px", marginTop: 20 }}>
                    {filteredResults?.length === 0 ? (
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 16
                        }}>
                            ບໍ່ມີຂໍ້ມູນທີ່ທ່ານຄົ້ນຫາ !
                        </div>
                    ) : (
                        <div>
                            <List
                                itemLayout="horizontal"
                                dataSource={filteredResults?.sort((a, b) => a.name.localeCompare(b.name))}
                                height={ContainerHeight}
                                itemHeight={47}
                                renderItem={(item) => (
                                    <List.Item key={item.employee_id} style={{ background: _checkSelected(item?.employee_id) || tokenData?.id === item?.employee_id ? "#e3f9f7" : "" }}>
                                        <List.Item.Meta
                                            avatar={
                                                <div style={{ display: "flex", cursor: "pointer" }}>
                                                    <div style={{ display: "flex" }}>
                                                        {_checkSelected(item?.employee_id) || tokenData?.id === item?.employee_id ? (
                                                            <CheckCircleOutlined style={{ fontSize: 20, color: "green", paddingRight: 20 }} />
                                                        ) : (
                                                            <Checkbox
                                                                style={{ paddingRight: 20 }}
                                                                checked={_checkSelected(item?.employee_id) || employees_member.some((p) => p.employee_id === item?.employee_id) || tokenData?.id === item?.employee_id}
                                                                onChange={() => handleCheckboxChange(item)}
                                                            />
                                                        )}
                                                    </div>
                                                    <Avatar
                                                        onClick={() => tokenData?.id === item?.employee_id ? "" : handleCheckboxChange(item)}
                                                        style={{
                                                            backgroundColor: '#87d068',
                                                        }}
                                                        icon={<UserOutlined />}
                                                    />
                                                </div>
                                            }
                                            title={
                                                <div onClick={() => tokenData?.id === item?.employee_id ? "" : handleCheckboxChange(item)} style={{ cursor: "pointer", fontWeight: _checkSelected(item?.employee_id) || tokenData?.id === item?.employee_id ? 600 : "" }}>
                                                    {item?.name} {item?.surname}
                                                </div>
                                            }
                                            description={<div onClick={() => tokenData?.id === item?.employee_id ? "" : handleCheckboxChange(item)}>{item?.email}</div>}
                                        />
                                        {_checkSelected(item?.employee_id) ? null : (
                                            <div>
                                                {employees_member?.map((employee, index) => (
                                                    <div key={index}>
                                                        {/* Other employee information */}
                                                        {employee?.employee_id === item?.employee_id && (
                                                            <Select
                                                                defaultValue={'0'}
                                                                style={{ width: 120 }}
                                                                onChange={(value) => handleChange(value, index)}
                                                                options={[
                                                                    {
                                                                        value: '0',
                                                                        label: 'User',
                                                                    },
                                                                    {
                                                                        value: '1',
                                                                        label: 'Admin',
                                                                    },

                                                                ]}
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </List.Item>
                                )}
                            />

                        </div>
                    )}

                </div>

            </div>

            <div style={{ display: "flex", justifyContent: "center", paddingTop: 20 }}>
                <Button icon={<CloseOutlined />} style={{ height: 40 }} onClick={closeModal}>{t("cancel")}</Button>&emsp;
                <Button
                    loading={loading}
                    icon={<CheckOutlined />}
                    type="primary"
                    style={{ background: "#00842e", height: 40 }}
                    onClick={handleAddMembers}>
                    {t("save")}
                </Button>
            </div>

        </Modal>
    )
}
