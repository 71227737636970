import React, { useState, useEffect } from 'react'
import { useCalendar } from '../../contexts/CalendarContext';
import { CalendarOutlined } from '@ant-design/icons'
import { Tabs, Avatar } from "antd";
import { useTranslation } from 'react-i18next';
import Calendar from './Calendar';
import Home from './Home';
import AllProjectList from './AllProjectList';

export default function HomeScreen() {

    const { t } = useTranslation();
    const { fetchEvent } = useCalendar()
    const [activeKey, setActiveKey] = useState(localStorage.getItem('activeCustomer') || 'home');

    useEffect(() => {
        localStorage.setItem('activeCustomer', activeKey);
        // fetchEvent()
    }, [activeKey]);

    const handleTabSelect = (key) => {
        setActiveKey(key);
        fetchEvent()
    };

    return (
        <div style={{ marginTop: 20 }}>
            <Tabs

                onChange={handleTabSelect}
                activeKey={activeKey}
                type="card"
                defaultActiveKey='home'
                items={[
                    {
                        label: t('Project List'),
                        key: 'home',
                        children: <Home />
                    },
                    {
                        label: t('All Project'),
                        key: '/all-project-list',
                        children: <AllProjectList />
                    },
                    {
                        icon: <CalendarOutlined />,
                        label: t('Calendar'),
                        key: 'Calendar',
                        children: <Calendar />,
                    }
                ]}
            />
        </div>
    )
}
