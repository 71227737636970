import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import { tokenData } from '../const';

const DepartmentConext = createContext()

export default function DepartmentProvider({ children }) {

    const [department, setDepartment] = useState([])

    useEffect(() => {
        if (!tokenData?.token) return
        fetchDepartMent()
    }, []);

    const fetchDepartMent = () => {
        try {
            fetch(`${SERVER_URL + "proj/departments"}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setDepartment(data)
                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                });
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    return (
        <DepartmentConext.Provider value={{ department }}>
            {children}
        </DepartmentConext.Provider>
    )
}


export function useDepartment() {
    return useContext(DepartmentConext)
}
