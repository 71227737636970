import React, { useState, useEffect } from 'react';
import { CloseOutlined, SearchOutlined, UserOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Input, List, Avatar, Checkbox } from 'antd';
import { useMember } from '../../contexts/MemberContext';
import useWindowDimensions from '../../helper/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { useUserGroup } from '../../contexts/UserGroupContext';
import { useOutMeeting } from '../../contexts/OutMeetingContext';

export default function AddmemberCalendar({ isModalOpen, handleCancel }) {

    const { members } = useMember()
    const { employees_member, setEmployeesMember } = useOutMeeting()
    const { UserGroup } = useUserGroup()
    const { width } = useWindowDimensions()
    const { t } = useTranslation()
    const [filteredResults, setFilteredResults] = useState(members?.results || []);
    const [selectUserGroup, setSelectUserGroup] = useState(null)
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // Initialize employees_member as an empty array if it's undefined
        if (!Array.isArray(employees_member)) {
            setEmployeesMember([]);
            return;
        }
    }, [employees_member])

    useEffect(() => {
        if (members) setFilteredResults(members?.results || [])
    }, [members])

    useEffect(() => {
        if (!searchQuery) {
            setFilteredResults(members?.results || [])
        }
        const newFilteredResults = members?.results?.filter((item) =>
            item?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    }, [searchQuery])

    useEffect(() => {
        if (selectUserGroup) {
            const employeeIds = selectUserGroup?.employees?.map(item => item?.employee_id);
            const filtered = members?.results?.filter(item => employeeIds?.some(id => id === item?.employee_id));
            setFilteredResults(filtered);
        }
        else {
            setFilteredResults(members?.results || [])
        }
    }, [selectUserGroup]);

    const handleCheckboxChange = (employee) => {

        // Check if the employee is already in the selected employees list
        const isSelected = employees_member?.some((e) => e?.value === employee?.employee_id);

        if (isSelected) {
            // If the employee is already selected, remove them
            const updatedEmployees = employees_member?.filter((e) => e?.value !== employee?.employee_id);
            setEmployeesMember(updatedEmployees);
        } else {
            // If the employee is not selected, add them
            const updatedEmployees = [...employees_member,
            {
                value: employee.employee_id,
                name: employee?.name,
            }
            ];
            setEmployeesMember(updatedEmployees);
        }
    };

    const ContainerHeight = 400;

    const handleSelectUserGroup = async (userGroupData) => {
        setSelectUserGroup(userGroupData)
    }

    return (
        <Modal
            centered
            title={t("Add Member")}
            open={isModalOpen}
            footer={null} 
            transitionName={false}
            width={width < 700 ? '100%' : "50%"}
            onCancel={handleCancel}>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Button
                    style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        background: selectUserGroup === null ? 'green' : '',
                        color: selectUserGroup === null ? 'white' : '',
                    }}
                    onClick={() => handleSelectUserGroup(null)} // Reset selectUserGroup when the "All" button is clicked
                >
                    ທັງໝົດ
                </Button>
                {UserGroup?.map((e, index) => (
                    <Button
                        onClick={() => {
                            handleSelectUserGroup(e)
                        }}
                        key={index}
                        style={{
                            marginRight: "10px",
                            marginBottom: "10px",
                            background: selectUserGroup === e ? 'green' : '',
                            color: selectUserGroup === e ? 'white' : '',
                        }}>
                        {e?.group_name}
                    </Button>
                ))}
            </div>

            <div style={{ display: "flex", marginTop: 20 }}>
                <div style={{ width: "100%" }}>
                    <Input
                        prefix={<SearchOutlined />}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        placeholder={t("Enter the employee's name")}
                        style={{ height: 40 }}
                    />
                </div>

                {/* <div style={{ width: "20%", marginLeft: 20 }}>
                <Button type="primary w-100" style={{ background: "#00842e" }} onClick={() => handleSearch()}>ຄົ້ນຫາ</Button>
            </div> */}

            </div>

            <div style={{ overflow: "auto", height: "60vh" }}>

                <div style={{ height: "50px", marginTop: 20 }}>
                    {filteredResults?.length === 0 ? (
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 16
                        }}>
                            ບໍ່ມີຂໍ້ມູນທີ່ທ່ານຄົ້ນຫາ !
                        </div>
                    ) : (
                        <div>
                            <List
                                itemLayout="horizontal"
                                dataSource={filteredResults?.sort((a, b) => a.name.localeCompare(b.name))}
                                height={ContainerHeight}
                                itemHeight={47}
                                renderItem={(item) => (
                                    <List.Item key={item.employee_id}
                                    // style={{ background: _checkSelected(item?.employee_id) ? "#e3f9f7" : "" }}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <div style={{ display: "flex", cursor: "pointer" }}>
                                                    <div style={{ display: "flex" }}>
                                                        <Checkbox
                                                            style={{ paddingRight: 20 }}
                                                            checked={employees_member?.some((p) => p.value === item?.employee_id)}
                                                            onChange={() => handleCheckboxChange(item)}
                                                        />
                                                    </div>
                                                    <Avatar
                                                        onClick={() => handleCheckboxChange(item)}
                                                        style={{
                                                            backgroundColor: '#87d068',
                                                        }}
                                                        icon={<UserOutlined />}
                                                    />
                                                </div>
                                            }
                                            title={
                                                <div
                                                    onClick={() => handleCheckboxChange(item)}
                                                    style={{
                                                        cursor: "pointer",
                                                        // fontWeight: _checkSelected(item?.employee_id) ? 600 : ""
                                                    }}>
                                                    {item.name} {item.surname}
                                                </div>
                                            }
                                            description={<div
                                            //  onClick={() => handleCheckboxChange(item)}
                                            >{item?.email}</div>}
                                        />

                                    </List.Item>
                                )}
                            />

                        </div>
                    )}

                </div>

            </div>

            <div style={{ display: "flex", justifyContent: "center", paddingTop: 20 }}>
                <Button
                    icon={<CloseOutlined />}
                    style={{ height: 40 }}
                    onClick={handleCancel}>{t("cancel")}</Button>&emsp;
                <Button
                    icon={<CheckOutlined />}
                    onClick={() => handleCancel()}
                    type="primary"
                    style={{ background: "#00842e", height: 40 }}>
                    {t("save")}
                </Button>
            </div>

        </Modal>
    )
}
