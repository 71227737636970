import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Modal, List, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

export default function UserGroupDetail({
    isModalOpen,
    handleCancel,
    selectedData
}) {

    const { t } = useTranslation()
    // console.log("userGroupDetail===========>",selectedData)

    return (
        <Modal
            title={t("detail")}
            open={isModalOpen}
            // onOk={handleOk}
            centered
            footer={[
                // <Button key="cancel" onClick={handleCancel}>ຍົກເລີກ</Button>,
                // <Button key="submit" type="primary" disabled={shouldDisableButton}>ບັນທຶກ</Button>
            ]}
            onCancel={handleCancel}>
            <div>
                <List
                    // pagination={{
                    //     position,
                    //     align,
                    // }}
                    dataSource={selectedData?.employees}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar icon={<UserOutlined size={40} />} />}
                                title={item?.employee_name}
                                // description={item?.employee_id}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </Modal>
    )
}
