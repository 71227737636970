import React, { useState } from 'react'
import { Form, InputGroup, Spinner } from 'react-bootstrap'
import CustomButton from '../../components/CustomButton';
import { errorSwal } from '../../helper/sweetalert';
import { useNavigate } from 'react-router-dom';
import { FaEyeSlash } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { CgLock } from 'react-icons/cg'
import { VERSION } from '../../const';
import { FaEye } from 'react-icons/fa'
import './Login.css'

export default function Login() {

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [eye, setEye] = useState(true);
    const [valid, setValid] = useState(false);

    // --------------------- Fuctions ---------------------

    const handleButtonClick = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            // navigate('/');
            errorSwal('ກາລຸນາປ້ອນ ອີເມວ ເເລະ ລະຫັດຜ່ານ')
        }, 2000);

    };

    return (
        <React.Fragment>
            <div className='login-main'>
                <div className='login-card'>
                    <div className='login-content'>
                        <div> <img src='assets/images/logo.png' className='login-logo' /> </div>
                        <div className='login-title'>Project Management</div>
                        <div style={{ width: "100%" }}>
                            <Form.Label style={{ fontSize: 14, borderRight: "none" }}>Email</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text style={{ background: "#FFFFFF" }}><MdEmail /></InputGroup.Text>
                                <Form.Control type={"text"} placeholder="email..." style={{ borderLeft: "none" }} />
                            </InputGroup>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Form.Label style={{ fontSize: 14 }}>Password</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text style={{ borderRight: "none", background: "#FFFFFF" }}> <CgLock /> </InputGroup.Text>
                                <Form.Control
                                    type={eye ? "password" : "text"}
                                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                    style={{ borderLeft: "none" }}
                                />
                                <InputGroup.Text style={{ cursor: "pointer" }} onClick={() => setEye((prev) => !prev)}>
                                    {eye ? <FaEyeSlash /> : <FaEye />}
                                </InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div>
                            <CustomButton
                                title={isLoading ? <Spinner size='sm' /> : 'Login'}
                                marginTop={20}
                                onclick={handleButtonClick}
                            />
                        </div>
                        <div className='login-version'>{VERSION}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

