import React, { useState, useEffect } from 'react';
import { CalendarOutlined, DownOutlined, FieldTimeOutlined, MessageFilled, ReloadOutlined, BellOutlined, MenuOutlined, HomeOutlined, CloseOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import '../App.css'
import { Layout, Menu, Button, theme, Dropdown, Space, Drawer, List, Badge, Popover, Tabs, Avatar, } from 'antd';
import AnimationEmpty from "./AnimationEmpty";
import { useDeadline } from '../contexts/DeadlineContext';
import AnimationLoading from './AnimationLoading';
import { useProject } from '../contexts/ProjectContext';

export default function Menus() {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [language, setLanguage] = useState(localStorage.getItem('locale'));
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(localStorage.getItem('selectedKey') || 'management');
    const [isOnline, setIsOnline] = useState(true);
    const [isSlow, setIsSlow] = useState(false);
    const { notification, deadline, loading, fetchData, fetchNotification } = useDeadline()
    const { project, fetchMyProject, fetchData: fetchAllProject } = useProject()
    const ContainerHeight = 400;

    // Filter items by status
    const itemsWithStatus1 = notification?.results?.filter(item => item.status === 1);
    const itemsWithStatus0 = notification?.results?.filter(item => item.status === 0);

    // Concatenate the two arrays with itemsWithStatus1 on top
    const sortedItems = itemsWithStatus1?.concat(itemsWithStatus0);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    const items = [
        getItem(t('Home'), 'home', <HomeOutlined />),
        getItem(t('Work tracking management'), '/', <CalendarOutlined />),
        getItem(t('User Group'), '/user-group-list', <CalendarOutlined />),
    ];

    useEffect(() => {
        localStorage.getItem('locale')
    }, [language])

    const projectName = (value) => {
        // Find the project with the matching ID
        const projects = project?.result?.find(item => item?.project_id === value);

        // console.log("projects=========>", projects)
        // If project is found, return its name, otherwise return null or an empty string
        return projects ? projects?.p_name : null; // You can change null to '' if you prefer an empty string
    }

    const _changeLanguage = (locale) => {
        const { pathname, search } = location;
        const newLocation = `${pathname}${search}`;
        // Save the selected locale to localStorage
        localStorage.setItem('locale', locale);
        navigate(newLocation, { state: { locale } });
        i18n.changeLanguage(locale);
        setLanguage(locale);
    };


    const content = (
        <div style={{ width: "100%", height: "auto" }}>
            <Tabs
                // onChange={onChange}
                type="card"
                items={[
                    {
                        label: t("General"),
                        key: 1,
                        children:
                            <div style={{
                                height: "55vh",
                                overflow: "auto"
                            }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={sortedItems}
                                    style={{ width: 400, paddingLeft: 20, paddingRight: 20 }}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            onClick={() => onDetailProject(item?.project_id)}>
                                            <List.Item.Meta
                                                style={{ background: item?.status === 0 ? "" : "#EEF0F6", cursor: "pointer", padding: 10 }}
                                                avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                                title={<div style={{ color: "#737489", fontWeight: "bold" }}>{item.message}</div>}
                                                description={
                                                    <div >
                                                        <div style={{ fontWeight: "initial", color: "#737489" }}>{t("Project Name Noti")} :  {projectName(item?.project_id)}</div>
                                                        <div>{t("Time")} : {moment(item?.created_at)?.format("YYYY-MM-DD HH:mm")}</div>
                                                        <div><MessageFilled style={{ color: item?.status === 0 ? "" : "green" }} /> : {item?.status === 0 ? t("Already read") : t("Haven't read it yet.")}</div>
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>
                    },
                    {
                        label: t("DeadLine Project"),
                        key: 2,
                        children:
                            <div
                                style={{
                                    height: "55vh",
                                    overflow: "auto"
                                }}
                            >
                                <List
                                    dataSource={deadline?.results?.slice(0, 5)}
                                    style={{ width: 400, paddingLeft: 20, paddingRight: 20 }}
                                    renderItem={(item) => {
                                        // Calculate the remaining days
                                        const deadlineDate = moment(item?.deadline, "YYYY-MM-DD");
                                        const currentDate = moment();
                                        const remainingDays = deadlineDate?.diff(currentDate, 'days');

                                        return (
                                            <List.Item key={item.email}>
                                                <List.Item.Meta
                                                    avatar={<Avatar icon={<FieldTimeOutlined />} />}
                                                    title={<a onClick={() => onDetailProject(item?.project_id)}>{item?.p_name}</a>}
                                                    description={
                                                        <div>
                                                            {t("deadline")} {moment(item?.deadline).format("DD-MM-YYYY")}
                                                        </div>
                                                    }
                                                />
                                                {/* <div style={{ color: "red", display: "flex", flexDirection: "column" }}>ກາຍໄປ&emsp;{`${remainingDays} `} {t("Day")}</div> */}
                                                <div style={{ color: "red", display: "flex", flexDirection: "column" }}>{`${remainingDays} `} {t("Day")}</div>
                                            </List.Item>
                                        );
                                    }}
                                />,
                            </div>
                    }
                ]}
            />

            {/* {
            deadline?.results?.length > 5 ? <div style={{ textAlign: "center" }}>
              <Button>
                ເບີ່ງທັງໝົດ
                <CaretDownOutlined />
              </Button>
            </div> : ""
          } */}

        </div>
    );

    const onDetailProject = (id) => {
        navigate("/management/" + id);
    }

    const ErrorMessage = () => (
        <div style={{
            width: "100%",
            height: '100vh',
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            fontSize: 20
        }}>
            <div>
                <AnimationEmpty />
            </div>
            <div>{t('no internet')}</div>
        </div>
    );

    const checkInternetConnection = () => {
        setIsOnline(navigator.onLine);
    };

    const checkInternetSpeed = () => {
        const image = new Image();
        image.src = 'https://www.your-website.com/some-image.jpg'; // Replace with an actual image URL from your website.

        const timeout = setTimeout(() => {
            setIsSlow(true);
            image.src = '';
        }, 5000); // Adjust the timeout as needed.

        image.onload = image.onerror = () => {
            clearTimeout(timeout);
            setIsSlow(false);
        };
    };

    useEffect(() => {
        checkInternetConnection();
        checkInternetSpeed();

        window.addEventListener('online', checkInternetConnection);
        window.addEventListener('offline', checkInternetConnection);

        return () => {
            window.removeEventListener('online', checkInternetConnection);
            window.removeEventListener('offline', checkInternetConnection);
        };
    }, []);

    if (!isOnline || isSlow) {
        return <ErrorMessage />;
    }

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#001529",
                height: 60,
                width: "100%",
                paddingLeft: 20,
                paddingRight: 20,
                position: "fixed",
                top: 0,
                zIndex: 1000,
            }} >
                <div onClick={showDrawer} style={{}}>
                    <MenuOutlined style={{ fontSize: 20, color: "#FFFFFF", paddingTop: 5 }} />
                </div>
                <div style={{ fontSize: 20, color: "#FFFFFF" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ cursor: "pointer", paddingRight: 20 }}>
                            <Popover
                                transitionName={false}
                                content={content} title={
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <div style={{ fontSize: 16 }}>{t("Notification")}</div>
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    fetchData()
                                                    fetchNotification()
                                                    fetchAllProject()
                                                    fetchMyProject()
                                                }}
                                                icon={<ReloadOutlined />}>
                                                {t("Refresh")}
                                            </Button>
                                        </div>
                                    </div>
                                }>
                                <Badge count={deadline?.results?.length}>
                                    <BellOutlined style={{ fontSize: 20, color: "green" }} />
                                </Badge>
                            </Popover>
                        </div>
                        <div>
                            <Dropdown
                                transitionName={false}
                                overlay={
                                    <div style={{
                                        background: "#FFFFFF",
                                        width: 200,
                                        height: "auto",
                                        padding: 20,
                                        boxShadow: '1px 2px 9px #F4AAB9',
                                    }}>
                                        <div
                                            onClick={() => _changeLanguage('LA')}
                                            style={{
                                                cursor: "pointer",
                                                background: language === "LA" ? "#e6f4ff" : "",
                                                color: language === "LA" ? "green" : "",
                                                fontWeight: language === "LA" ? "400" : "",
                                            }}>
                                            <img
                                                src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Laos.svg/2560px-Flag_of_Laos.svg.png'}
                                                alt=""
                                                style={{ width: 24, height: 16 }}
                                            />
                                            &ensp;
                                            {'LAO'}
                                        </div>
                                        <div
                                            onClick={() => _changeLanguage('EN')}
                                            style={{
                                                cursor: "pointer",
                                                marginTop: 20,
                                                background: language === "EN" ? "#e6f4ff" : "",
                                                color: language === "EN" ? "green" : "",
                                                fontWeight: language === "EN" ? "400" : "",
                                            }}>
                                            <img
                                                src={'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/2880px-Flag_of_the_United_Kingdom_%281-2%29.svg.png'}
                                                alt=""
                                                style={{ width: 24, height: 16 }}
                                            />
                                            &ensp;
                                            {'ENGLISH'}
                                        </div>
                                        {/* ))} */}
                                    </div>
                                }
                            >
                                <div onClick={(e) => e.preventDefault()} style={{ fontWeight: 400, color: "#FFFFFF", fontSize: 16 }}>
                                    <Space>
                                        <img
                                            src={language === "EN" ? "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/2880px-Flag_of_the_United_Kingdom_%281-2%29.svg.png" :
                                                "https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Laos.svg/2560px-Flag_of_Laos.svg.png"}
                                            alt=""
                                            style={{ width: 24, height: 16 }}
                                            width={600}
                                            height={900}
                                        />
                                        {language}
                                        <DownOutlined />
                                    </Space>
                                </div>
                            </Dropdown>
                        </div>
                    </div>

                </div>
            </div>
            <Drawer
                title={<div style={{ color: "#001529" }}>P</div>}
                placement="left"
                onClose={onClose}
                closable={false}
                bodyStyle={{ background: "#001529" }}
                headerStyle={{ background: "#001529" }}
                open={open}
                extra={
                    <Space>
                        <div onClick={onClose}>
                            {/* <RightOutlined color='#FFFFFF' style={{ fontSize: 25, color: "#FFFFFF" }} /> */}
                            <CloseOutlined style={{ fontSize: 20, color: "#FFFFFF", paddingTop: 5 }} />
                        </div>
                    </Space>
                }
                width={'70%'}>
                <Layout
                    style={{
                        // minHeight: '100vh',
                        width: "100%"
                    }}
                >
                    <Menu
                        theme="dark"
                        onClick={({ key }) => {
                            if (key == "home") {
                                window.location.href = 'https://psvsystem.com';
                            } else {
                                navigate(key);
                                setSelectedKey(key)
                            }
                        }}
                        defaultSelectedKeys={[selectedKey]}
                        onSelect={({ key }) => setSelectedKey(key)}
                        mode="inline"
                        items={items}
                        style={{ height: "100vh", overflow: "hidden" }}
                    />
                </Layout >
            </Drawer>
        </>
    )
}
