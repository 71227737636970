import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import { tokenData } from '../const';

const ManagementContext = createContext()

export default function ManagementProvider({ children }) {

    const [management, setManagement] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!tokenData?.token) return
        fetchManagerment()
    }, []);

    const fetchManagerment = () => {
        try {
            fetch(`${SERVER_URL + "status/get-all-status"}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setManagement(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                    setLoading(false);
                });
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    return (
        <ManagementContext.Provider value={{ management, loading }} >
            {children}
        </ManagementContext.Provider>
    )
}

export function useManagement() {
    return useContext(ManagementContext)
}