import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import { tokenData } from '../const';
import moment from 'moment';

const OutMeetingContext = createContext()

export default function OutMeetingProvider({ children }) {

    const [outMeeting, setOutMeeting] = useState([])
    const [outMeetingPublic, setOutMeetingPublic] = useState([])
    const [employees_member, setEmployeesMember] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!tokenData?.token) return
        fetchOutMeeting()
        fetchOutMeetingPublic()
    }, []);

    const decodeHTMLEntities = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    const fetchOutMeeting = () => {
        try {
            fetch(`${SERVER_URL + "out-meeting/outmeeting"}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    // 'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {

                    // Map your data to the format expected by FullCalendar
                    const formattedEvents = data?.results?.map((item) => {
                        // Define default values for properties that might be missing or undefined
                        const rruleDefaults = {
                            freq: 'defaultFreq', // Provide a default value for freq
                            interval: 1, // Provide a default value for intervals
                            count: 0, // Provide a default value for count
                            dtstart: moment(), // Provide a default value for dtstart
                            until: moment(), // Provide a default value for untils,
                            byweekday: []
                        };

                        // Merge the default values with the actual values from the data
                        const rrule = { ...rruleDefaults, ...item?.rrule };

                        const parsedByweekday = rrule?.byweekday !== undefined ? JSON.parse(rrule.byweekday) : []

                        // Map item to the format expected by FullCalendar
                        return {
                            outmeeting_uid: item?.outmeeting_uid,
                            title: decodeHTMLEntities(item.title),
                            description: decodeHTMLEntities(item?.description),
                            backgroundColor: item?.backgroundColor,
                            start: moment(rrule?.dtstart)?.format("YYYY-MM-DD HH:mm:ss"),
                            end: moment(rrule?.untils)?.format("YYYY-MM-DD HH:mm:ss"),
                            is_repeat: item?.is_repeat,
                            rrule: {
                                freq: rrule.freq ?? "WEEKLY",
                                byweekday: parsedByweekday,
                                // byweekday: ["MO","FR"],
                                // interval: rrule.intervals,
                                count: rrule.count,
                                dtstart: rrule.dtstart,
                                until: rrule.untils,
                            }
                        };
                    });

                    setOutMeeting(formattedEvents);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                    setLoading(false);
                });
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const fetchOutMeetingPublic = () => {
        try {
            fetch(`${SERVER_URL + "out-meeting/out-meeting"}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {

                    // Map your data to the format expected by FullCalendar
                    const formattedEvents = data?.results.map((item) => {
                        // Define default values for properties that might be missing or undefined
                        const rruleDefaults = {
                            freq: 'defaultFreq', // Provide a default value for freq
                            interval: 1, // Provide a default value for intervals
                            count: 0, // Provide a default value for count
                            dtstart: moment(), // Provide a default value for dtstart
                            until: moment(), // Provide a default value for untils
                            byweekday: ""
                        };

                        // Merge the default values with the actual values from the data
                        const rrule = { ...rruleDefaults, ...item.rrule };

                        const parsedByweekday = rrule.byweekday !== undefined ? JSON.parse(rrule.byweekday) : []
                        // Format start and end times according to eventTimeFormat
                        const formattedStartTime = moment(rrule.dtstart)?.format("YYYY-MM-DD HH:mm:ss");
                        const formattedEndTime = moment(rrule.untils)?.format("YYYY-MM-DD HH:mm:ss");

                        // Map item to the format expected by FullCalendar
                        return {
                            outmeeting_uid: item?.outmeeting_uid,
                            title: decodeHTMLEntities(item?.title),
                            description: decodeHTMLEntities(item?.description),
                            backgroundColor: item?.backgroundColor,
                            start: formattedStartTime,
                            end: formattedEndTime,
                            is_repeat: item?.is_repeat,
                            rrule: {
                                freq: rrule.freq ?? "WEEKLY",
                                // byweekday: rrule?.byweekday,
                                byweekday: parsedByweekday,
                                // interval: rrule.intervals,
                                count: rrule.count,
                                dtstart: rrule.dtstart,
                                until: rrule.untils,
                            }
                        };
                    });

                    setOutMeetingPublic(formattedEvents);
                    setLoading(false);

                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                    setLoading(false);
                });
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    // console.log("outMeeting========>", outMeeting)
    // console.log("outMeeting========>", outMeetingPublic)

    return (
        <OutMeetingContext.Provider
            value={{
                outMeeting,
                outMeetingPublic,
                employees_member,
                setEmployeesMember,
                setOutMeetingPublic,
                setOutMeeting,
                fetchOutMeeting,
                fetchOutMeetingPublic
            }}>
            {children}
        </OutMeetingContext.Provider>
    )
}

export function useOutMeeting() {
    return useContext(OutMeetingContext)
}
