import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined, CheckOutlined, SearchOutlined, UserOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { List, Avatar, Input, Space, Button, Card, Form, Table, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMember } from '../../contexts/MemberContext';
import { SERVER_URL } from '../../api';
import { useUserGroup } from '../../contexts/UserGroupContext';
import { useTranslation } from 'react-i18next';
import { tokenData } from '../../const';
import useWindowDimensions from '../../helper/useWindowDimensions';

export default function CreateUserGroup() {

    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const { t } = useTranslation()
    const { members, setMembers, fetchMember } = useMember()
    const { fetchUserGroup } = useUserGroup()
    const [title, setTitle] = useState()
    const [cardData, setCardData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState(members?.results || [])

    useEffect(() => {
        if (members?.results) setFilteredResults(members?.results || [])
    }, [members?.results])

    useEffect(() => {
        if (!searchQuery) {
            setFilteredResults(members?.results || [])
        }
        const newFilteredResults = members?.results?.filter((item) =>
            item?.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(newFilteredResults);
    }, [searchQuery]);

    const addToCard = (id) => {
        const memberToAdd = members.results.find(item => item.employee_id === id);
        if (memberToAdd) {
            setCardData([...cardData, memberToAdd]);
            setMembers({ results: members.results.filter(item => item.employee_id !== id) });
        }
    };

    const removeFromCard = (id) => {

        const memberToRemove = cardData.find(item => item.employee_id === id);

        if (memberToRemove) {
            setCardData(cardData?.filter(item => item.employee_id !== id));
            setMembers({ results: [...members.results.filter(item => item.employee_id !== id), memberToRemove] });
        }
    };

    const columns = [
        {
            title: t("No/"),
            dataIndex: 'number',
            key: 'number',
            render: (text) => <a>{text}</a>,
            ellipsis: true,

        },
        {
            title: t("User Name"),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
            ellipsis: true,
        },
        {
            title: t("User Email"),
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
        },
        {
            title: t("Management"),
            dataIndex: 'delete',
            key: 'delete',
            ellipsis: true,
        },
    ]

    const data = cardData?.map((e, index) => ({
        number: index + 1,
        name: e?.surname,
        email: e?.email,
        delete: <Button icon={<DeleteOutlined />} onClick={() => removeFromCard(e?.employee_id)} >{t("delete")}</Button>
    }))

    const handleCreateUserGroup = async () => {

        if (cardData?.length <= 0) {
            notification.error({
                message: t('Error'),
                description: t('Please select an employee'),
                duration: 2,
            });
            return;
        }

        setLoading(true)
        try {
            const requestBody = {
                group_name: title,
                employees: cardData?.map((e) => ({ employee_id: e?.employee_id }))
            };

            await axios.post(`${SERVER_URL}group/insert`, requestBody, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                },
            });
            notification.success({
                message: t('Success'),
                description: t('save success'),
                duration: 2,
            });
            fetchMember()
            fetchUserGroup()
            navigate('/user-group-list')
            setLoading(false)
        } catch (error) {
            console.log("Error: ", error)
            setLoading(false)
        }
    }

    return (
        <div style={{ marginTop: 20 }}>
            <Card
                // title="Card title"
                bordered={false}
                style={{
                    width: '100%',
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: width < 700 ? "column" : ""
                }}>
                    <div style={{ fontSize: 20 }}>{t("Create User Group")}</div>
                    <Space>
                        <Button
                            onClick={() => {
                                fetchMember()
                                navigate('/user-group-list')
                            }}
                            style={{ height: 40 }}
                            // type="primary"
                            icon={<ArrowLeftOutlined />}>
                            {t("Back")}
                        </Button>
                        {/* <Button
                            style={{ height: 40 }}
                            // type="primary"
                            icon={<CloseOutlined />}>
                            ຍົກເລີກ
                        </Button> */}
                        <Button
                            loading={loading}
                            onClick={() => {
                                if (!title || title?.trim() === '') {
                                    notification.error({
                                        message: t('Error'),
                                        description: t("Please Input Title Group"),
                                        duration: 2,
                                    });
                                    return;
                                }
                                handleCreateUserGroup()
                            }}
                            style={{ height: 40 }}
                            type="primary"
                            icon={<CheckOutlined />}>
                            {t("save")}
                        </Button>
                    </Space>
                </div>
            </Card>

            <Card
                // title="Card title"
                bordered={false}
                style={{
                    width: '100%',
                    marginTop: 20
                }}
            >
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: width < 700 ? "column" : ""
                }}>
                    <div style={{ width: width < 700 ? "100%" : "50%" }}>

                        <div>
                            <Input
                                onChange={(e) => setSearchQuery(e.target.value)}
                                value={searchQuery}
                                style={{ height: 40 }}
                                placeholder={t('Search Employee Name')}
                                prefix={<SearchOutlined />} />
                        </div>

                        <div style={{ height: "55vh", overflow: "auto" }}>
                            <List
                                itemLayout="horizontal"
                                dataSource={filteredResults?.sort((a, b) => a.name.localeCompare(b.name))}
                                // height={ContainerHeight}
                                itemHeight={47}
                                // onScroll={onScroll}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <div style={{ display: "flex", cursor: "pointer" }} >

                                                    <Avatar
                                                        style={{
                                                            backgroundColor: '#87d068',
                                                        }}
                                                        icon={<UserOutlined />}
                                                    />
                                                </div>
                                            }
                                            title={
                                                <div>
                                                    {item.name} {item.surname}
                                                </div>
                                            }
                                            description={<div>{item?.email}</div>}
                                        />
                                        <div style={{ display: "flex" }}>
                                            <Button
                                                disabled={tokenData?.id === item?.employee_id ? true : false}
                                                onClick={() => {
                                                    addToCard(item?.employee_id)
                                                }}
                                                style={{ height: 40 }}
                                                // type="primary"
                                                icon={<PlusCircleOutlined />}>
                                                {t("add")}
                                            </Button>

                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>

                    </div>
                    <div style={{ width: width < 700 ? "100%" : "50%", marginTop: width < 700 ? "50px" : "" }}>
                        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <Input
                                style={{ height: 40, marginBottom: 20, border: !title || title?.trim() === '' ? "1px solid red" : "" }}
                                value={title}
                                placeholder={t('Input Title Group')}
                                onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        {/* <div style={{ textAlign: 'center', fontSize: 16 }}>ລາຍຊື່</div> */}
                        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <Table
                                scroll={{ x: true }}
                                columns={columns}
                                dataSource={data}
                                pagination={{ pageSize: 5 }}
                            />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
